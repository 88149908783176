import { Box } from "@mui/material";
import SimpleDropdown from "../Components/DynamicDropdown/SimpleDropdown";
import FormTextBox from "./FormTextBox";

const FormPhone = ({ item, register, clearErrors, errors, isRendering }) => {
  return (
    <Box
      className='formTextBox elementWrapper'
      mt={1}
      display={item?.split ? "flex" : "block"}
      gap={item?.split ? "12px" : "0px"}>
      {item?.split ? (
        item?.splitFields?.map(
          (splitFieldItem) =>
            splitFieldItem?.optional !== true && (
              <>
                {splitFieldItem?.type === "phone" ? (
                  <Box flex={3}>
                    <FormTextBox
                      item={splitFieldItem}
                      register={register}
                      clearErrors={clearErrors}
                      errors={errors}
                      isRendering={isRendering}></FormTextBox>
                  </Box>
                ) : (
                  <>
                    {splitFieldItem?.type === "simple-dropdown" ? (
                      <Box flex={1} sx={{ minWidth: "115px" }}>
                        <SimpleDropdown
                          field={splitFieldItem}
                          register={register}
                          errors={errors}
                          clearErrors={clearErrors}
                          isRendering={isRendering}
                        />
                      </Box>
                    ) : null}
                  </>
                )}
              </>
            ),
        )
      ) : (
        <Box>
          <FormTextBox
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}></FormTextBox>
        </Box>
      )}
    </Box>
  );
};

export default FormPhone;
