import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Divider, Grid } from "@mui/material";
import {
  FETCH_SELECTED_COLLECTION_ITEM_DETAIL,
  FETCH_TAG_LIST_QUERY,
  commentsApi,
  contentTypeAPIs,
  useComment,
  useWorkflow,
} from "@platformx/authoring-apis";
import { RootState, previewContent } from "@platformx/authoring-state";
import { CommentListPanel, CommentWrapper } from "@platformx/comment-review";
import { ErrorMessageHandle } from "@platformx/schema-based-content-type";
import {
  AILoader,
  AUTH_INFO,
  AutoTextArea,
  CATEGORY_CONTENT,
  CommonBoxWithNumber,
  CommonPlateformXDialog,
  Loader,
  MAPPING,
  ShowToastError,
  ShowToastSuccess,
  TextBox,
  TitleSubTitle,
  XTags,
  capitalizeFirstLetter,
  getCurrentLang,
  useUserSession,
  workflowKeys,
} from "@platformx/utilities";
import { WorkflowHistory } from "@platformx/workflow-management";
import { DamContentGallery, XImageRender } from "@platformx/x-image-render";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v6";
import Analytics from "../../../components/Analytics/Analytics";
import ContentPageScroll from "../../../components/ContentPageScroll";
import { CreateHeader } from "../../../components/CreateHeader/CreateHeader";
import Seo from "../../../components/SEO/SEO";
import SocialShare from "../../../components/SocialShare/SocialShare";
import { ContentType } from "../../../enums/ContentType";
import useAIContent from "../../../hooks/useAIcontent/useAIcontent";
import icons, {
  DEF_VOD,
  analyticsTitleSubTitles,
  defaultVodRef,
  seoTitleSubTitles,
  socialShareTitleSubTitles,
} from "./Utils/constats";
import {
  fieldValidationForPublish,
  getNewVod,
  getUpdatedVodToSend,
  getVodToSend,
  removeVODDuplicateTag,
  updateStructureData,
  updateVodSettings,
} from "./Utils/helper";
import { ChooseVideoTray } from "./components/chooseVideoTray/ChooseVideoTray";
import "./createVod.css";
import { useStyles } from "./createVod.styles";

export const CreateVod = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const updateTempObj = useRef<any>({});
  const { currentContent } = useSelector((state: RootState) => state.content);
  const [getSession] = useUserSession();
  const { userInfo, role } = getSession();
  const login_user_id = userInfo?.user_id;
  const username = `${userInfo.first_name} ${userInfo.last_name}`;
  const vodPageUrl = new URL(window.location.href);
  const { getWorkflowDetails, workflowRequest } = useWorkflow();
  const galleryType = useRef<string>("Video");
  const [isLoading, setIsLoading] = useState(false);
  const [enableWorkflowHistory, setEnableWorkflowHistory] = useState<boolean>(false);
  const [workflowStatus, setWorkflowStatus] = useState(true);
  const [showWorkflowSubmit, setShowWorkflowSubmit] = useState(false);
  const [pageStatus, setPageStatus] = useState("DRAFT");
  const [isFeatured, setIsFeatured] = useState(false);
  const [isDraft, setIsDraft] = useState<boolean>(true);
  const [draftPageURL, setDraftPageURL] = useState<string>("");
  const [parentToolTip, setParentToolTip] = useState("");
  const [runFetchTagList] = useLazyQuery(FETCH_TAG_LIST_QUERY);
  const currentVodData = useRef(
    vodPageUrl.searchParams.get("path") ? (vodPageUrl.searchParams.get("path") as string) : "",
  );
  const [createVodMutate] = useMutation(contentTypeAPIs.createContentType);
  const [updateVodMutate] = useMutation(contentTypeAPIs.updateContentType);
  const [publishVodMutate] = useMutation(contentTypeAPIs.publishContentType);
  const [fetchVodQuery] = useLazyQuery(contentTypeAPIs.fetchContentByPath);
  const [openPageExistModal, setOpenPageExistModal] = useState<boolean>(false);
  const [tagData, setTagData] = useState<any>([]);
  const [vodInstance, setVodInstance] = useState<any>({});
  const [workflow, setWorkflow] = useState({});
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [showExitWarning, setShowExitWarning] = useState(false);
  const unsavedChanges = useRef<boolean>(currentContent.isUnsavedVod);
  const [galleryState, setGalleryState] = useState<boolean>(false);
  const vodRef = useRef<any>(defaultVodRef(currentContent) || DEF_VOD);
  const tagRef = useRef<any>([]);
  const scrollDebounceRef = useRef<any>(null);
  const [previewButton, setPreviewButton] = useState(true);
  const [previewTooltip, setPreviewTooltip] = useState(true);
  const { comments } = useComment();
  const [isError, setIsError] = useState<any>([]);
  const { fetchContent, loading } = useAIContent(FETCH_SELECTED_COLLECTION_ITEM_DETAIL);
  const [openAImodal, setOpenAImodal] = useState(false);
  const aiContentRef = useRef<any>();

  const workflowSubmitRequest = async (workflowObj, status) => {
    try {
      // Perform the workflow request
      setIsLoading(true);
      const { success, workflow_status } = await workflowRequest(workflowObj, status);
      if (success) {
        workflow_status === workflowKeys.publish.toLowerCase() && status === workflowKeys.approve
          ? setShowPublishConfirm(true)
          : setShowWorkflowSubmit(true);
      }
    } catch (error: any) {
      // Handle the error
      const statusCode = error?.networkError?.statusCode || 0;
      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error?.graphQLErrors[0]?.message);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const updateField = (updatedCommonFields, updatedObjectFields, callPreview = false) => {
    updateTempObj.current = {
      CommonFields: { ...updateTempObj.current?.CommonFields, ...updatedCommonFields },
      ObjectFields: { ...updateTempObj.current?.ObjectFields, ...updatedObjectFields },
    };
    const currentVodInstance = JSON.parse(JSON.stringify(vodInstance));
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          ...updatedCommonFields,
          modificationDate: new Date().toISOString(),
        },
        ObjectFields: {
          ...prevState.ObjectFields,
          ...updatedObjectFields,
        },
      };
    });
    if (callPreview) {
      dispatch(
        previewContent({
          ...currentVodInstance,
          CommonFields: {
            ...currentVodInstance.CommonFields,
            ...updatedCommonFields,
            modificationDate: new Date().toISOString(),
            contentType: "Vod",
            isDraft: isDraft,
            draftPageURL: draftPageURL,
          },
          ObjectFields: {
            ...currentVodInstance.ObjectFields,
            ...updatedObjectFields,
          },
        }),
      );
      navigate("/content/preview", {
        state: { contentType: MAPPING.Vod, pageName: currentVodData?.current || draftPageURL },
      });
    }
  };

  /**
   * thumbnail update
   */
  const updateImageField = (updatedPartialObj) => {
    const relativeUrl = `${updatedPartialObj?.selected_image?.Thumbnail}`;
    vodRef.current.Thumbnail = relativeUrl;
    vodRef.current.imageSocialShare = relativeUrl;
    const structureData = updateStructureData(vodRef);
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          modificationDate: new Date().toISOString(),
          imageSocialShare: relativeUrl,
          structure_data: JSON.stringify(structureData),
        },
        ObjectFields: {
          ...prevState.ObjectFields,
          Thumbnail: relativeUrl,
        },
      };
    });
    setIsError({ ...isError, Thumbnail: false });
  };

  const updateCurrentInstance = (pageURL, callPreview = false) => {
    const videoTags = vodRef?.current?.Tags || tagRef.current;
    const updatedCommonFields = {
      page: vodRef?.current?.Page || "",
      title: vodRef?.current?.title || "",
      description: vodRef?.current?.description || "",
      tags: removeVODDuplicateTag(videoTags),
      current_page_url: `/${pageURL}`,
      settings: {
        ...updateVodSettings(vodRef, currentVodData, i18n.language),
      },
      is_featured: isFeatured,
      analytics_enable: vodRef.current.analytics_enable,
      seo_enable: vodRef.current.seo_enable,
      ...(callPreview ? { workflow } : {}),
    };
    const updatedObjectFields = {
      Thumbnail: vodRef?.current?.Thumbnail || "",
      DsapceVideoUrl: vodRef?.current?.DsapceVideoUrl || "",
    };

    updateField(updatedCommonFields, updatedObjectFields, callPreview);
  };

  const handelPreview = () => {
    if (!currentVodData.current) {
      updateCurrentInstance(
        vodRef?.current?.title.replace(/[^A-Z0-9]+/gi, "-").toLowerCase(),
        true,
      );
    } else {
      updateCurrentInstance(currentVodData.current, true);
    }
  };

  const exitWithoutSave = () => {
    setShowExitWarning(false);
    navigate("/content/vod");
  };

  const createVod = async (pageState, pageExist, isWorkflow = true) => {
    const newTempData = JSON.parse(JSON.stringify(vodInstance));
    const vodToSend = getVodToSend(
      vodRef,
      newTempData,
      updateTempObj,
      pageState,
      pageExist,
      isFeatured,
      username,
    );
    try {
      setIsLoading(true);
      const createResponse: any = await createVodMutate({
        variables: {
          contenttype: "Vod",
          input: vodToSend,
        },
      });

      const pageUrl = createResponse?.data?.authoring_createContent?.path.substring(
        createResponse?.data?.authoring_createContent?.path.lastIndexOf("/") + 1,
      );
      if (createResponse?.data?.authoring_createContent?.isExist) {
        setOpenPageExistModal(true);
        setPageStatus(pageState);
        setWorkflowStatus(isWorkflow);
      } else {
        setIsDraft(false);
        vodRef.current = {
          ...vodRef.current,
          page: pageUrl,
        };
        setDraftPageURL(pageUrl);
        unsavedChanges.current = false;
        const {
          CommonFields: { page_createdBy, title, description },
        } = vodToSend;
        const workflowObj = {
          createdBy: page_createdBy,
          title,
          description,
          path: createResponse?.data?.authoring_createContent?.path,
          workflow_status: workflowKeys.draft,
          tag_name: capitalizeFirstLetter(ContentType.Vod),
          last_modifiedBy: page_createdBy,
        };
        setWorkflow({ ...workflow, ...workflowObj });
        if (isWorkflow) {
          workflowSubmitRequest(workflowObj, workflowKeys.approve);
        } else {
          ShowToastSuccess(`${t("vod")} ${t("saved_toast")}`);
        }
        if (
          createResponse?.data?.authoring_createContent?.message === "Successfully created!!!" &&
          pageState === "PUBLISHED"
        ) {
          const publishedVodToSend = {
            page: pageUrl,
          };
          await publishVodMutate({
            variables: {
              contentType: "Vod",
              input: publishedVodToSend,
            },
          });
          setShowPublishConfirm(true);
        }
        setOpenPageExistModal(false);
      }
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;
      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error?.graphQLErrors[0]?.message);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateVOD = async (pageState: string, isWorkflow, props = {}, event_step = "") => {
    const newTempData = JSON.parse(JSON.stringify(vodInstance));
    const vodToSend = getUpdatedVodToSend(
      newTempData,
      updateTempObj,
      username,
      isFeatured,
      draftPageURL || currentVodData.current,
      pageState,
    );
    try {
      setIsLoading(true);
      const updateResponse: any = await updateVodMutate({
        variables: {
          contenttype: "Vod",
          input: vodToSend,
        },
      });
      if (pageState === "DRAFT") {
        if (isWorkflow) {
          workflowSubmitRequest(props, event_step);
        } else {
          ShowToastSuccess(`${t("vod")} ${t("updated_toast")}`);
        }
      } else if (
        updateResponse?.data?.authoring_updateContent?.message === "Successfully updated!!!" &&
        pageState === "PUBLISHED"
      ) {
        const publishedVodToSend = {
          page:
            draftPageURL ||
            (currentVodData.current ? currentVodData.current : vodRef?.current?.page),
        };
        await publishVodMutate({
          variables: {
            contentType: "Vod",
            input: publishedVodToSend,
          },
        });
        setShowPublishConfirm(true);
      }
      unsavedChanges.current = false;
      setShowExitWarning(false);
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;
      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error?.graphQLErrors[0]?.message);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveVod = (status = false, props = {}, event_step = "") => {
    if (showExitWarning) {
      setShowExitWarning(false);
    }
    // if (fieldValidation(vodRef.current, isError, setIsError)) {
    const pageURL = vodRef?.current?.title?.replace(/[^A-Z0-9]+/gi, "-").toLowerCase();
    updateCurrentInstance(pageURL);
    if (!currentVodData.current && isDraft) {
      createVod("DRAFT", false, status);
    } else {
      updateVOD("DRAFT", status, props, event_step);
    }
    // }
  };

  const publishButtonHandel = () => {
    if (showExitWarning) {
      setShowExitWarning(false);
    }
    if (fieldValidationForPublish(vodRef.current, isError, setIsError)) {
      const pageURL = currentVodData.current
        ? currentVodData.current
        : vodRef?.current?.title?.replace(/[^A-Z0-9]+/gi, "-").toLowerCase();
      updateCurrentInstance(pageURL);
      if (!currentVodData.current && isDraft) {
        createVod("PUBLISHED", false, false);
      } else {
        updateVOD("PUBLISHED", false);
      }
    }
  };

  //Functions to handle Page Exist modal
  const pageExistNoButtonHandle = () => {
    setOpenPageExistModal(false);
  };

  const pageExistYesButtonHandle = () => {
    setOpenPageExistModal(false);
    if (pageStatus.toLowerCase() === "DRAFT") {
      createVod("DRAFT", true, workflowStatus);
    } else if (pageStatus.toLowerCase() === "PUBLISHED") {
      createVod("PUBLISHED", true);
    }
  };

  const handleSelectedImage = (image) => {
    vodRef.current = { ...vodRef.current, Thumbnail: image?.Thumbnail };
    const structureData = updateStructureData(vodRef);
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          structure_data: JSON.stringify(structureData),
        },
      };
    });
    unsavedChanges.current = true;
  };

  /**
   * update video
   * @param video object
   */
  const handleSelectedVideo = (video) => {
    setOpenAImodal(true);
    aiContentRef.current = {
      collectionId: video?.uuid,
      assetId: video?.bitStreamId,
    };
    const videoObject = {
      page: draftPageURL || "",
      DsapceVideoUrl: video?.Url,
      Thumbnail: video?.Thumbnail,
      imageSocialShare: video?.Thumbnail,
    };
    vodRef.current = {
      ...vodRef.current,
      ...videoObject,
    };
    const structureData = updateStructureData(vodRef);
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          page: draftPageURL || "",
          imageSocialShare: video?.Thumbnail,
          structure_data: JSON.stringify(structureData),
        },
        ObjectFields: {
          ...prevState.ObjectFields,
          DsapceVideoUrl: video?.Url,
          Thumbnail: video?.Thumbnail,
        },
      };
    });
    unsavedChanges.current = true;
    setIsError({ ...isError, DsapceVideoUrl: false });
  };
  const handleAIContent = async () => {
    setOpenAImodal(false);
    if (aiContentRef.current?.collectionId && aiContentRef.current?.assetId) {
      const result = await fetchContent(
        aiContentRef.current?.collectionId,
        aiContentRef.current?.assetId,
      );
      if (result && vodRef?.current) {
        const {
          summary,
          teaser,
          label,
          seo_keywords = [],
        } = result.data.authoring_getAssetMetadata;
        let tagsArray: any = [...(label ?? [])];
        tagsArray = [...tagsArray, "Video Card"];
        vodRef.current = {
          ...vodRef.current,
          Tags: removeVODDuplicateTag(tagsArray),
          tagsSocialShare:
            seo_keywords?.length > 0
              ? removeVODDuplicateTag([...seo_keywords, ...tagsArray])
              : removeVODDuplicateTag(tagsArray),
          title: teaser?.slice(0, 120) || "",
          description: summary?.slice(0, 1000) || "",
          titleSocialShare: teaser?.slice(0, 120) || "",
          descriptionSocialShare: summary?.slice(0, 1000) || "",
        };
        setVodInstance((prevState) => {
          return {
            ...prevState,
            CommonFields: {
              ...prevState.CommonFields,
              tags: vodRef.current.Tags,
              tagsSocialShare: vodRef.current.tagsSocialShare,
              title: vodRef.current.title,
              description: vodRef.current.description,
              titleSocialShare: vodRef.current.titleSocialShare,
              descriptionSocialShare: vodRef.current.descriptionSocialShare,
            },
          };
        });
      }
    }
  };

  const handleNoButton = () => {
    setOpenAImodal(false);
  };

  const toggleGallery = (toggleState) => {
    setGalleryState(toggleState);
  };

  const showGallery = (gType) => {
    window.scrollTo(0, 0);
    galleryType.current = gType;
    setGalleryState(true);
  };

  const handleTagOnChange = (event) => {
    let tagsArray: any = vodRef?.current?.Tags ? [...vodRef?.current?.Tags] : [...tagRef.current];

    if (event.target.checked) {
      tagsArray = [...tagsArray, event?.target?.value];
      if (tagsArray?.length > 100) {
        ShowToastError("You cannot select more than 100 tags.");
        return false;
      }
    } else {
      tagsArray.splice(tagsArray.indexOf(event.target.value), 1);
    }
    tagRef.current = tagsArray;
    vodRef.current = {
      ...vodRef.current,
      Tags: removeVODDuplicateTag(tagsArray),
      tagsSocialShare: removeVODDuplicateTag(tagsArray),
    };
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          tags: removeVODDuplicateTag(tagsArray),
          tagsSocialShare: removeVODDuplicateTag(tagsArray),
        },
      };
    });
    unsavedChanges.current = true;
    setIsError({ ...isError, Tags: false });
  };
  const handleSocialShareTagChange = (_, value) => {
    const result = value.filter((str) => str.trim()?.length !== 0);
    const updatedtags = result.filter((c, index) => {
      return result.indexOf(c) === index;
    });
    vodRef.current = {
      ...vodRef.current,
      tagsSocialShare: removeVODDuplicateTag(updatedtags),
    };
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          tagsSocialShare: removeVODDuplicateTag(updatedtags),
        },
      };
    });
  };
  const handleSocialShareImageChange = (data) => {
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          imageSocialShare: data.relativeUrl,
        },
      };
    });
    vodRef.current = {
      ...vodRef.current,
      imageSocialShare: data.relativeUrl,
    };
    unsavedChanges.current = true;
  };
  const handleChange = (event) => {
    const { name } = event.target;
    const key = `${name}SocialShare`;
    vodRef.current = { ...vodRef.current, [name]: event?.target?.value, [key]: event.target.value };
    const structureData = updateStructureData(vodRef);
    unsavedChanges.current = true;
    if (
      (name === "description" && vodRef?.current?.description === event?.target?.value) ||
      (name === "title" && vodRef?.current?.title === event?.target?.value)
    ) {
      setVodInstance((prevState) => {
        return {
          ...prevState,
          CommonFields: {
            ...prevState.CommonFields,
            [name]: event?.target?.value,
            [key]: event.target.value,
            structure_data: JSON.stringify(structureData),
          },
        };
      });
      setIsError({ ...isError, [name]: false });
    }
  };
  const handleSocialShareChange = (event) => {
    const { name } = event.target;
    vodRef.current = { ...vodRef.current, [name]: event?.target?.value };
    if (
      (name === "titleSocialShare" && vodRef?.current?.titleSocialShare === event?.target?.value) ||
      (name === "descriptionSocialShare" &&
        vodRef?.current?.descriptionSocialShare === event?.target?.value)
    ) {
      setVodInstance((prevState) => {
        return {
          ...prevState,
          CommonFields: {
            ...prevState.CommonFields,
            [name]: event?.target?.value,
          },
        };
      });
    }
    unsavedChanges.current = true;
  };
  const handleAnalyticsSeoChange = (event, keyName) => {
    vodRef.current = { ...vodRef.current, [keyName]: event?.target?.checked };
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          [keyName]: event?.target?.checked,
        },
      };
    });
  };

  const handleStrucutreDataChange = (structureData) => {
    setVodInstance((prevState) => {
      return {
        ...prevState,
        CommonFields: {
          ...prevState.CommonFields,
          structure_data: JSON.stringify(structureData),
        },
      };
    });
  };

  const createComment = () => {
    const currentLanguage = getCurrentLang();
    const createCommentRequest = {
      document_path: `/content/documents/hclplatformx/${currentLanguage}/vod/${currentVodData.current}`,
      status: false,
      document_type: "Vod",
      created_by: username,
      last_modified_by: username,
      reviewer_comments: [comments],
    };

    return commentsApi.createOrUpdateComment({
      input: createCommentRequest,
    });
  };

  const backToVodList = () => {
    if (unsavedChanges.current === true) {
      setShowExitWarning(true);
    } else {
      navigate("/content/vod");
    }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (unsavedChanges.current) {
      backToVodList();
    } else {
      // eslint-disable-next-line no-restricted-globals
      window.history.pushState(null, "", window.location.pathname + location?.search);
      backToVodList();
    }
  };

  const pageExistCrossButtonHandle = () => {
    setOpenPageExistModal(false);
    // eslint-disable-next-line no-restricted-globals
    window.history.pushState(null, "", window.location.pathname + location?.search);
    window.addEventListener("popstate", onBackButtonEvent);
  };

  const onUploadClick = (type) => {
    showGallery(type);
  };

  useEffect(() => {
    if (Object.keys(vodInstance).length === 0) {
      setVodInstance(getNewVod(username, vodRef));
    }
    const fetchWorkflowDetails = async () => {
      setIsLoading(true);
      if (currentVodData.current === "" && Object.keys(currentContent).length === 0) {
        await getWorkflowDetails(
          role,
          login_user_id,
          setWorkflow,
          capitalizeFirstLetter(ContentType.Vod),
        );
      }
      setIsLoading(false);
    };
    fetchWorkflowDetails();
  }, []);

  useEffect(() => {
    if (Object.keys(currentContent).length > 0) {
      const tempCurrentContent = { ...currentContent };
      const tempCurrentContentCommonFields = { ...(tempCurrentContent.CommonFields || {}) };
      setDraftPageURL(tempCurrentContentCommonFields?.draftPageURL);
      setIsDraft(tempCurrentContentCommonFields?.isDraft);
      setWorkflow(tempCurrentContentCommonFields?.workflow);

      delete tempCurrentContentCommonFields?.isDraft;
      delete tempCurrentContentCommonFields?.contentType;
      delete tempCurrentContentCommonFields?.draftPageURL;
      delete tempCurrentContentCommonFields?.workflow;
      setVodInstance((prevState) => {
        return {
          ...prevState,
          CommonFields: {
            ...prevState.CommonFields,
            ...tempCurrentContentCommonFields,
          },
          ObjectFields: {
            ...prevState?.ObjectFields,
            ...tempCurrentContent.ObjectFields,
          },
        };
      });
    }
  }, [currentContent]);

  useEffect(() => {
    const fetchVodContent = async () => {
      try {
        setIsLoading(true);
        const {
          data: { authoring_getCmsContentByPath: fetchVodResponse },
        } = await fetchVodQuery({
          variables: {
            contentType: "Vod",
            path: currentVodData.current,
          },
        });
        const {
          path,
          workflow_status,
          stages,
          tag_name,
          last_modifiedBy,
          createdBy,
          title,
          task_status,
          user_id,
          user_name,
          page,
          description,
          tags,
          settingsProperties,
          is_featured,
          DsapceVideoUrl,
          Thumbnail,
          analytics_enable,
          seo_enable,
          structure_data,
        } = fetchVodResponse;
        const { socialog_title, socialog_description, socialog_image, keywords } =
          settingsProperties;
        const updatedCommonFields = {
          title,
          description,
          tags,
          settings: settingsProperties,
          is_featured,
          page,
          titleSocialShare: socialog_title,
          descriptionSocialShare: socialog_description,
          imageSocialShare: socialog_image,
          tagsSocialShare: [...keywords],
          analytics_enable,
          seo_enable,
          structure_data,
        };
        const updatedObjectFields = {
          DsapceVideoUrl: DsapceVideoUrl || "",
          Thumbnail: Thumbnail || "",
        };
        setVodInstance((prevState) => {
          return {
            ...prevState,
            CommonFields: {
              ...prevState.CommonFields,
              ...updatedCommonFields,
            },
            ObjectFields: {
              ...prevState.ObjectFields,
              ...updatedObjectFields,
            },
          };
        });

        setDraftPageURL(page);
        const videoObj = {
          page,
          DsapceVideoUrl: DsapceVideoUrl || "",
          Thumbnail: Thumbnail || "",
          title,
          description,
          Tags: removeVODDuplicateTag(tags),
          is_featured,
          titleSocialShare: socialog_title,
          descriptionSocialShare: socialog_description,
          imageSocialShare: socialog_image,
          tagsSocialShare: [...keywords],
          analytics_enable,
          seo_enable,
        };
        vodRef.current = {
          ...videoObj,
        };
        updateField(updatedCommonFields, updatedObjectFields);
        setIsFeatured(is_featured);

        setWorkflow({
          path,
          workflow_status,
          stages,
          tag_name,
          last_modifiedBy,
          createdBy,
          role,
          title,
          enable: stages?.length > 0 ? true : false,
          login_user_id,
          task_status,
          task_user_id: user_id,
          task_user_name: user_name,
          description,
        });
      } catch (error: any) {
        const statusCode = error?.networkError?.statusCode || 0;
        ShowToastError("Some thing went wrong", statusCode);
      } finally {
        setIsLoading(false);
      }
    };
    if (
      currentVodData.current &&
      unsavedChanges.current !== true &&
      Object.keys(currentContent).length === 0
    ) {
      fetchVodContent();
    }
    if (Object.keys(tagData).length === 0) {
      runFetchTagList({
        variables: { start: 0, rows: 1000 },
      })
        .then((res) => {
          if (res?.data?.authoring_getTagsList) {
            setTagData(res?.data?.authoring_getTagsList);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(err, null, 2));
          ShowToastError(t("api_error_toast"));
        });
    }
  }, []);

  useEffect(() => {
    if (!currentVodData.current && tagData?.length > 0) {
      handleTagOnChange({
        target: {
          checked: true,
          value: "Video Card",
        },
      });
    }
  }, [tagData?.length]);

  const isInViewport = (element) => {
    const mainElement = document.querySelector(`#${element}`);
    if (mainElement) {
      const rect = mainElement.getBoundingClientRect();
      return rect.top <= window.pageYOffset + window.innerHeight && rect.top >= window.pageYOffset;
    }
    return false;
  };

  const scrollHandler = () => {
    if (scrollDebounceRef.current) {
      clearTimeout(scrollDebounceRef.current);
    }
    const timeOutId = setTimeout(() => {
      const active = icons.find((i) => isInViewport(i.id));
      if (active && active.tooltip !== parentToolTip) {
        setParentToolTip(active.tooltip);
      }
    }, 100);
    scrollDebounceRef.current = timeOutId;
  };
  useEffect(() => {
    const dataHolder = document.getElementById("scrollableDiv");
    dataHolder?.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    const {
      CommonFields: { title = "", tags = [] } = {},
      ObjectFields: { DsapceVideoUrl = "", Thumbnail = "" } = {},
    } = vodInstance || {};
    if (title === "" || tags?.length === 0 || DsapceVideoUrl === "" || Thumbnail === "") {
      setPreviewButton(true);
    } else if (!currentVodData?.current && isDraft) {
      setPreviewTooltip(false);
    } else {
      setPreviewButton(false);
    }
  }, [vodInstance, isDraft]);
  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Box>
          {/* Top Section start */}
          <CreateHeader
            hasPreviewButton={previewButton}
            handelPreview={handelPreview}
            createText={currentVodData.current ? `${t("edit")} ${t("vod")}` : t("create_vod")}
            handleReturn={backToVodList}
            hasPublishButton={false}
            handleSaveOrPublish={saveVod}
            publishText={t("publish")}
            saveText={t("save_as_draft")}
            previewText={t("preview")}
            toolTipText={previewTooltip ? t("preview_tooltip") : t("preview_tooltip1")}
            saveVariant='secondaryButton'
            handlePublish={publishButtonHandel}
            category={CATEGORY_CONTENT}
            subCategory={ContentType.Vod}
            workflow={workflow}
            createComment={createComment}
            setEnableWorkflowHistory={setEnableWorkflowHistory}
            setIsFeatured={setIsFeatured}
            isFeatured={isFeatured}
            publishDisabled={isLoading}
          />
          {/* Top Section End */}
          <Divider></Divider>
        </Box>
        <Box
          sx={{
            position: "relative",
            height: {
              sm: "calc(100vh - 125px)",
              xs: "calc(100vh - 45px)",
            },
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          id='scrollableDiv'>
          {!enableWorkflowHistory && (
            <Box
              sx={{
                position: "fixed",
                top: "25%",
                right: { sm: "5px", xs: 0 },
                zIndex: 1000,
              }}>
              <ContentPageScroll
                icons={icons}
                parentToolTip={parentToolTip}
                scrollToView={""} // scrollToView={scrollToView}
              />
            </Box>
          )}
          {enableWorkflowHistory ? (
            <WorkflowHistory
              workflow={workflow}
              setEnableWorkflowHistory={setEnableWorkflowHistory}
            />
          ) : (
            <>
              {/* Video Start */}
              <Box id='VideoAndThumbnail' className={classes.mainStyleWrapper}>
                <CommentWrapper elementId='2'>
                  <CommonBoxWithNumber
                    number='01'
                    title={t("video_subtitle")}
                    titleVarient='h5bold'
                    subTitleVarient='p4regular'
                    subTitle={t("subhead")}>
                    <Grid container>
                      <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
                        <TitleSubTitle
                          title={t("video_title")}
                          subTitle={t("video_subtitle")}
                          titleVariant='h6medium'
                          subTitleVariant='p4regular'
                        />
                      </Grid>
                      <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled' id='Video'>
                        <ChooseVideoTray
                          ifVideoUrl={vodRef.current?.DsapceVideoUrl}
                          onUploadClick={onUploadClick}
                        />
                        {isError["DsapceVideoUrl"] && (
                          <ErrorMessageHandle error={{ message: "Field is Required" }} />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiledLast'>
                        <TitleSubTitle
                          title={t("vod_thumbnail_title")}
                          subTitle={t("vod_thumbnail_subtitle")}
                          titleVariant='h6medium'
                          subTitleVariant='p4regular'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        md={7}
                        lg={7}
                        className='textFiledLast'
                        id='Thumbnail'>
                        <XImageRender
                          callBack={updateImageField}
                          editData={{
                            relativeUrl: vodRef.current?.Thumbnail,
                          }}
                          isCrop={false}
                        />
                        {isError["Thumbnail"] && (
                          <ErrorMessageHandle error={{ message: "Field is Required" }} />
                        )}
                      </Grid>
                    </Grid>
                  </CommonBoxWithNumber>
                </CommentWrapper>
              </Box>
              {/* Video End */}
              {/* Title Desc start */}
              <Box id='titleDescription' className={classes.mainStyleWrapper}>
                <CommentWrapper elementId='1'>
                  <CommonBoxWithNumber
                    number='02'
                    title={t("title_head")}
                    titleVarient='h5bold'
                    subTitleVarient='p4regular'
                    subTitle={t("subhead")}>
                    <Grid container>
                      <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
                        <TitleSubTitle
                          title={t("vod_title")}
                          subTitle={t("vod_subtitle")}
                          titleVariant='h6medium'
                          subTitleVariant='p4regular'
                        />
                      </Grid>
                      <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled' id='Title'>
                        <TextBox
                          name='title'
                          placeHolder={t("vod_title_placeholder")}
                          handleChange={handleChange}
                          maxCharLength={120}
                          state={vodRef?.current?.title || ""}
                          error={isError["title"]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiledLast'>
                        <TitleSubTitle
                          title={t("vod_desciption")}
                          subTitle={t("vod_subdes")}
                          titleVariant='h6medium'
                          subTitleVariant='p4regular'
                        />
                      </Grid>
                      <Grid item xs={12} sm={7} md={7} lg={7} className='textFiledLast'>
                        <AutoTextArea
                          name='description'
                          placeHolder={t("vod_desciption_placeholder")}
                          handleChange={handleChange}
                          maxCharLength={1000}
                          state={vodRef?.current?.description || ""}
                        />
                      </Grid>
                    </Grid>
                  </CommonBoxWithNumber>
                </CommentWrapper>
              </Box>

              {/* Title Desc End */}

              {/* Tag Start */}
              <Box id='tags' className={classes.mainStyleWrapper}>
                <CommonBoxWithNumber
                  number='03'
                  title={t("choose_tags")}
                  titleVarient='h5bold'
                  subTitleVarient='p4regular'
                  subTitle={t("subhead")}>
                  <XTags
                    isEdit={Boolean(currentVodData.current)}
                    tagData={tagData}
                    selectedTag={vodInstance?.CommonFields?.tags}
                    subCategory='Video Card'
                    handleTagOnChange={handleTagOnChange}
                  />
                  {isError["Tags"] && (
                    <ErrorMessageHandle error={{ message: "Field is Required" }} />
                  )}
                </CommonBoxWithNumber>
              </Box>
              {/* Tag End */}
              {/* Social Share Start */}
              <SocialShare
                titleSubTitles={socialShareTitleSubTitles(t)}
                state={vodInstance?.CommonFields}
                handleOnBlur={handleSocialShareChange}
                handleChange={() => {}}
                handleTagChange={handleSocialShareTagChange}
                handleImageChange={handleSocialShareImageChange}
              />
              {/* Social Share End */}
              {/* Analytics Start */}
              <Analytics
                titleSubTitles={analyticsTitleSubTitles(t)}
                state={vodInstance?.CommonFields}
                handleChange={handleAnalyticsSeoChange}
              />
              {/* Analytics End */}
              {/* SEO Start */}
              <Seo
                titleSubTitles={seoTitleSubTitles(t)}
                state={vodInstance?.CommonFields}
                handleChange={handleAnalyticsSeoChange}
                handleStrucutreDataChange={handleStrucutreDataChange}
              />
              {/* SEO End */}
            </>
          )}
        </Box>
      </Box>

      {/* video update */}
      {galleryState && (
        <DamContentGallery
          assetType={"Video"}
          dialogOpen={galleryState}
          toggleGallery={toggleGallery}
          handleImageSelected={handleSelectedImage}
          handleSelectedVideo={handleSelectedVideo}
        />
      )}

      {/* after publish dialog will show */}
      {(showPublishConfirm || showWorkflowSubmit) && (
        <CommonPlateformXDialog
          isDialogOpen={showPublishConfirm || showWorkflowSubmit}
          title={t("congratulations")}
          subTitle={
            showPublishConfirm
              ? `${t("your")} ${t("vod")} ${t("publish_popup_message")}`
              : t("requested_action")
          }
          confirmButtonText={t("go_to_listing")}
          confirmButtonHandle={() => navigate("/content/vod")}
          closeButtonHandle={() => navigate("/content/vod")}
          modalType='publish'
        />
      )}

      {showExitWarning && (
        <CommonPlateformXDialog
          isDialogOpen={showExitWarning}
          title={t("save_warn_title")}
          subTitle={t("save_warn_subtitle")}
          closeButtonText={t("stay_here")}
          confirmButtonText={t("take_me_out")}
          closeButtonHandle={() => {
            setShowExitWarning(false);
          }}
          confirmButtonHandle={exitWithoutSave}
          modalType='unsavedChanges'
        />
      )}

      {openPageExistModal && (
        <CommonPlateformXDialog
          isDialogOpen={openPageExistModal}
          title={`${t("vod")} ${t("duplicate_exists")}`}
          subTitle={t("conformation")}
          closeButtonText={t("no")}
          confirmButtonText={t("yes")}
          closeButtonHandle={pageExistNoButtonHandle}
          confirmButtonHandle={pageExistYesButtonHandle}
          crossButtonHandle={pageExistCrossButtonHandle}
          modalType=''
        />
      )}
      <CommentListPanel />
      <Box>
        {openAImodal && AUTH_INFO?.isAIGenerateEnable && (
          <CommonPlateformXDialog
            isDialogOpen={true}
            title={t("generate_video_content")}
            subTitle={t("ai_title_description_tags")}
            closeButtonText={t("no")}
            confirmButtonText={t("yes")}
            closeButtonHandle={handleNoButton}
            confirmButtonHandle={handleAIContent}
            modalType='generateContent'
            notification={t("overwrite_warning")}
          />
        )}
        {loading && <AILoader />}
      </Box>
    </>
  );
};
