const vodObject = {
  contentType: "Vod",
  input: {
    CommonFields: {
      page: "",
      title: "",
      category: "Vod",
      site_name: "",
      page_state: "DRAFT",
      description: "",
      is_edit: false,
      seo_enable: true,
      analytics_enable: true,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      others: "",
      structure_data: "",
      creationDate: "",
      modificationDate: "",
      tags: ["Video Card"],
      createdBy: "",
      parent_page_url: "/",
      page_lastmodifiedby: "",
      developedby: "",
      page_createdby: "",
      settings: {
        socialog_url: "",
        socialog_twitter_url: "",
        socialog_type: "video article",
        socialog_sitename: "",
        seo_title: "",
        socialog_title: "",
        socialog_twitter_title: "",
        socialog_description: "",
        socialog_twitter_description: "",
        socialog_image: "",
        socialog_twitter_image: "",
        keywords: ["Video Card"],
        seo_keywords: ["Video Card"],
        seo_description: "",
        seo_blockIndexing: true,
      },
      IsConfirm: false,
      is_featured: false,
    },
    ObjectFields: {
      VodAccountId: "",
      PlayerID: "",
      PlayerType: "brightcove",
      VodVideoId: "",
      Poster: "",
      Author: "",
      Thumbnail: "",
      DsapceVideoUrl: "",
    },
  },
};

export default vodObject;
