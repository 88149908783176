/* eslint-disable no-console */
import axios from "axios";
import { differenceInMonths, format } from "date-fns";
import { AUTH_TOKEN, AUTH_URL, HOST, PROJECT_KEY } from "./Constants";

export const getProductCategory = () => {
  return [
    {
      name: "Zagg",
      value: "2bf63006-e4b6-4919-907b-a0f0dfa3b921",
    },
  ];
};
// const setWarrantyDetails = (attributes, currentHost) => {
//   let warrantyRegisteredFrom = "";
//   let IsWarrantyRegistered = false;

//   if (attributes.warrantyDuration && attributes.mainSellerHost === currentHost) {
//     warrantyRegisteredFrom = currentHost;
//     IsWarrantyRegistered = true;
//   }

//   return {
//     warrantyRegisteredFrom,
//     IsWarrantyRegistered,
//   };
// };

export const getReplacmentOptions = () => {
  return [
    {
      name: "Defective Product",
      value: "Defective Product",
    },
    {
      name: "Wrong Item Received",
      value: "Wrong Item Received",
    },
    {
      name: "Size/Color Mismatch",
      value: "Size/Color Mismatch",
    },
    {
      name: "Change of Mind",
      value: "Change of Mind",
    },
  ];
};

export const getProductPurchaseLocation = () => {
  return [
    {
      name: "T-mobile",
      value: "T-mobile,",
    },
    {
      name: "At&T",
      value: "At&T",
    },
  ];
};

const formAddress = (shippingAddress) => {
  const {
    streetNumber = "",
    streetName = "",
    additionalAddressInfo = "",
    city = "",
    postalCode = "",
  } = shippingAddress || {};
  let newAddress = streetNumber || "";
  if (streetName.trim()) newAddress = newAddress ? newAddress + ", " + streetName : streetName;
  if (additionalAddressInfo.trim())
    newAddress = newAddress ? newAddress + ", " + additionalAddressInfo : additionalAddressInfo;
  if (city.trim()) newAddress = newAddress ? newAddress + ", " + city : city;
  if (postalCode.trim()) newAddress = newAddress ? newAddress + ", " + postalCode : postalCode;
  return newAddress;
};

const getOrder = async (config) => {
  return await axios(config)
    .then(function (response) {
      const otherData = {
        shipmentState:
          response?.data?.returnInfo?.[0]?.items?.[0]?.shipmentState ||
          response.data?.shipmentState,
        customFields: response.data?.lineItems?.[0]?.custom?.fields,
        lastModifiedAt: response.data?.lastModifiedAt,
      };
      let data: any = response.data?.lineItems?.reduce((_res: any, item: any) => {
        return {
          id: item?.productId,
          lineItemId: item?.id,
          orderid: response?.data?.id,
          name: item?.name?.en,
          productType: "Power",
          image: item?.variant?.images[0]?.url,
          attributeName: item?.variant?.attributes[0].name,
          attributeValue: item?.variant?.attributes[0].value?.en,
          brand:
            item?.variant?.attributes.length > 0
              ? item.variant.attributes.find((vari) => vari.name === "Brand")?.value?.en
              : "",
          rate: item?.totalPrice?.centAmount,
          deliveryDate: response?.data?.versionModifiedAt,
          deliveryStatus:
            response?.data?.returnInfo?.[0]?.items?.[0]?.shipmentState ||
            response.data?.shipmentState,
          customerEmail: response?.data?.customerEmail,
          customerName:
            response?.data?.shippingAddress?.firstName ||
            response?.data?.shippingAddress?.title +
              " " +
              response?.data?.shippingAddress?.lastName,
          customerPhone: response?.data?.shippingAddress?.phone,
          shippingAddress: formAddress(response?.data?.shippingAddress),
          rewards: "100 SuperCoins Cashback 5% Cashback on AU Bank Card",
          isWarranty: item?.custom?.fields?.is_register_for_warranty === "Yes" ? true : false,
          warrantyPeriod:
            item?.variant?.attributes?.length > 0
              ? item?.variant?.attributes?.find((vari) => vari.name === "product_warranty")?.value
              : "",
          version: response?.data?.version,
          quantity: item?.quantity,
          variantId: item?.variant?.id,
        };
      }, []);
      data = { ...data, ...otherData };
      return data;
    })
    .catch(function () {
      return {};
    });
};

const getOrderDetails = async (ordersData, tokennew) => {
  const orderDetails: any = await Promise.all(
    ordersData.map(async (ord) => {
      const config = {
        method: "get",
        url: `${HOST}/${PROJECT_KEY}/orders/${ord.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokennew}`,
        },
        data: "",
      };
      const orderDetail = await getOrder(config);
      return orderDetail;
    }),
  );
  const sortedList: any = orderDetails?.sort(
    (a, b) => +new Date(b?.lastModifiedAt) - +new Date(a.lastModifiedAt),
  );
  return sortedList;
};

export const getCustomerEmail = () => {
  const userLoginDetails = localStorage.getItem("userLoginDetails");
  if (userLoginDetails) {
    const userDetails = JSON.parse(userLoginDetails);
    return userDetails?.data?.email_id || "teja.jajula@hcl.com";
  }
  return "";
};

// Function to fetch order history from the GraphQL API
export const getOrderHistoryData = async (
  url,
  siteHost,
  searchTerm,
  isOrderSearch = false,
  start = 0,
  rows = 10,
) => {
  // GraphQL query payload
  const data = JSON.stringify({
    query: `
      query {
        publish_getOrderHistory(
          customerEmail: "${getCustomerEmail()}"
          ${isOrderSearch ? "" : `pagination: { start: ${start}, rows: ${rows} }`}
          searchTerm: "${searchTerm}"
          sort: desc
          purchasedFrom: "${siteHost}"
        )
      }
    `,
    variables: {},
  });

  // Request configuration
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      site_host: siteHost,
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    // Make the POST request to the GraphQL endpoint
    const response = await axios.request(config);
    // Return the data
    return response.data;
  } catch (error) {
    // Log and throw any errors that occur
    console.error("Failed to fetch order history", error);
    throw error;
  }
};

export const getData = async (tokennew: any) => {
  let tempData: any = [];
  try {
    const data = JSON.stringify({
      query: {
        or: [
          {
            prefix: {
              field: "all",
              value: getCustomerEmail(),
              caseInsensitive: true,
            },
          },
          {
            fullText: {
              field: "all",
              value: getCustomerEmail(),
            },
          },
        ],
      },
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      limit: 20,
      offset: 0,
    });
    await axios
      .post(`${HOST}/${PROJECT_KEY}/orders/search`, data, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: `Bearer ${tokennew}`,
        },
        withCredentials: true,
      })
      .then(async function (response) {
        tempData = await getOrderDetails(response?.data?.hits, tokennew);
      })
      .catch(function (error) {
        console.error("Error", error);
      });
    return tempData;
  } catch (e) {
    console.error("Error", e);
  }
};

const setCustomFields = async (orderId, token) => {
  const orderConfig = {
    method: "get",
    url: `${HOST}/${PROJECT_KEY}/orders/${orderId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: "",
  };
  const orderDetail = await getOrder(orderConfig);
  // eslint-disable-next-line prefer-destructuring
  // const { lineItemId } = orderDetail[0];
  const data = JSON.stringify({
    version: 1,
    actions: [
      {
        action: "setLineItemCustomType",
        lineItemId: `${orderDetail?.lineItemId}`, //line id
        type: {
          id: "32aed79d-fc5d-45bd-ab9b-d7fb81477b29",
          typeId: "type",
        },
        // fields: {
        //   is_register_for_warranty: "TextString",
        //   warranty_register_by: "TextString",
        //   warranty_registered_from: "TextString",
        //   replaced_order: "TextString",
        // },
      },
    ],
  });

  const config = {
    method: "post",
    url: `${HOST}/${PROJECT_KEY}/orders/${orderId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  await axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  return orderDetail.lineItemId;
};

const setAllValues = (orderId: string, details: any, siteName: string, lineItemId, token, _url) => {
  // const productsData = await getOrderHistoryData(url, siteName, orderId);
  // const fetchedProducts =
  //   productsData?.data?.publish_getOrderHistory?.data[0]?.lineItem[0]?.attributes;

  // const result = setWarrantyDetails(fetchedProducts, siteName);
  //console.log("details33", fetchedProducts);
  console.log("details3", details);

  // let data = JSON.stringify({
  //   version: 1,
  //   actions: [
  //     {
  //       action: "changeShipmentState",
  //       shipmentState: "Ready",
  //     },
  //     {
  //       action: "setCustomerEmail",
  //       email: `${details?.email}`,
  //     },
  //     {
  //       action: "setLineItemCustomField",
  //       lineItemId: `${lineItemId}`,
  //       name: "warranty_registered_from",
  //       value: "",
  //     },
  //     {
  //       action: "setLineItemCustomField",
  //       lineItemId: `${lineItemId}`,
  //       name: "warranty_register_by",
  //       value: `${siteName}`,
  //     },
  //     {
  //       action: "setLineItemCustomField",
  //       lineItemId: lineItemId,
  //       name: "is_register_for_warranty",
  //       value: "No",
  //     },
  //     {
  //       action: "setLineItemCustomField",
  //       lineItemId: lineItemId,
  //       name: "replaced_order",
  //       value: `${isReturned ? selectedProduct : ""}`,
  //     },
  //   ],
  // });
  let data = JSON.stringify({
    version: 1,
    actions: [
      {
        action: "changeShipmentState",
        shipmentState: "Ready",
      },
      {
        action: "setCustomerEmail",
        email: `${details?.email}`,
      },
      {
        action: "setLineItemCustomField",
        lineItemId: `${lineItemId}`,
        name: "warrantyRegisteredFrom",
        value: "",
      },
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "IsWarrantyRegistered",
        value: false,
      },
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "pointOfPurchase",
        value: "",
      },
      {
        action: "setLineItemCustomField",
        lineItemId: lineItemId,
        name: "purchasedFrom",
        value: `${siteName}`,
      },
    ],
  });

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${HOST}/${PROJECT_KEY}/orders/${orderId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      const newVersion = error?.response?.data?.errors[0]?.currentVersion;
      if (newVersion) {
        const newData = JSON.parse(data);
        newData.version = newVersion;
        data = JSON.stringify(newData);
        const configNew = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${HOST}/${PROJECT_KEY}/orders/${orderId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
        axios
          .request(configNew)
          .then((response) => {
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(response.data));
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log("SETEMAILERROR", err);
          });
      }
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const setEmailInCT = async (
  orderId: string,
  details: any,
  siteName: string,
  url: string,
) => {
  const token = await getAccessToken();
  const lineItemId = await setCustomFields(orderId, token);
  console.log("details2", details);
  await setAllValues(orderId, details, siteName, lineItemId, token, url);
};

export const getAccessToken = async () => {
  let temp = "";
  const config = {
    method: "post",
    url: `${AUTH_URL}/oauth/token?grant_type=client_credentials`,
    headers: {
      Authorization: `Basic ${AUTH_TOKEN}`,
    },
  };
  await axios(config)
    .then(function (response) {
      temp = response?.data?.access_token;
    })
    .catch(function (error) {
      console.error("Error:", error);
    });
  return temp;
};
// Function to verify product serial no.
export const verifySerialNo = async (searchTerm, siteName, deliveryEndPointGq) => {
  try {
    const response = await axios.post(
      deliveryEndPointGq,
      {
        // query to verify serial no.
        query: `
          query getOrderHistory($customerEmail: String!, $searchTerm: String!, $start: Int!, $rows: Int!) {
            publish_getOrderHistory(
            customerEmail: $customerEmail
            pagination:{start: $start, rows: $rows}
            sequenceNumber: $searchTerm
            )
          }
        `, // passing variables to graphQl query
        variables: {
          customerEmail: getCustomerEmail(),
          start: 0,
          rows: 25,
          sequenceNumber: searchTerm,
        },
      },
      {
        headers: {
          site_host: siteName,
          "Content-Type": "application/json",
        },
      },
    );

    const { data: { data: { publish_getOrderHistory = {} } = {} } = {} } = response;
    // Extract and return the list of order history
    return publish_getOrderHistory?.data;
  } catch (error) {
    console.error("Product not valid", error);
    //return {};
  }
};
// Function to register product
export const registerMyProduct = async (
  orderId,
  lineItemId,
  siteName,
  deliveryEndPointGq,
  pointOfPurchase,
) => {
  try {
    const response = await axios.post(
      deliveryEndPointGq,
      {
        // query to register product
        query: `
          mutation updateOrderDetails($customerEmail: String!, $lineItemId: String!, $orderId: String!, $updateLineItemFields: [publish_JSON]!) {
            publish_updateOrderDetails(
            customerEmail: $customerEmail
            lineItemId: $lineItemId
            orderId: $orderId
            updateLineItemFields: $updateLineItemFields
            )
          }
        `, // passing variables to graphQl query
        variables: {
          customerEmail: getCustomerEmail(),
          lineItemId: lineItemId,
          orderId: orderId,
          updateLineItemFields: [
            {
              name: "IsWarrantyRegistered",
              value: true,
            },
            {
              name: "warrantyRegisteredFrom",
              value: siteName,
            },
            {
              name: "pointOfPurchase",
              value: pointOfPurchase,
            },
          ],
        },
      },
      {
        headers: {
          site_host: siteName,
          "Content-Type": "application/json",
        },
      },
    );

    const { data: { data: { publish_updateOrderDetails = {} } = {} } = {} } = response;
    // Extract and return the OrderDetails
    return publish_updateOrderDetails;
  } catch (error) {
    console.error("Failed to register product", error);
    return {};
  }
};

export const dateFormat = (dataTime: any) => {
  const date = format(new Date(dataTime), "dd");
  const month = format(new Date(dataTime), "LLL");
  return month + " " + date;
};

export const checkWarrantyAvailable = (deliveryDateTime, warrantyPeriod: any) => {
  const start = new Date(deliveryDateTime);
  const end = new Date();
  const months = differenceInMonths(start, end);
  // eslint-disable-next-line eqeqeq
  return months > warrantyPeriod || warrantyPeriod == 0 ? true : false;
};

export const AmountToDollar = (amt: any) => {
  return amt ? `$${(amt / 100).toFixed(2)}` : "$0.00";
};

export const AmountWithCurrency = (amt: any) => {
  return amt ? `$${(+amt).toFixed(2)}` : "$0.00";
};

export const getRandomNumebrs = () => {
  const { crypto } = window;
  const arrayData = new Uint32Array(1);
  crypto.getRandomValues(arrayData); // Compliant for security-sensitive use cases
  return arrayData?.[0] || 0;
};

export const fetchEcomProducts = async (deliveryEndPointGq, queryParams, sitename) => {
  try {
    // Make the API call to fetch eCommerce products
    const response = await axios.post(
      deliveryEndPointGq,
      {
        query: `
          query fetchEcomProducts($pagePath: String!) {
            publish_fetchEcomProducts(queryParam: $pagePath)
          }
        `,
        variables: {
          pagePath: queryParams,
        },
      },
      {
        headers: {
          site_host: sitename,
          "Content-Type": "application/json",
        },
      },
    );

    // Extract and return the list of fetched eCommerce products
    const { data: { data: { publish_fetchEcomProducts = [] } = {} } = {} } = response;
    return publish_fetchEcomProducts;
  } catch (error) {
    console.error("Error fetching eCommerce products:", error);
    return [];
  }
};
export const fetchEcomProductsV2 = async (deliveryEndPointGq, queryParams, sitename) => {
  try {
    // Make the API call to fetch eCommerce products
    const response = await axios.post(
      deliveryEndPointGq,
      {
        query: `
          query fetchEcomProductsV2($pagePath: String!) {
            publish_fetchEcomProductsV2(queryParam: $pagePath)
          }
        `,
        variables: {
          pagePath: queryParams,
        },
      },
      {
        headers: {
          site_host: sitename,
          "Content-Type": "application/json",
        },
      },
    );

    // Extract and return the list of fetched eCommerce products
    const { data: { data: { publish_fetchEcomProductsV2 = [] } = {} } = {} } = response;
    return publish_fetchEcomProductsV2;
  } catch (error) {
    console.error("Error fetching eCommerce products:", error);
    return [];
  }
};

// Function to getRegisteredProducts
export const getRegisteredProducts = async (
  url,
  siteHost,
  mainSellerHost,
  start = 0,
  rows = 10,
) => {
  // GraphQL query payload
  const data = JSON.stringify({
    query: `
      query {
        publish_getOrderHistory(
          customerEmail: "${getCustomerEmail()}"
          pagination: { start: ${start}, rows: ${rows} }
          sort: desc
          mainSellerHost: "${mainSellerHost}",
          isWarrantyRegistered: true,
        )
      }
    `,
    variables: {},
  });

  // Request configuration
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
      site_host: siteHost,
    },
    data: data,
  };

  try {
    // Make the POST request to the GraphQL endpoint
    const response = await axios.request(config);
    const { data: { data: { publish_getOrderHistory = {} } = {} } = {} } = response;
    // Extract and return the list of order history
    return publish_getOrderHistory;
  } catch (error) {
    // Log and throw any errors that occur
    console.error("Failed to getRegisteredProducts", error);
  }
};

export const returnOrderedLineItem = async (
  url,
  siteHost,
  oldOrderId,
  oldItemID,
  comment,
  newOrderId,
  newLineItemId,
) => {
  // GraphQL query payload
  const data = JSON.stringify({
    query: `
      mutation {
        publish_returnOrderedLineItem(
          customerEmail: "${getCustomerEmail()}"
          orderId: "${oldOrderId}"
          lineItemId: "${oldItemID}"
          quantity: 1
          comment: "${comment}"
          returnTrackingId: "${newOrderId}"
          newLineItemId: "${newLineItemId}"
        )
      }
    `,
    variables: {},
  });

  // Request configuration
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "application/json",
      site_host: siteHost,
    },
    data: data,
  };

  try {
    // Make the POST request to the GraphQL endpoint
    //const response =
    await axios.request(config);
    // const { data: { data: { publish_returnOrderedLineItem = {} } = {} } = {} } = response;
  } catch (error) {
    // Log and throw any errors that occur
    console.error("Failed to returnOrderedLineItem", error);
  }
};
