export const LanguageList = () => {
  return [
    { id: "en", label: "English (UK)" },
    { id: "fr", label: "French" },
    { id: "de", label: "German" },
  ];
};

export const genderData = () => {
  return [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
  ];
};
export const countries = [
  {
    code: "AD",
    label: "+376",
    phone: "376",
    value: "+376",
  },
  {
    code: "AE",
    label: "+971",
    phone: "971",
    value: "+971",
  },
  {
    code: "AF",
    label: "+93",
    phone: "93",
    value: "+93",
  },
  {
    code: "AG",
    label: "+1-268",
    phone: "1-268",
    value: "+1-268",
  },
  {
    code: "AL",
    label: "+355",
    phone: "355",
    value: "+355",
  },
  {
    code: "AM",
    label: "+374",
    phone: "374",
    value: "+374",
  },
  {
    code: "AO",
    label: "+244",
    phone: "244",
    value: "+244",
  },
  {
    code: "AQ",
    label: "+672",
    phone: "672",
    value: "+672",
  },
  {
    code: "AR",
    label: "+54",
    phone: "54",
    value: "+54",
  },
  {
    code: "AS",
    label: "+1-684",
    phone: "1-684",
    value: "+1-684",
  },
  {
    code: "AT",
    label: "+43",
    phone: "43",
    value: "+43",
  },
  {
    code: "AU",
    label: "+61",
    phone: "61",
    suggested: true,
    value: "+61",
  },
  {
    code: "AW",
    label: "+297",
    phone: "297",
    value: "+297",
  },
  {
    code: "AX",
    label: "+358",
    phone: "358",
    value: "+358",
  },
  {
    code: "AZ",
    label: "+994",
    phone: "994",
    value: "+994",
  },
  {
    code: "BA",
    label: "+387",
    phone: "387",
    value: "+387",
  },
  {
    code: "BB",
    label: "+1-246",
    phone: "1-246",
    value: "+1-246",
  },
  {
    code: "BD",
    label: "+880",
    phone: "880",
    value: "+880",
  },
  {
    code: "BE",
    label: "+32",
    phone: "32",
    value: "+32",
  },
  {
    code: "BF",
    label: "+226",
    phone: "226",
    value: "+226",
  },
  {
    code: "BG",
    label: "+359",
    phone: "359",
    value: "+359",
  },
  {
    code: "BH",
    label: "+973",
    phone: "973",
    value: "+973",
  },
  {
    code: "BI",
    label: "+257",
    phone: "257",
    value: "+257",
  },
  {
    code: "BJ",
    label: "+229",
    phone: "229",
    value: "+229",
  },
  {
    code: "BL",
    label: "+590",
    phone: "590",
    value: "+590",
  },
  {
    code: "BM",
    label: "+1-441",
    phone: "1-441",
    value: "+1-441",
  },
  {
    code: "BN",
    label: "+673",
    phone: "673",
    value: "+673",
  },
  {
    code: "BO",
    label: "+591",
    phone: "591",
    value: "+591",
  },
  {
    code: "BR",
    label: "+55",
    phone: "55",
    value: "+55",
  },
  {
    code: "BS",
    label: "+1-242",
    phone: "1-242",
    value: "+1-242",
  },
  {
    code: "BT",
    label: "+975",
    phone: "975",
    value: "+975",
  },
  {
    code: "BV",
    label: "+47",
    phone: "47",
    value: "+47",
  },
  {
    code: "BW",
    label: "+267",
    phone: "267",
    value: "+267",
  },
  {
    code: "BY",
    label: "+375",
    phone: "375",
    value: "+375",
  },
  {
    code: "BZ",
    label: "+501",
    phone: "501",
    value: "+501",
  },
  {
    code: "CA",
    label: "+1",
    phone: "1",
    suggested: true,
    value: "+1",
  },
  {
    code: "CC",
    label: "+61",
    phone: "61",
    value: "+61",
  },
  {
    code: "CD",
    label: "+243",
    phone: "243",
    value: "+243",
  },
  {
    code: "CF",
    label: "+236",
    phone: "236",
    value: "+236",
  },
  {
    code: "CG",
    label: "+242",
    phone: "242",
    value: "+242",
  },
  {
    code: "CH",
    label: "+41",
    phone: "41",
    value: "+41",
  },
  {
    code: "CI",
    label: "+225",
    phone: "225",
    value: "+225",
  },
  {
    code: "CK",
    label: "+682",
    phone: "682",
    value: "+682",
  },
  {
    code: "CL",
    label: "+56",
    phone: "56",
    value: "+56",
  },
  {
    code: "CM",
    label: "+237",
    phone: "237",
    value: "+237",
  },
  {
    code: "CN",
    label: "+86",
    phone: "86",
    value: "+86",
  },
  {
    code: "CO",
    label: "+57",
    phone: "57",
    value: "+57",
  },
  {
    code: "CR",
    label: "+506",
    phone: "506",
    value: "+506",
  },
  {
    code: "CU",
    label: "+53",
    phone: "53",
    value: "+53",
  },
  {
    code: "CV",
    label: "+238",
    phone: "238",
    value: "+238",
  },
  {
    code: "CW",
    label: "+599",
    phone: "599",
    value: "+599",
  },
  {
    code: "CX",
    label: "+61",
    phone: "61",
    value: "+61",
  },
  {
    code: "CY",
    label: "+357",
    phone: "357",
    value: "+357",
  },
  {
    code: "CZ",
    label: "+420",
    phone: "420",
    value: "+420",
  },
  {
    code: "DE",
    label: "+49",
    phone: "49",
    suggested: true,
    value: "+49",
  },
  {
    code: "DJ",
    label: "+253",
    phone: "253",
    value: "+253",
  },
  {
    code: "DK",
    label: "+45",
    phone: "45",
    value: "+45",
  },
  {
    code: "DM",
    label: "+1-767",
    phone: "1-767",
    value: "+1-767",
  },
  {
    code: "DO",
    label: "+1-809",
    phone: "1-809",
    value: "+1-809",
  },
  {
    code: "DZ",
    label: "+213",
    phone: "213",
    value: "+213",
  },
  {
    code: "EC",
    label: "+593",
    phone: "593",
    value: "+593",
  },
  {
    code: "EE",
    label: "+372",
    phone: "372",
    value: "+372",
  },
  {
    code: "EG",
    label: "+20",
    phone: "20",
    value: "+20",
  },
  {
    code: "EH",
    label: "+212",
    phone: "212",
    value: "+212",
  },
  {
    code: "ER",
    label: "+291",
    phone: "291",
    value: "+291",
  },
  {
    code: "ES",
    label: "+34",
    phone: "34",
    value: "+34",
  },
  {
    code: "ET",
    label: "+251",
    phone: "251",
    value: "+251",
  },
  {
    code: "FI",
    label: "+358",
    phone: "358",
    value: "+358",
  },
  {
    code: "FJ",
    label: "+679",
    phone: "679",
    value: "+679",
  },
  {
    code: "FK",
    label: "+500",
    phone: "500",
    value: "+500",
  },
  {
    code: "FM",
    label: "+691",
    phone: "691",
    value: "+691",
  },
  {
    code: "FO",
    label: "+298",
    phone: "298",
    value: "+298",
  },
  {
    code: "FR",
    label: "+33",
    phone: "33",
    suggested: true,
    value: "+33",
  },
  {
    code: "GA",
    label: "+241",
    phone: "241",
    value: "+241",
  },
  {
    code: "GB",
    label: "+44",
    phone: "44",
    value: "+44",
  },
  {
    code: "GD",
    label: "+1-473",
    phone: "1-473",
    value: "+1-473",
  },
  {
    code: "GE",
    label: "+995",
    phone: "995",
    value: "+995",
  },
  {
    code: "GF",
    label: "+594",
    phone: "594",
    value: "+594",
  },
  {
    code: "GG",
    label: "+44",
    phone: "44",
    value: "+44",
  },
  {
    code: "GH",
    label: "+233",
    phone: "233",
    value: "+233",
  },
  {
    code: "GI",
    label: "+350",
    phone: "350",
    value: "+350",
  },
  {
    code: "GL",
    label: "+299",
    phone: "299",
    value: "+299",
  },
  {
    code: "GM",
    label: "+220",
    phone: "220",
    value: "+220",
  },
  {
    code: "GN",
    label: "+224",
    phone: "224",
    value: "+224",
  },
  {
    code: "GP",
    label: "+590",
    phone: "590",
    value: "+590",
  },
  {
    code: "GQ",
    label: "+240",
    phone: "240",
    value: "+240",
  },
  {
    code: "GR",
    label: "+30",
    phone: "30",
    value: "+30",
  },
  {
    code: "GS",
    label: "+500",
    phone: "500",
    value: "+500",
  },
  {
    code: "GT",
    label: "+502",
    phone: "502",
    value: "+502",
  },
  {
    code: "GU",
    label: "+1-671",
    phone: "1-671",
    value: "+1-671",
  },
  {
    code: "GW",
    label: "+245",
    phone: "245",
    value: "+245",
  },
  {
    code: "GY",
    label: "+592",
    phone: "592",
    value: "+592",
  },
  {
    code: "HK",
    label: "+852",
    phone: "852",
    value: "+852",
  },
  {
    code: "HM",
    label: "+672",
    phone: "672",
    value: "+672",
  },
  {
    code: "HN",
    label: "+504",
    phone: "504",
    value: "+504",
  },
  {
    code: "HR",
    label: "+385",
    phone: "385",
    value: "+385",
  },
  {
    code: "HT",
    label: "+509",
    phone: "509",
    value: "+509",
  },
  {
    code: "HU",
    label: "+36",
    phone: "36",
    value: "+36",
  },
  {
    code: "ID",
    label: "+62",
    phone: "62",
    value: "+62",
  },
  {
    code: "IE",
    label: "+353",
    phone: "353",
    value: "+353",
  },
  {
    code: "IL",
    label: "+972",
    phone: "972",
    value: "+972",
  },
  {
    code: "IM",
    label: "+44",
    phone: "44",
    value: "+44",
  },
  {
    code: "IN",
    label: "+91",
    phone: "91",
    value: "+91",
  },
  {
    code: "IO",
    label: "+246",
    phone: "246",
    value: "+246",
  },
  {
    code: "IQ",
    label: "+964",
    phone: "964",
    value: "+964",
  },
  {
    code: "IR",
    label: "+98",
    phone: "98",
    value: "+98",
  },
  {
    code: "IS",
    label: "+354",
    phone: "354",
    value: "+354",
  },
  {
    code: "IT",
    label: "+39",
    phone: "39",
    value: "+39",
  },
  {
    code: "JE",
    label: "+44",
    phone: "44",
    value: "+44",
  },
  {
    code: "JM",
    label: "+1-876",
    phone: "1-876",
    value: "+1-876",
  },
  {
    code: "JO",
    label: "+962",
    phone: "962",
    value: "+962",
  },
  {
    code: "JP",
    label: "+81",
    phone: "81",
    suggested: true,
    value: "+81",
  },
  {
    code: "KE",
    label: "+254",
    phone: "254",
    value: "+254",
  },
  {
    code: "KG",
    label: "+996",
    phone: "996",
    value: "+996",
  },
  {
    code: "KH",
    label: "+855",
    phone: "855",
    value: "+855",
  },
  {
    code: "KI",
    label: "+686",
    phone: "686",
    value: "+686",
  },
  {
    code: "KM",
    label: "+269",
    phone: "269",
    value: "+269",
  },
  {
    code: "KN",
    label: "+1-869",
    phone: "1-869",
    value: "+1-869",
  },
  {
    code: "KP",
    label: "+850",
    phone: "850",
    value: "+850",
  },
  {
    code: "KR",
    label: "+82",
    phone: "82",
    value: "+82",
  },
  {
    code: "KW",
    label: "+965",
    phone: "965",
    value: "+965",
  },
  {
    code: "KY",
    label: "+1-345",
    phone: "1-345",
    value: "+1-345",
  },
  {
    code: "KZ",
    label: "+7",
    phone: "7",
    value: "+7",
  },
  {
    code: "LA",
    label: "+856",
    phone: "856",
    value: "+856",
  },
  {
    code: "LB",
    label: "+961",
    phone: "961",
    value: "+961",
  },
  {
    code: "LC",
    label: "+1-758",
    phone: "1-758",
    value: "+1-758",
  },
  {
    code: "LI",
    label: "+423",
    phone: "423",
    value: "+423",
  },
  {
    code: "LK",
    label: "+94",
    phone: "94",
    value: "+94",
  },
  {
    code: "LR",
    label: "+231",
    phone: "231",
    value: "+231",
  },
  {
    code: "LS",
    label: "+266",
    phone: "266",
    value: "+266",
  },
  {
    code: "LT",
    label: "+370",
    phone: "370",
    value: "+370",
  },
  {
    code: "LU",
    label: "+352",
    phone: "352",
    value: "+352",
  },
  {
    code: "LV",
    label: "+371",
    phone: "371",
    value: "+371",
  },
  {
    code: "LY",
    label: "+218",
    phone: "218",
    value: "+218",
  },
  {
    code: "MA",
    label: "+212",
    phone: "212",
    value: "+212",
  },
  {
    code: "MC",
    label: "+377",
    phone: "377",
    value: "+377",
  },
  {
    code: "MD",
    label: "+373",
    phone: "373",
    value: "+373",
  },
  {
    code: "ME",
    label: "+382",
    phone: "382",
    value: "+382",
  },
  {
    code: "MF",
    label: "+590",
    phone: "590",
    value: "+590",
  },
  {
    code: "MG",
    label: "+261",
    phone: "261",
    value: "+261",
  },
  {
    code: "MH",
    label: "+692",
    phone: "692",
    value: "+692",
  },
  {
    code: "MK",
    label: "+389",
    phone: "389",
    value: "+389",
  },
  {
    code: "ML",
    label: "+223",
    phone: "223",
    value: "+223",
  },
  {
    code: "MM",
    label: "+95",
    phone: "95",
    value: "+95",
  },
  {
    code: "MN",
    label: "+976",
    phone: "976",
    value: "+976",
  },
  {
    code: "MO",
    label: "+853",
    phone: "853",
    value: "+853",
  },
  {
    code: "MP",
    label: "+1-670",
    phone: "1-670",
    value: "+1-670",
  },
  {
    code: "MQ",
    label: "+596",
    phone: "596",
    value: "+596",
  },
  {
    code: "MR",
    label: "+222",
    phone: "222",
    value: "+222",
  },
  {
    code: "MS",
    label: "+1-664",
    phone: "1-664",
    value: "+1-664",
  },
  {
    code: "MT",
    label: "+356",
    phone: "356",
    value: "+356",
  },
  {
    code: "MU",
    label: "+230",
    phone: "230",
    value: "+230",
  },
  {
    code: "MV",
    label: "+960",
    phone: "960",
    value: "+960",
  },
  {
    code: "MW",
    label: "+265",
    phone: "265",
    value: "+265",
  },
  {
    code: "MX",
    label: "+52",
    phone: "52",
    value: "+52",
  },
  {
    code: "MY",
    label: "+60",
    phone: "60",
    value: "+60",
  },
  {
    code: "MZ",
    label: "+258",
    phone: "258",
    value: "+258",
  },
  {
    code: "NA",
    label: "+264",
    phone: "264",
    value: "+264",
  },
  {
    code: "NC",
    label: "+687",
    phone: "687",
    value: "+687",
  },
  {
    code: "NE",
    label: "+227",
    phone: "227",
    value: "+227",
  },
  {
    code: "NF",
    label: "+672",
    phone: "672",
    value: "+672",
  },
  {
    code: "NG",
    label: "+234",
    phone: "234",
    value: "+234",
  },
  {
    code: "NI",
    label: "+505",
    phone: "505",
    value: "+505",
  },
  {
    code: "NL",
    label: "+31",
    phone: "31",
    value: "+31",
  },
  {
    code: "NO",
    label: "+47",
    phone: "47",
    value: "+47",
  },
  {
    code: "NP",
    label: "+977",
    phone: "977",
    value: "+977",
  },
  {
    code: "NR",
    label: "+674",
    phone: "674",
    value: "+674",
  },
  {
    code: "NU",
    label: "+683",
    phone: "683",
    value: "+683",
  },
  {
    code: "NZ",
    label: "+64",
    phone: "64",
    value: "+64",
  },
  {
    code: "OM",
    label: "+968",
    phone: "968",
    value: "+968",
  },
  {
    code: "PA",
    label: "+507",
    phone: "507",
    value: "+507",
  },
  {
    code: "PE",
    label: "+51",
    phone: "51",
    value: "+51",
  },
  {
    code: "PF",
    label: "+689",
    phone: "689",
    value: "+689",
  },
  {
    code: "PG",
    label: "+675",
    phone: "675",
    value: "+675",
  },
  {
    code: "PH",
    label: "+63",
    phone: "63",
    value: "+63",
  },
  {
    code: "PK",
    label: "+92",
    phone: "92",
    value: "+92",
  },
  {
    code: "PL",
    label: "+48",
    phone: "48",
    value: "+48",
  },
  {
    code: "PM",
    label: "+508",
    phone: "508",
    value: "+508",
  },
  {
    code: "PN",
    label: "+870",
    phone: "870",
    value: "+870",
  },
  {
    code: "PR",
    label: "+1",
    phone: "1",
    value: "+1",
  },
  {
    code: "PS",
    label: "+970",
    phone: "970",
    value: "+970",
  },
  {
    code: "PT",
    label: "+351",
    phone: "351",
    value: "+351",
  },
  {
    code: "PW",
    label: "+680",
    phone: "680",
    value: "+680",
  },
  {
    code: "PY",
    label: "+595",
    phone: "595",
    value: "+595",
  },
  {
    code: "QA",
    label: "+974",
    phone: "974",
    value: "+974",
  },
  {
    code: "RE",
    label: "+262",
    phone: "262",
    value: "+262",
  },
  {
    code: "RO",
    label: "+40",
    phone: "40",
    value: "+40",
  },
  {
    code: "RS",
    label: "+381",
    phone: "381",
    value: "+381",
  },
  {
    code: "RU",
    label: "+7",
    phone: "7",
    value: "+7",
  },
  {
    code: "RW",
    label: "+250",
    phone: "250",
    value: "+250",
  },
  {
    code: "SA",
    label: "+966",
    phone: "966",
    value: "+966",
  },
  {
    code: "SB",
    label: "+677",
    phone: "677",
    value: "+677",
  },
  {
    code: "SC",
    label: "+248",
    phone: "248",
    value: "+248",
  },
  {
    code: "SD",
    label: "+249",
    phone: "249",
    value: "+249",
  },
  {
    code: "SE",
    label: "+46",
    phone: "46",
    value: "+46",
  },
  {
    code: "SG",
    label: "+65",
    phone: "65",
    value: "+65",
  },
  {
    code: "SH",
    label: "+290",
    phone: "290",
    value: "+290",
  },
  {
    code: "SI",
    label: "+386",
    phone: "386",
    value: "+386",
  },
  {
    code: "SJ",
    label: "+47",
    phone: "47",
    value: "+47",
  },
  {
    code: "SK",
    label: "+421",
    phone: "421",
    value: "+421",
  },
  {
    code: "SL",
    label: "+232",
    phone: "232",
    value: "+232",
  },
  {
    code: "SM",
    label: "+378",
    phone: "378",
    value: "+378",
  },
  {
    code: "SN",
    label: "+221",
    phone: "221",
    value: "+221",
  },
  {
    code: "SO",
    label: "+252",
    phone: "252",
    value: "+252",
  },
  {
    code: "SR",
    label: "+597",
    phone: "597",
    value: "+597",
  },
  {
    code: "SS",
    label: "+211",
    phone: "211",
    value: "+211",
  },
  {
    code: "ST",
    label: "+239",
    phone: "239",
    value: "+239",
  },
  {
    code: "SV",
    label: "+503",
    phone: "503",
    value: "+503",
  },
  {
    code: "SX",
    label: "+1-721",
    phone: "1-721",
    value: "+1-721",
  },
  {
    code: "SY",
    label: "+963",
    phone: "963",
    value: "+963",
  },
  {
    code: "SZ",
    label: "+268",
    phone: "268",
    value: "+268",
  },
  {
    code: "TC",
    label: "+1-649",
    phone: "1-649",
    value: "+1-649",
  },
  {
    code: "TD",
    label: "+235",
    phone: "235",
    value: "+235",
  },
  {
    code: "TF",
    label: "+262",
    phone: "262",
    value: "+262",
  },
  {
    code: "TG",
    label: "+228",
    phone: "228",
    value: "+228",
  },
  {
    code: "TH",
    label: "+66",
    phone: "66",
    value: "+66",
  },
  {
    code: "TJ",
    label: "+992",
    phone: "992",
    value: "+992",
  },
  {
    code: "TK",
    label: "+690",
    phone: "690",
    value: "+690",
  },
  {
    code: "TL",
    label: "+670",
    phone: "670",
    value: "+670",
  },
  {
    code: "TM",
    label: "+993",
    phone: "993",
    value: "+993",
  },
  {
    code: "TN",
    label: "+216",
    phone: "216",
    value: "+216",
  },
  {
    code: "TO",
    label: "+676",
    phone: "676",
    value: "+676",
  },
  {
    code: "TR",
    label: "+90",
    phone: "90",
    value: "+90",
  },
  {
    code: "TT",
    label: "+1-868",
    phone: "1-868",
    value: "+1-868",
  },
  {
    code: "TV",
    label: "+688",
    phone: "688",
    value: "+688",
  },
  {
    code: "TW",
    label: "+886",
    phone: "886",
    value: "+886",
  },
  {
    code: "TZ",
    label: "+255",
    phone: "255",
    value: "+255",
  },
  {
    code: "UA",
    label: "+380",
    phone: "380",
    value: "+380",
  },
  {
    code: "UG",
    label: "+256",
    phone: "256",
    value: "+256",
  },
  {
    code: "US",
    label: "+1",
    phone: "1",
    suggested: true,
    value: "+1",
  },
  {
    code: "UY",
    label: "+598",
    phone: "598",
    value: "+598",
  },
  {
    code: "UZ",
    label: "+998",
    phone: "998",
    value: "+998",
  },
  {
    code: "VA",
    label: "+379",
    phone: "379",
    value: "+379",
  },
  {
    code: "VC",
    label: "+1-784",
    phone: "1-784",
    value: "+1-784",
  },
  {
    code: "VE",
    label: "+58",
    phone: "58",
    value: "+58",
  },
  {
    code: "VG",
    label: "+1-284",
    phone: "1-284",
    value: "+1-284",
  },
  {
    code: "VI",
    label: "+1-340",
    phone: "1-340",
    value: "+1-340",
  },
  {
    code: "VN",
    label: "+84",
    phone: "84",
    value: "+84",
  },
  {
    code: "VU",
    label: "+678",
    phone: "678",
    value: "+678",
  },
  {
    code: "WF",
    label: "+681",
    phone: "681",
    value: "+681",
  },
  {
    code: "WS",
    label: "+685",
    phone: "685",
    value: "+685",
  },
  {
    code: "XK",
    label: "+383",
    phone: "383",
    value: "+383",
  },
  {
    code: "YE",
    label: "+967",
    phone: "967",
    value: "+967",
  },
  {
    code: "YT",
    label: "+262",
    phone: "262",
    value: "+262",
  },
  {
    code: "ZA",
    label: "+27",
    phone: "27",
    value: "+27",
  },
  {
    code: "ZM",
    label: "+260",
    phone: "260",
    value: "+260",
  },
  {
    code: "ZW",
    label: "+263",
    phone: "263",
    value: "+263",
  },
];

export const CONTENT_ICON = {
  ARTICLE: "machine_assets/1702913611660/public/png/article.png",
  POLL: "machine_assets/1702914792308/public/png/poll1.png",
  QUIZ: "machine_assets/1702914846437/public/png/quiz-(1).png",
  EVENT: "machine_assets/1702913324953/public/png/EventWhiteIcon.png",
  VOD: "machine_assets/1702914916013/public/png/vod.png",
};
export const defaultImages = {
  poll: "machine_assets/1699618236423/public/png/Polls",
  event: "machine_assets/1699618157654/public/png/Event",
  news: "machine_assets/1699618197455/public/png/News",
  challenges: "machine_assets/1699618102923/public/png/Challenges",
  quiz: "machine_assets/1699618268031/public/png/Quiz",
  kudos: "machine_assets/1699618312963/public/png/Kudos",
  ext: "png",
};
export const MESSAGE_API_ERROR = "Error in fetching data";
export const SliderSetting = {
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  dots: false,
  arrow: false,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
