import { styled, Switch } from "@mui/material";

export const CustomSwitch = styled(Switch)(() => ({
  "& .Platform-x-Switch-track": {
    // backgroundColor: "#A0A3BD",
    position: "relative",
    display: "inline-block",
    width: "38px",
    height: "20px",
    margin: "0px",
    borderRadius: "10px",
    cursor: "pointer",
    // opacity: 0.5,
  },
  "& .Platform-x-ButtonBase-root.Platform-x-Switch-switchBase:hover": {
    backgroundColor: "transparent",
  },
  "& .Mui-checked + .Platform-x-Switch-track": {
    backgroundColor: "#14142B",
    opacity: "1 !important",
  },
  "& .Mui-disabled + .Platform-x-Switch-track": {
    opacity: 0.4,
  },
  "& .Platform-x-Switch-thumb": {
    display: "block",
    width: "14px",
    height: "14px",
    top: "0px",
    left: "-1px",
    borderRadius: "16px",
    backgroundColor: "#fff",
    position: "relative",
    transition: "all 200ms ease",
    opacity: 1,
  },
  "& .Platform-x-witch-switchBase.Mui-checked": {
    transform: `translateX(24px)`,
  },
}));
