import { Box, Typography } from "@mui/material";
import { Dropdown } from "../Components/DropDown/Dropdown";
import ControllInfo from "./Common/ControllInfo/ControllInfo";

const FormDropdown = ({ item, register, clearErrors, errors, isRendering }) => {
  return (
    <Box className='formDropdown elementWrapper' mt={1}>
      <Typography
        variant='p4regular'
        className='form-label'
        mt={1}
        mb={1}
        display='flex'
        alignItems='center'>
        <Box component='span' className='form-label-with-info'>
          {item?.title}
        </Box>
        {item?.info?.showMessage && <ControllInfo item={item} />}
      </Typography>
      <Dropdown
        options={item?.options}
        placeholder={item?.placeholder}
        errors={errors[item?.name]}
        register={register}
        field={item}
        clearErrors={clearErrors}
        isRendering={isRendering}
      />
    </Box>
  );
};

export default FormDropdown;
