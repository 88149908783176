import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import ToastService from "../../components/ToastContainer/ToastService";
import { graphQlFormBuilder } from "../../graphql/FormBuilder/fetch";
import { fetchEcomProductsV2 } from "../../prelems/ZaggEcommerce/HelperFunction";
import { useCustomStyle } from "./PlayerAnalysis.style";
import PlayerHeroVideo from "./PlayerHeroVideo";
import PlayerHighlightInfo from "./PlayerHighlightInfo";
import RecentVideos from "./RecentVideos";
import TagListing from "./TagListing";

interface PlayerAnalysisProps {
  title: string;
  description: string;
  banner: string;
}

const PlayerAnalysis: React.FC<PlayerAnalysisProps> = ({
  content,
  analytics,
  authoringHelper,
  secondaryArgs = {},
}: any) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [watchMore, setWatchMore] = useState([]);
  const [vodData, setVodData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [videoLoader, setVideoLoader] = useState(true);
  const [selectedTags, setSelectedTags] = useState<any>(JSON?.parse(content?.tags) || []);

  const {
    prelemBaseEndpoint: { deliveryEndPointGq = "" } = {},
    sitename = "",
    language = "en",
  } = secondaryArgs;
  const classes = useCustomStyle();

  const getVODDetails = async (pagePath = "") => {
    try {
      setVideoLoader(true);
      const contentType = "VOD";
      const { data, errors: APIErrors } = await graphQlFormBuilder.fetchOldContentData(
        {
          pagePath,
          contentType,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (APIErrors) {
        ToastService.failToast(APIErrors.msg || "Error fetching VOD");
      } else {
        const { publish_contentDetail = {} } = data;
        setVodData(publish_contentDetail);
      }
    } catch (error) {
      ToastService.failToast("Error fetching VOD");
    } finally {
      setVideoLoader(false);
    }
  };

  const getVodListing = async () => {
    try {
      // setLoading(true);
      const start = 0,
        rows = 300;
      const tagsToAPI = selectedTags?.length > 0 ? selectedTags : [];
      const filteredTagsToAPI =
        tagsToAPI?.filter((item: string) => item !== "Player Analysis") || [];
      const queryparam: string = `{pagination:{start:${start},rows:${rows}},searchTerm:"",tags:${JSON.stringify(filteredTagsToAPI)},cdpFilter:[],filter:\"Vod\",isSuggestive:false}`;
      const data = await fetchEcomProductsV2(deliveryEndPointGq, queryparam, sitename);
      const [first] = data;
      const pagePath = first?.EditorialItemPath || "";
      await getVODDetails(pagePath);
      setWatchMore(data);
    } catch (error) {
      ToastService.failToast("Error fetching VOD");
    } finally {
      setLoading(false);
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getVodListing();
  }, []);

  const onFilterClick = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const applyFilter = () => {
    getVodListing();
  };

  const onSelectTags = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags((prev) => prev.filter((item) => item !== tag));
      return;
    } else setSelectedTags((prev) => [...prev, tag]);
  };

  return (
    <Box
      ref={authoringHelper?.innerRef}
      className={`${classes.playerAnalysisWrapper} playerAnalysis`}>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          {isFilterOpen ? (
            <Container className='grid_container'>
              <TagListing
                selectedTags={selectedTags}
                onFilterClick={onFilterClick}
                applyFilter={applyFilter}
                onSelectTags={onSelectTags}
                secondaryArgs={secondaryArgs}
                applyingFilterLoading={videoLoader}
              />
            </Container>
          ) : (
            <>
              {/* <HeroBanner content={content} secondaryArgs={secondaryArgs} /> */}
              <Container className='grid_container'>
                <PlayerHighlightInfo onFilterClick={onFilterClick} isFilterOpen={isFilterOpen} />
                <PlayerHeroVideo
                  content={vodData}
                  videoLoader={videoLoader}
                  secondaryArgs={secondaryArgs}
                  analytics={analytics}
                />
                <RecentVideos
                  watchMore={watchMore.filter(
                    (item: any) => item?.EditorialItemPath !== vodData?.page,
                  )}
                  videoLoader={videoLoader}
                  vodData={vodData}
                  loadVODDetails={getVODDetails}
                  secondaryArgs={secondaryArgs}
                />
              </Container>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default PlayerAnalysis;
