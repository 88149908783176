import { Box } from "@mui/material";
import DynamicDropdowns from "../Components/DynamicDropdown/DynamicDropdown";

const FormDynamicDropdown = ({
  item,
  register,
  clearErrors,
  errors,
  isRendering,
  secondaryArgs,
}) => {
  const enabledElement = item?.splitFields?.find((splitFieldItem) => splitFieldItem.enabled);
  return (
    <Box
      className='formTextBox elementWrapper'
      mt={1}
      display={item?.split && item?.enabled ? "flex" : "block"}
      gap={item?.split && item?.enabled ? "12px" : "0px"}>
      {enabledElement ? (
        <Box
          key={enabledElement?.id}
          flex={1}
          className='formNameElements'
          sx={{
            "&:empty": {
              display: "none",
            },
            display: "block",
          }}>
          <DynamicDropdowns
            item={enabledElement}
            register={register}
            errors={errors}
            clearErrors={clearErrors}
            isRendering={isRendering}
            secondaryArgs={secondaryArgs}
            parentElement={item}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default FormDynamicDropdown;
