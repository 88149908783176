import { Box, Divider, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import ControllInfo from "../FormElements/Common/ControllInfo/ControllInfo";
import { LENGTH } from "../constants";

const FormConsentSetting = ({ selectedElement, updateElement, renderValidation }) => {
  const handleChange = (field, value) => {
    updateElement(selectedElement.id, { [field]: value });
  };
  const handleDropdownChange = (field, value) => {
    updateElement(selectedElement.id, { [field]: [Boolean(value)] });
  };
  const item = {
    info: {
      message: "Display text will show in result table",
      showMessage: false,
      icon: "small",
      tooltipPosition: "left",
    },
  };
  return (
    <Box className='formTextBox elementWrapper'>
      <Box className='settingSection'>
        <Box mt={1}>
          <Typography variant='p4regular'>Title Text</Typography>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedElement?.placeholder}
            inputProps={{ maxLength: LENGTH.CONSENT }}
            onChange={(e) => handleChange("placeholder", e?.target?.value)}
          />
          <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
            {`${LENGTH.CONSENT} characters max`}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant='p4regular' sx={{ display: "flex", alignItems: "center" }}>
            Display Text
            <ControllInfo item={item} />
          </Typography>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedElement?.title}
            inputProps={{ maxLength: 20 }}
            onChange={(e) => handleChange("title", e?.target?.value)}
          />
          <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
            20 characters max
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant='p4regular'>Default Consent</Typography>
          <FormControl size='small' fullWidth>
            <Select
              fullWidth
              labelId='selectConsent'
              id='selectConsent'
              value={selectedElement?.default_value?.[0] ? 1 : 0}
              onChange={(e) => handleDropdownChange("default_value", e?.target?.value)}
              sx={{ mt: 1, mb: 2 }}>
              <MenuItem value={1}>Checked</MenuItem>
              <MenuItem value={0}>UnChecked</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {selectedElement?.validations?.length > 0 && (
        <Box className='validationSection' mt={2}>
          <Typography variant='h6semibold'>Validation</Typography>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          {selectedElement?.validations?.map((validationType, index) => (
            <Box key={index}>{renderValidation(validationType)}</Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FormConsentSetting;
