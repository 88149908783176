import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = (selectedType) => {
  const theme = useTheme();
  return makeStyles(() => {
    return {
      formCreatorWrapper: {
        "&.formCreator": {
          minHeight: "520px",
          background: theme.palette?.[selectedType]?.BACKGROUND,
          "& .headingText": {
            color: theme.palette?.[selectedType]?.TITLE,
          },
          "& .descriptionText": {
            color: theme.palette?.[selectedType]?.PARAGRAPH,
          },
          "&.noscroll": {
            overflowY: "hidden !important",
            "& .editForm": {
              minHeight: "480px",
              overflow: "hidden",
            },
          },
          "& .formCreatorPrelem": {
            height: "100%",
            position: "relative",
            maxWidth: "740px",
            margin: "0px auto",
            paddingTop: "0px",
            padding: theme.spacing(2.5),
            border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
            borderRadius: theme.borderRadius.value1,
            "& .formSection": {
              height: "100%",
              "& .mandatory": {
                color: "red",
              },
              "& .form-element": {
                "& .elementWrapper": {
                  "& .address-split-field:empty": {
                    display: "none",
                  },
                  "& .form-label": {
                    "& .form-label-with-info": {
                      wordWrap: "break-word",
                      width: "100%",
                      maxWidth: "700px",
                      display: "inline-block",
                    },
                  },
                  "& .headingText": {
                    wordWrap: "break-word",
                    maxWidth: "700px",
                    display: "inline-block",
                    width: "100%",
                  },
                },
                "& .formDropdown.elementWrapper": {
                  maxHeight: "90px",
                  marginBottom: "8px",
                },
                "& .form-label": {
                  color: theme.palette?.[selectedType]?.LABEL,
                },
                "& .dynamicdropdown": {
                  border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  "&.Mui-focused": {
                    border: "1px solid #1a1a1a",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                  },
                  "& .Platform-x-Select-select.Platform-x-Select-filled": {
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    fontSize: theme.fontSize?.fontSize_14,
                    "&:focus": {
                      backgroundColor: "#fff",
                      borderWidth: "1px",
                    },
                  },
                },
                "& .form-outline.selectfield": {
                  "&.Platform-x-InputBase-root.Platform-x-FilledInput-root": {
                    border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    // backgroundColor: theme.palette?.[selectedType]?.BACKGROUND,
                    "&.Mui-focused": {
                      border: "1px solid #1a1a1a",
                      backgroundColor: "#fff",
                      // backgroundColor: theme.borderRadius.value,
                    },
                  },
                  "& .Platform-x-Select-select.Platform-x-Select-filled": {
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    fontSize: theme.fontSize?.fontSize_14,
                    "&:focus": {
                      backgroundColor: "#fff",
                      borderWidth: "1px",
                    },
                  },
                },
                "& .form-outline.textarea": {
                  border: `1px solid ${theme.palette?.[selectedType]?.LINE}`,
                  backgroundColor: "#fff !important",
                  fontSize: theme.fontSize?.fontSize_14,
                  "&:focus": {
                    outline: "none",
                    backgroundColor: "#fff",
                  },
                  "&.textbox-default": {
                    "&:focus": {
                      border: `1px solid #1a1a1a !important`,
                    },
                  },
                  "&::placeholder": {
                    color: "#9C9CA6", //theme.palette?.[selectedType]?.INPUT.PLACEHOLDER,
                    opacity: 0.9,
                  },
                },
                "& .checkBoxElementWrapper": {
                  "& .checkBoxComponentWrapper": {
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "left",
                    flexDirection: "row-reverse",
                    "& label": {
                      marginRight: "0px",
                      marginTop: "-2px",
                    },
                  },
                },
                "& .toggleElementWrapper": {
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "left",
                  "& p": {
                    marginRight: "10px",
                    marginTop: "5px",
                  },
                  "& label": {
                    "& .Platform-x-Switch-root": {
                      padding: "6px",
                      width: "54px",
                    },
                  },
                },
              },
            },
            "& .add-content-overlay": {
              left: 0,
              borderRadius: theme.borderRadius.value1,
            },
          },
        },
      },
    };
  })();
};
