import { Dashboard } from "@platformx/dashboard";
import { lazy } from "react";

const LazySpace = lazy(() =>
  import(`@platformx/community`).then((module) => ({
    default: module?.Space,
  })),
);
const LazyUserGroupListing = lazy(() =>
  import(`@platformx/user-groups`).then((module) => ({
    default: module?.UserGroupListing,
  })),
);
const LazyUserList = lazy(() =>
  import(`@platformx/user-management`).then((module) => ({
    default: module?.UserListing,
  })),
);
const LazyWorkflowManagement = lazy(() =>
  import(`@platformx/workflow-management`).then((module) => ({
    default: module?.WorkflowManagement,
  })),
);
const LazyNavTreeCreation = lazy(() =>
  import(`@platformx/nav-menu`).then((module) => ({
    default: module?.default,
  })),
);
const LazyHeaderSetting = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.HeaderSetting,
  })),
);
const LazyFooterSetting = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.FooterSetting,
  })),
);
const LazyCookieSetting = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.CookieSetting,
  })),
);
const LazyMediaHandle = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.MediaHandle,
  })),
);
const LazyGlobalSetting = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.GlobalSetting,
  })),
);
const LazyTagListing = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.TagListing,
  })),
);
const LazySiteListing = lazy(() =>
  import(`@platformx/sites`).then((module) => ({
    default: module?.SiteListing,
  })),
);
const LazyAssetListing = lazy(() =>
  import(`@platformx/asset-manager`).then((module) => ({
    default: module?.AssetListing,
  })),
);
const LazyDynamicComponentContainer = lazy(() =>
  import(`@platformx/schema-based-content-type`).then((module) => ({
    default: module?.DynamicComponentContainer,
  })),
);
const LazyContent = lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.Content,
  })),
);
const LazyCollection = lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.Content,
  })),
);
const LazyWorkflowDetails = lazy(() =>
  import(`@platformx/workflow-management`).then((module) => ({
    default: module?.WorkflowDetails,
  })),
);
const LazyCreateUser = lazy(() =>
  import(`@platformx/user-management`).then((module) => ({
    default: module?.CreateUser,
  })),
);
const LazyCreateContent = lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.CreateContent,
  })),
);
const LazyCreateSpace = lazy(() =>
  import(`@platformx/community`).then((module) => ({
    default: module?.CreateSpace,
  })),
);
const LazyCommonContentRender = lazy(() =>
  import(`@platformx/content-preview`).then((module) => ({
    default: module?.CommonContentRender,
  })),
);
const LazyCreateCourse = lazy(() =>
  import(`@platformx/course`).then((module) => ({
    default: module?.CreateCourse,
  })),
);
const LazyCreateAsset = lazy(() =>
  import(`@platformx/asset-manager`).then((module) => ({
    default: module?.CreateAsset,
  })),
);
const LazyFeatureFlagSetting = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.FeatureFlagSetting,
  })),
);
const LazyCategoryDetail = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.CategoryDetail,
  })),
);
const LazyCreateTags = lazy(() =>
  import(`@platformx/site-setting`).then((module) => ({
    default: module?.CreateTags,
  })),
);
const LazyAddSite = lazy(() =>
  import(`@platformx/sites`).then((module) => ({
    default: module?.AddSite,
  })),
);
const LazyCreateArticle = lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.CreateArticle,
  })),
);
const LazyCreateUserGroup = lazy(() =>
  import(`@platformx/user-groups`).then((module) => ({
    default: module?.CreateUserGroup,
  })),
);
const LazyTimeLineBlogs = lazy(() =>
  import(`@platformx/content`).then((module) => ({
    default: module?.TimeLineBlogs,
  })),
);
const LazySearchPrelem = lazy(() =>
  import(`@platformx/site-page`).then((module) => ({
    default: module?.SearchPrelem,
  })),
);
const LazyPrelemInfo = lazy(() =>
  import(`@platformx/site-page`).then((module) => ({
    default: module?.PrelemInfo,
  })),
);
const LazyEditPage = lazy(() =>
  import(`@platformx/site-page`).then((module) => ({
    default: module?.EditPage,
  })),
);
const LazyAccessDenied = lazy(() =>
  import(`@platformx/utilities`).then((module) => ({
    default: module?.AccessDenied,
  })),
);
const LazyCharts = lazy(() =>
  import(`@platformx/charts`).then((module) => ({
    default: module?.Charts,
  })),
);
const LazyCommonPreview = lazy(() =>
  import(`@platformx/content-preview`).then((module) => ({
    default: module?.CommonPreview,
  })),
);
const LazyEcommerce = lazy(() =>
  import(`@platformx/report`).then((module) => ({
    default: module?.Ecommerce,
  })),
);
const LazyLoyalty = lazy(() =>
  import(`@platformx/report`).then((module) => ({
    default: module?.Loyalty,
  })),
);
const LazyFormBuilder = lazy(() =>
  import(`@platformx/form-builder`).then((module) => ({
    default: module?.FormBuilderAuthoringContainer,
  })),
);
//This component is for run prelem component on local
const LazyPrelemComponent = lazy(() =>
  import("../../components/PrelemLibrary/PrelemComponent").then((module) => ({
    default: module?.default,
  })),
);

const LazyFormView = lazy(() =>
  import(`@platformx/form-builder`).then((module) => ({
    default: module?.FormView,
  })),
);

const Components = {
  dashboard: Dashboard,
  space: LazySpace,
  userGroupListing: LazyUserGroupListing,
  userListing: LazyUserList,
  workflowManagement: LazyWorkflowManagement,
  navTreeCreation: LazyNavTreeCreation,
  headerSetting: LazyHeaderSetting,
  footerSetting: LazyFooterSetting,
  cookieSetting: LazyCookieSetting,
  mediaHandle: LazyMediaHandle,
  globalSetting: LazyGlobalSetting,
  tagListing: LazyTagListing,
  siteListing: LazySiteListing,
  assetListing: LazyAssetListing,
  dynamicComponentContainer: LazyDynamicComponentContainer,
  content: LazyContent,
  collection: LazyCollection,
  workflowDetails: LazyWorkflowDetails,
  createUser: LazyCreateUser,
  createContent: LazyCreateContent,
  createSpace: LazyCreateSpace,
  commonContentRender: LazyCommonContentRender,
  createCourse: LazyCreateCourse,
  createAsset: LazyCreateAsset,
  featureFlagSetting: LazyFeatureFlagSetting,
  categoryDetail: LazyCategoryDetail,
  createTags: LazyCreateTags,
  addSite: LazyAddSite,
  createArticle: LazyCreateArticle,
  createUserGroup: LazyCreateUserGroup,
  timeLineBlogs: LazyTimeLineBlogs,
  searchPrelem: LazySearchPrelem,
  prelemInfo: LazyPrelemInfo,
  editPage: LazyEditPage,
  accessDenied: LazyAccessDenied,
  userEngagement: LazyCharts,
  sitePerformance: LazyCharts,
  prelemPreview: LazyCommonPreview,
  pagePreview: LazyCommonPreview,
  contentPreview: LazyCommonPreview,
  ecommerce: LazyEcommerce,
  loyalty: LazyLoyalty,
  formBuilder: LazyFormBuilder,
  prelemComponent: LazyPrelemComponent,
  formView: LazyFormView,
};

export default Components;
