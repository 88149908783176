import { capitalizeFirstLetter } from "../utils/helperFns";

export const linkCopy = "linkCopy";
export const errorRequest = "errorRequest";
export const PROJECT_CATCH_ERROR_LIST = {
  authoring: "authoringError",
  userExp: "user-experienceError",
  x_prelem_lib: "x-prelem-library",
  site_setting: "site setting",
  utilities: "utilities",
};
export const FREEFORM = "freeform";

export const ratios = {
  hero: "3 / 1",
  landscape: "16 / 9",
  card2: "4 / 3",
  square: "1 / 1",
  card1: "2 / 3",
  portrait: "9 / 16",
  [FREEFORM]: "auto",
};

export const fractionalRatio = {
  hero: 3 / 1,
  landscape: 16 / 9,
  card2: 4 / 3,
  square: 1,
  card1: 2 / 3,
  portrait: 9 / 16,
};

export const breakpoints = [
  { breakpoint: 1440, ratio: "landscape" },
  { breakpoint: 1280, ratio: "landscape" },
  { breakpoint: 1024, ratio: "portrait" },
  { breakpoint: 768, ratio: "portrait" },
  { breakpoint: 600, ratio: "square" },
  { breakpoint: 320, ratio: "square" },
];

export const IMAGE_ORDER_MULTI = {
  1440: "hero",
  1280: "landscape",
  1024: "card2",
  768: "square",
  600: "card2",
  320: "card2",
};

export const IMAGE_ORDER_SINGLE = {
  1440: FREEFORM,
  1280: FREEFORM,
  1024: FREEFORM,
  768: FREEFORM,
  600: FREEFORM,
  320: FREEFORM,
};

export const getSingleCropBreakpoint = (ratioName) => {
  return [
    {
      breakpoint: breakpoints.find((obj) => obj.ratio === ratioName)?.breakpoint || 320,
      ratio: ratioName,
      ratioName: capitalizeFirstLetter(ratioName),
      aspectRatio: fractionalRatio[ratioName] || 4 / 3,
      aspectRatioName: ratios[ratioName],
    },
  ];
};

export const getImgOrder = (content) => {
  if (content?.aspectRatio) {
    return content?.aspectRatio;
  }
  if (content?.cropType?.toLowerCase() === "single") {
    return IMAGE_ORDER_MULTI;
  }
  return IMAGE_ORDER_SINGLE;
};

export const breakpointsdata = [
  {
    breakpoint: 1440,
    ratio: "landscape",
    ratioName: "Landscape",
    aspectRatio: 16 / 9,
    aspectRatioName: "16 / 9",
  },
  {
    breakpoint: 1280,
    ratio: "square",
    ratioName: "Square",
    aspectRatio: 1,
    aspectRatioName: "1 / 1",
  },
  {
    breakpoint: 1024,
    ratio: "portrait",
    ratioName: "Portrait",
    aspectRatio: 9 / 16,
    aspectRatioName: "9 / 16",
  },
  {
    breakpoint: 768,
    ratio: "hero",
    ratioName: "Hero",
    aspectRatio: 3 / 1,
    aspectRatioName: "3 / 1",
  },
  {
    breakpoint: 600,
    ratio: "card1",
    ratioName: "Card",
    aspectRatio: 2 / 3,
    aspectRatioName: "2 / 3",
  },
  {
    breakpoint: 320,
    ratio: "card2",
    ratioName: "Standard",
    aspectRatio: 4 / 3,
    aspectRatioName: "4 / 3",
  },
];

export const IMAGE_FALLBACK_PRELEM = {
  aspect_ratio: "card2",
  folder_path: "machine_assets/1690001203561/public/png/Aboutus-card2",
};

export const DASHBOARD_KEYS = {
  SITE_PAGE: "Sitepage",
  DASHBOARD: "dashboard",
  BOOST_PAGE: "boostpage",
  SCHEDULED_PUBLISH: "SCHEDULED_PUBLISH",
  SCHEDULED_UNPUBLISH: "SCHEDULED_UNPUBLISH",
  DESC: "DESC",
  ALL: "ALL",
  ZERO: 0,
};

export const SORT_ORDER = "DESC";
export const DefaultLocale = "en";
export const DefaultLanguage = "English (UK)";
export const CATEGORY_CONTENT = "content";
export const CATEGORY_PAGE = "Page";
export const SUB_CATEGORY_PAGE = "sitepage";
export const SORTED_ORDER = "ASC";
export const MENU_STATE_DRAFT = "DRAFT";
export const MENU_TAGGING = "Navigation";
export const USERNAME_EMAIL_EXIST = "Username already exist!";
export const SITE_PAGE = "SitePage";
export const SITE_SETTING = "SiteSetting";

export const CONTENT_TYPE_WITH_ABSOLUTEURL = ["VOD", "Course", "Product"];
export const CONTENT_TYPE = {
  PAGE: "page",
  ARTICLE: "article",
  VOD: "vod",
  POLL: "poll",
  QUIZ: "quiz",
  EVENT: "event",
};
export const SNOWPLOW = {
  NA: "NA",
  GERMAN: "German",
  FRENCH: "French",
  ENGLISH: "English",
  SNOWPLOW: "snowplow",
  TRACKID: "selfDescribingEvent",
  IMPRESSIONTYPE: "Page Impression",
  SNOWPLOWLABEL: "snowplow - Page Impression",
  REGISTERFORM: "Rendering",
  CONTENT_TYPE: {
    PAGE: "Page",
    EVENT: "Event",
    VIDEO: "Video",
    ARTICLE: "Article",
    POLL: "Poll",
    QUIZ: "Quiz",
  },
};

export const MAPPING = {
  Article: "Article",
  Poll: "Poll",
  Quiz: "Quiz",
  Event: "Event",
  Vod: "VideoLandingPage",
  Header: "Header",
  Footer: "Footer",
};
export const HIDE_HEADER_FOOTER = ["Poll", "Quiz"];

export const CROP_TYPE = {
  AUTO: "auto-crop",
  MANUAL: "manual-crop",
  NOCROP: "no-crop",
};
export const MAX_FILE_SIZE = {
  images: 7,
  videos: 144,
};

export const MAX_FILE_TITLE_LEN = 50;

export const TYPE_OF_ASSET = {
  images: "image",
  videos: "video",
};

export const ASSET_TYPE = {
  Image: "images",
  Video: "videos",
};

export const FILE_FORMAT = {
  images: "JPG, JPEG, PNG, ICO",
  videos: "MP4",
};

export const VALID_EXTENSION = {
  images: ["jpg", "jpeg", "png", "ico"],
  videos: ["mp4"],
};

export const getAcceptedFileFormats = (assetType: "images" | "videos") => {
  if (!(assetType in TYPE_OF_ASSET)) {
    console.error(`${PROJECT_CATCH_ERROR_LIST.utilities}:HTTP error! Status ${assetType}`);
  }
  const acceptedFileFormat = Object.entries(VALID_EXTENSION).reduce((acc, [key, value]) => {
    acc[key] = value.map((val) => `${TYPE_OF_ASSET[assetType]}/${val}`).join(", ");
    return acc;
  }, {});

  return acceptedFileFormat[assetType];
};

export const OLD_CONTENT_TYPES = [
  "Article",
  "VOD",
  "Quiz",
  "Poll",
  "Event",
  "Sitepage",
  "formbuilder",
];
