import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { fetchValidations, getMaxLength, handleLength } from "../../helper";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";
import MaxLengthValidationMessage from "../Error/MaxLengthValidationMessage";

const TextBox = ({ field, errors, isDisabled, register, clearErrors, isRendering }: any) => {
  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });
  const validations = fetchValidations(field?.validations);
  return (
    <Box>
      <Box display='flex' alignItems='baseline'>
        <TextField
          // variant={"filled"}
          size='small'
          type={field?.type}
          name={field?.name}
          className={isRendering ? "form-outline titlefield" : "authoring titlefield"}
          placeholder={field?.placeholder}
          id={field?.name}
          error={errors?.[field?.name]}
          // disabled={isDisabled}
          sx={{
            width: "100%",
            input: { cursor: isDisabled && "not-allowed" },
          }}
          inputProps={{
            readOnly: !isRendering,
            maxLength: getMaxLength(field?.validations),
          }}
          onMouseDown={(event) => !isRendering && event.preventDefault()}
          {...register(field?.name, {
            ...validations,
            onChange: (event) => {
              if (errors?.[field?.name]) {
                clearErrors(field?.name);
              }
              const { target: { value = "" } = {} } = event;
              handleLength(value, field?.validations, setRestOfChar, restOfChar);
              // if (handleChange) {
              //   handleChange(event);
              // }
            },
          })}
        />
      </Box>
      <ErrorMessageHandle error={errors?.[field?.name]} />
      <MaxLengthValidationMessage
        validations={field?.validations}
        error={errors?.[field?.name]}
        restOfChar={restOfChar}
      />
    </Box>
  );
};
export default TextBox;
