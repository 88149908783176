import { Box, FormControlLabel } from "@mui/material";
import { fetchValidations } from "../../helper";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";
import { CustomSwitch } from "./CustomSwitch";

const Toggle = ({ field, register, errors, isRendering }) => {
  const validations = fetchValidations(field?.validations);
  return (
    <Box>
      <FormControlLabel
        control={
          <CustomSwitch
            defaultChecked={field?.default_value?.[0]}
            disabled={!isRendering}
            aria-label='switch'
            {...(!isRendering ? { checked: field?.default_value?.[0] } : {})}
            {...register(field?.name, {
              ...validations,
            })}
          />
        }
        label=''
      />
      <ErrorMessageHandle error={errors?.[field?.name]} />
    </Box>
  );
};
export default Toggle;
