import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import CountryStateCitySettings from "./DropdownSettings/CountryStateCitySettings";
import CustomSettings from "./DropdownSettings/CustomSettings";
import SimpleSetting from "./DropdownSettings/SimpleSetting";

const FormDropDownSetting = ({
  parentElement,
  selectedElement,
  updateElement,
  updateCustomDropdown,
  renderValidation,
}) => {
  const [dynamicSelect, setDynamicSelect] = useState("custom");

  const handleDynamicDrownChange = (event: any) => {
    const { value } = event.target;
    setDynamicSelect(value);
    const selectedValue = [value];
    updateCustomDropdown(selectedValue);
  };

  return (
    <>
      {selectedElement.enabled ? (
        <Box className='formDropdown elementWrapper'>
          <Box mt={1}>
            <Box
              mt={2}
              className={parentElement?.dropdownList?.length > 1 ? "multi-menu" : "single-menu"}>
              <Typography variant='p4regular' id='select-dynamic-dropdown'>
                Select Pre-defined List
              </Typography>
              <Select
                fullWidth
                labelId='default-simple-select-label'
                id='default-simple-select-label'
                value={selectedElement?.type || dynamicSelect}
                name={"selectedDefaultValue"}
                onChange={handleDynamicDrownChange}
                sx={{ mt: 1, mb: 2 }}>
                {parentElement.dropdownList?.map((item) => (
                  <MenuItem key={item?.label} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          {(() => {
            switch (selectedElement?.type) {
              case "custom":
                return (
                  <CustomSettings
                    selectedElement={selectedElement}
                    updateElement={updateElement}
                    renderValidation={renderValidation}
                  />
                );
              case "country":
              case "country-state":
              case "country-state-city":
                return (
                  <CountryStateCitySettings
                    selectedElement={selectedElement}
                    updateElement={updateElement}
                    parentElement={parentElement}
                  />
                );
              default:
                return (
                  <SimpleSetting updateElement={updateElement} selectedElement={selectedElement} />
                );
            }
          })()}
        </Box>
      ) : null}
    </>
  );
};

export default FormDropDownSetting;
