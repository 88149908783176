import { Box, Divider, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { LENGTH } from "../constants";

const FormDateSetting = ({ selectedElement, updateElement, renderValidation }) => {
  const [value, setValue] = useState<Dayjs | null>(dayjs("2022-04-17"));
  /**
   * Update the textBox placeholder/Title of the selected element.
   * updateElement is a function that updates the selected element with the new value.
   */
  const handleChange = (field, fieldValue) => {
    updateElement(selectedElement.id, { [field]: fieldValue });
  };
  // const updateDateTimepicker = (field, newValue) => {
  //   setValue(newValue)
  //   updateElement(selectedElement.id, { [field]: fieldValue });
  // };

  return (
    <Box className='formTextBox elementWrapper'>
      {/* <Typography variant='h5semibold'>Text Box Setting</Typography>
      <Box mt={1.5} mb={1.5}>
        <Divider />
      </Box> */}
      <Box mt={1}>
        <Typography variant='p4regular'>Title Text</Typography>
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          value={selectedElement?.title}
          inputProps={{ maxLength: LENGTH.TITLE }}
          onChange={(e) => handleChange("title", e?.target?.value)}
        />
        <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
          {`${LENGTH.TITLE} characters max`}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant='p4regular'>Placeholder Text</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label=''
            value={value}
            onChange={(newValue) => setValue(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      {selectedElement?.validations?.length > 0 && (
        <Box className='validationSection' mt={2}>
          <Typography variant='h6semibold'>Validation</Typography>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          {selectedElement?.validations?.map((validationType) => renderValidation(validationType))}
        </Box>
      )}
    </Box>
  );
};

export default FormDateSetting;
