import { Box } from "@mui/material";
import FormItem from "../FormItem";
import FormTextBox from "./FormTextBox";

const FormName = ({
  item,
  register,
  clearErrors,
  errors,
  isRendering,
  setSelectedElement,
  selectedElement,
  secondaryArgs,
}) => {
  return (
    <Box
      className='formTextBox elementWrapper'
      mt={1}
      sx={{
        display: { xs: "block", sm: "flex" },
        flexDirection: item?.split ? "row" : "column",
        gap: item?.split ? "12px" : 0,
      }}>
      {item?.split ? (
        item?.splitFields?.map((splitFieldItem) => (
          <Box
            key={splitFieldItem?.id}
            flex={1}
            className='formNameElements'
            sx={{
              "&:empty": {
                display: "none",
              },
              display: "block",
            }}>
            <FormItem
              item={splitFieldItem}
              register={register}
              clearErrors={clearErrors}
              errors={errors}
              setSelectedElement={setSelectedElement}
              selectedElement={selectedElement}
              isRendering={isRendering}
              secondaryArgs={secondaryArgs}
            />
          </Box>
        ))
      ) : (
        <Box>
          <FormTextBox
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}></FormTextBox>
        </Box>
      )}
    </Box>
  );
};

export default FormName;
