import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";

const ControllInfo = ({ item }) => {
  return (
    <Box m='0px 5px' height='17px'>
      <Tooltip
        title={
          <Box
            sx={{
              maxWidth: "250px", // Limit tooltip width
              maxHeight: "150px", // Limit tooltip height
              overflowY: "auto", // Enable vertical scrolling
              overflowX: "hidden", // Hide horizontal scrolling
              whiteSpace: "normal", // Wrap text instead of single-line
            }}>
            {item?.info?.message}
          </Box>
        }
        placement='top'
        className='infoTooltip'>
        <InfoOutlinedIcon
          fontSize='small'
          sx={{ width: "17px", height: "17px" }}></InfoOutlinedIcon>
      </Tooltip>
    </Box>
  );
};

export default ControllInfo;
