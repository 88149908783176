import { Box, TextField, Typography, useTheme } from "@mui/material";
import { BasicSwitch } from "@platformx/utilities";

const CountryStateCitySettings = ({ selectedElement, updateElement, parentElement }) => {
  const theme = useTheme();

  const handleChange = (splitFieldItem, field, value) => {
    updateElement(splitFieldItem?.id, {
      [field]: value,
    });
  };

  const countryField = parentElement?.splitFields?.find((item) => item?.type === "country");
  const stateField = parentElement?.splitFields?.find((item) => item?.type === "country-state");
  const cityField = parentElement?.splitFields?.find((item) => item?.type === "country-state-city");

  return (
    <>
      {(() => {
        switch (selectedElement.type) {
          case "country":
            return (
              <Box mt={1}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='p4regular'>{countryField?.label} Country Title</Typography>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  value={countryField?.title}
                  onChange={(e) => {
                    handleChange(countryField, "title", e.target.value);
                  }}
                />
              </Box>
            );
          case "country-state":
            return (
              <Box mt={1}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='p4regular'>{countryField?.label} Country Title</Typography>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  value={countryField?.title}
                  onChange={(e) => {
                    handleChange(countryField, "title", e.target.value);
                  }}
                />
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='p4regular'>{stateField?.label} State Title</Typography>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  value={stateField?.title}
                  onChange={(e) => {
                    handleChange(stateField, "title", e.target.value);
                  }}
                />
                <Box className='informationSection' mt={2}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='p4semibold'>Align horizontal</Typography>
                    <BasicSwitch
                      onChange={(e) => handleChange(stateField, "alignRow", e?.target?.checked)}
                      checked={selectedElement?.alignRow || false}
                      color={theme?.palette?.customColor?.PRIMARY}
                    />
                  </Box>
                </Box>
              </Box>
            );
          case "country-state-city":
            return (
              <Box mt={1}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='p4regular'>{countryField?.label} Country Title</Typography>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  value={countryField?.title}
                  onChange={(e) => {
                    handleChange(countryField, "title", e.target.value);
                  }}
                />
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='p4regular'>{stateField?.label} State Title</Typography>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  value={stateField?.title}
                  onChange={(e) => {
                    handleChange(stateField, "title", e.target.value);
                  }}
                />
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='p4regular'>{cityField?.label} City Title</Typography>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  value={cityField?.title}
                  onChange={(e) => {
                    handleChange(cityField, "title", e.target.value);
                  }}
                />
                <Box className='informationSection' mt={2}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='p4semibold'>Align horizontal</Typography>
                    <BasicSwitch
                      onChange={(e) => handleChange(cityField, "alignRow", e?.target?.checked)}
                      checked={selectedElement?.alignRow || false}
                      color={theme?.palette?.customColor?.PRIMARY}
                    />
                  </Box>
                </Box>
              </Box>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

export default CountryStateCitySettings;
