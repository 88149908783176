import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fetchValidations } from "../../helper";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  backgroundColor: theme.palette.customColor.LINE,
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.customColor.PRIMARY,
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    background: `radial-gradient(${theme.palette.customColor.BGLIGHT},${theme.palette.customColor.BGLIGHT} 44%,transparent 49%)`,
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.customColor.PRIMARY,
  },
}));

const RadioGroupButtons = ({
  options,
  activeColor = "default",
  field,
  row,
  error,
  register,
  isRendering,
}: any) => {
  const validations = fetchValidations(field?.validations);
  return (
    <Box>
      <RadioGroup
        name={field?.name}
        row={row}
        defaultValue={field?.default_value?.[0]}
        {...(!isRendering ? { value: field?.default_value?.[0] } : {})}>
        {options.map((option) => (
          <FormControlLabel
            key={`${field?.name}-${option?.value}`}
            sx={{ marginRight: "20px", marginLeft: "-9px" }}
            value={option.value}
            // {...(!isRendering ? { value: field?.default_value?.[0] } : { value: val.value })}
            control={
              <Radio
                disableRipple
                color={activeColor}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                disabled={!isRendering}
                {...register(field?.name, {
                  ...validations,
                })}
              />
            }
            label={<Typography variant='p4regular'>{option?.label}</Typography>}
          />
        ))}
      </RadioGroup>
      <ErrorMessageHandle error={error} />
    </Box>
  );
};

export default RadioGroupButtons;
