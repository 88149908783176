import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { fetchValidations } from "../../helper";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

const CheckboxGroup = ({
  options,
  activeColor = "default",
  field,
  row,
  error,
  register,
  isRendering,
}: any) => {
  const validations = fetchValidations(field?.validations);
  return (
    <Box>
      <FormGroup row={row} sx={{ display: row ? "block" : "flex" }}>
        {options.map((option) => (
          <FormControlLabel
            key={`${field?.name}-${option?.value}`}
            control={
              <Checkbox
                disableRipple
                color={activeColor}
                disabled={!isRendering}
                {...register(field?.name, {
                  ...validations,
                })}
                value={option.value}
                {...(!isRendering ? { checked: field?.default_value.includes(option.value) } : {})}
                defaultChecked={field?.default_value?.includes(option.value)}
              />
            }
            label={<Typography variant='p4regular'>{option?.label}</Typography>}
          />
        ))}
      </FormGroup>
      <ErrorMessageHandle error={error} />
    </Box>
  );
};

export default CheckboxGroup;
