import { Box, FormControl, Typography } from "@mui/material";
import { Dropdown } from "../DropDown/Dropdown";

const SimpleDropdown = ({ field, register, errors, clearErrors, isRendering }) => {
  return (
    <Box>
      <FormControl fullWidth>
        <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
          {field?.title}
        </Typography>
        <Dropdown
          options={field.options}
          placeholder={field?.placeholder}
          errors={errors[field?.name]}
          register={register}
          field={field}
          clearErrors={clearErrors}
          isRendering={isRendering}
        />
      </FormControl>
    </Box>
  );
};

export default SimpleDropdown;
