import { Box, Typography } from "@mui/material";
import TextArea from "../Components/TextArea/TextArea";
import { checkRequired } from "../helper";
import ControllInfo from "./Common/ControllInfo/ControllInfo";

const FormTextArea = ({ item, register, clearErrors, errors, isRendering }) => {
  return (
    <Box className='formTextArea elementWrapper' mt={1}>
      <Typography
        variant='p4regular'
        className='form-label'
        mt={1}
        mb={1}
        display='flex'
        alignItems='flex-start'>
        <Box component='span' className='form-label-with-info'>
          {item?.title}
          {checkRequired(item?.validations)}
        </Box>
        {item?.info?.showMessage && <ControllInfo item={item} />}
      </Typography>
      <TextArea
        field={item}
        errors={errors}
        register={register}
        clearErrors={clearErrors}
        minRows={4}
        isRendering={isRendering}
        isDisabled={!isRendering}
      />
    </Box>
  );
};

export default FormTextArea;
