import getGraphQlInstance from "../graphqlConfig";
import { FETCH_TAG_LIST_QUERY } from "./queries";

const graphQlList = {
  getTags: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: FETCH_TAG_LIST_QUERY,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
};

export default graphQlList;
