import { Box, Divider, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BasicSwitch } from "@platformx/utilities";
import { LENGTH } from "../../constants";

const InfoSettings = ({ updateElement, selectedElement }) => {
  const theme = useTheme();
  return (
    <Box className='informationSection' mt={2}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='p4semibold'>Info Setting</Typography>
        <BasicSwitch
          onChange={() =>
            updateElement(selectedElement.id, {
              info: {
                ...selectedElement?.info,
                showMessage: !selectedElement?.info?.showMessage,
              },
            })
          }
          checked={selectedElement?.info?.showMessage || false}
          color={theme?.palette?.customColor?.PRIMARY}
        />
      </Box>
      {selectedElement?.info?.showMessage && (
        <>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          <Box mt={1}>
            <Typography variant='p4regular'>Tooltip Message</Typography>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              value={selectedElement?.info?.message}
              inputProps={{ maxLength: LENGTH.INFO }}
              onChange={(e) =>
                updateElement(selectedElement.id, {
                  info: { ...selectedElement?.info, message: e?.target?.value },
                })
              }
            />
            <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
              {`${LENGTH.INFO} characters max`}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default InfoSettings;
