import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { dynamicAPICall, isNotNumber } from "../../../helper";

interface City {
  id: string;
  label: string;
}

const CityList = ({
  selectedCountry,
  selectedState,
  field,
  register,
  secondaryArgs,
  countryLoading,
  stateLoading,
}) => {
  const [cityList, setCityList] = useState<City[]>([]);
  const [city, setCity] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCountry && selectedState && selectedState !== "No Selection") {
      const fetchCity = async () => {
        const splitSelectedState = selectedState.split("-")?.[1];
        const splitSelectedCountry = selectedCountry.split("-")?.[1];
        setLoading(true);
        const obj = {
          query: `query {publish_getDynamicDropDownItems(api_variant:"Cities", filter:{CountryCode:${splitSelectedCountry}, StateCode:${splitSelectedState}})}`,
          responseVariable: "publish_getDynamicDropDownItems",
        };
        try {
          const result = await dynamicAPICall(obj.query, obj.responseVariable, secondaryArgs);
          if (result && result.length > 0 && isNotNumber(selectedState)) {
            setCityList(result);
            let mappedResults = result.map((item) => ({
              id: `${item.label}-${item.id}`,
              label: item.label,
            }));
            mappedResults = [{ label: "Select", id: "No Selection" }, ...mappedResults];
            setCityList(mappedResults);
            setCity(mappedResults[0]?.id); // Set default state if not already selected
            // setSelectedCity(result[0]?.id);
          }
        } catch (error) {
          console.error("Error fetching states:", error);
          setCityList([]); // Reset state list on error
        } finally {
          setLoading(false);
        }
      };

      selectedCountry && selectedState && fetchCity();
    } else {
      setCityList([{ label: "Select", id: "No Selection" }]);
      setCity("No Selection");
    }
  }, [selectedCountry, selectedState]);

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  return (
    <FormControl fullWidth sx={{ mb: 1, flex: 1 }}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {field?.title}
      </Typography>
      {stateLoading || countryLoading || loading ? (
        <Select
          label='City'
          variant='filled'
          sx={{ minWidth: "130px" }}
          defaultValue='Loading...'
          className='dynamicdropdown'>
          <MenuItem value='Loading...' disabled>
            Loading...
          </MenuItem>
        </Select>
      ) : (
        <>
          {/* {console.log("shadowCL1City", city)} */}
          <Select
            label='City'
            variant='filled'
            sx={{ minWidth: "130px" }}
            className='dynamicdropdown'
            defaultValue={city}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  minWidth: 200,
                },
              },
            }}
            {...register(`${field?.name}`, {
              onChange: (event) => {
                handleCityChange(event);
              },
            })}>
            {loading || countryLoading || stateLoading ? (
              <MenuItem value='' disabled>
                Loading...
              </MenuItem>
            ) : cityList?.length === 0 ? (
              <MenuItem value='' disabled>
                No Data Found
              </MenuItem>
            ) : (
              cityList?.map((item) => (
                <MenuItem key={`${item?.id}`} value={item?.id}>
                  <Box display='flex' sx={{ maxHeight: "20px", overflow: "hidden" }}>
                    {item.label}
                  </Box>
                </MenuItem>
              ))
            )}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default CityList;
