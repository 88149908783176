import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Box, Typography } from "@mui/material";
import { formRelativeURL } from "@platformx/utilities";
import { Suspense, useState } from "react";
import BrightcovePlayer from "../../components/BrightcovePlayer/BrightcovePlayer";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";

const PlayerHeroVideo = ({ content, videoLoader, secondaryArgs, analytics }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const playVodEnable = () => {
    setPlayVideo(true);
  };
  return (
    <Box sx={{ position: "relative", width: "100%", height: 500 }}>
      {videoLoader ? (
        <SkeletonLoader />
      ) : (
        <>
          <Box
            sx={{
              position: "absolute",
              bottom: { xs: "15px", sm: "25px" },
              margin: { xs: "0 4%", em: "0" },
              width: { sm: "100%" },
              zIndex: "2",
            }}>
            <Typography sx={{ display: !playVideo ? "block" : "none", margin: { sm: "16px" } }}>
              <PlayArrowRoundedIcon
                onClick={playVodEnable}
                data-testid='play-vod-enable'
                style={{
                  background: "white",
                  borderRadius: "100px",
                  color: "black",
                  padding: "16px",
                  cursor: "pointer",
                }}
                sx={{
                  fontSize: { xs: "57px", sm: "77px" },
                }}
              />
            </Typography>
          </Box>
          <Box
            className={!playVideo ? "gradient" : ""}
            sx={{
              height: {
                md: "500px",
                sm: "500px",
                xs: "321px",
                lg: "500px",
              },
            }}>
            <img
              src={formRelativeURL(
                secondaryArgs?.gcpUrl,
                secondaryArgs?.bucketName,
                content?.Thumbnail,
              )}
              alt={"Thumbnail_Image"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            {playVideo && typeof window !== "undefined" && (
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                }}>
                <Suspense fallback={"Loading..."}>
                  <BrightcovePlayer
                    analytics={analytics}
                    analyticsEnable={content?.analytics_enable}
                    prelemTitle=''
                    VideoData={{
                      VideoID: content?.VodVideoId,
                      PlayerID: content?.PlayerID,
                      AccountID: content?.VodAccountId,
                      PlaylistID: content?.Poster,
                    }}
                    secondaryArgs={secondaryArgs}
                    tags={content?.tags}
                    author={content?.author}
                  />
                </Suspense>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default PlayerHeroVideo;
