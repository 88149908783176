import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { checkRequired, fetchValidations } from "../../helper";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

export interface CheckBoxProps {
  field: any;
  error: any;
  register: any;
  isRendering?: boolean;
  color?: string;
}

export default function CheckBoxComponent({
  field,
  error,
  register,
  color,
  isRendering,
}: CheckBoxProps) {
  const validations = fetchValidations(field?.validations);
  return (
    <>
      <Box className='checkBoxComponentWrapper'>
        <Typography variant='p4regular' className='headingText' mt={1} mb={1}>
          {checkRequired(field?.validations)}
          {field?.placeholder}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              disableRipple
              color={color}
              disabled={!isRendering}
              {...register(field?.name, {
                ...validations,
              })}
              // checked={field?.default_value?.[0]}
              {...(!isRendering ? { checked: field?.default_value?.[0] } : {})}
              defaultChecked={field?.default_value?.[0]}
            />
          }
          label={<Typography variant='p4regular'>{field?.label}</Typography>}
        />
      </Box>
      <ErrorMessageHandle error={error} />
    </>
  );
}
