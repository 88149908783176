import { Box, Typography } from "@mui/material";
import { checkRequired } from "../helper";

const FormParagraph = ({ item }) => {
  return (
    <Box className='formTextBox elementWrapper' mt={1} mb={2}>
      <Typography variant='p4regular' className='headingText' mt={1} mb={1}>
        <Box component='span' className='form-label-with-info'>
          {item?.title}
          {checkRequired(item?.validations)}
        </Box>
      </Typography>
    </Box>
  );
};

export default FormParagraph;
