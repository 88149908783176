import { useLazyQuery } from "@apollo/client";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { fetchAllMultislotContentList } from "@platformx/authoring-apis";
import { RootState } from "@platformx/authoring-state";
import { ContentTypeCard } from "@platformx/prelems";
import { CircleActionsSuccess, NoResults, searchIcon } from "@platformx/shared/static-assets";
import { AUTH_INFO, BackArrow, Loader, NoContentFound } from "@platformx/utilities";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useGalleryStyle from "./ContentGallery.styles";
import { ContentProps } from "./utils/contentGalleryTypes";

const ContentGallery = ({
  fromPageContentType = "",
  handleSelectedContent,
  onToggleContentGallery,
  contentType,
}) => {
  const { routes = [] } = useSelector((state: RootState) => state?.routes);
  const dropdownList = routes?.filter((route) => route?.isGallery);
  const { t } = useTranslation();
  const [contentList, setContentList] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState<ContentProps>({});
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [items, setItems] = useState<ContentProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [onHover, setOnHover] = useState(null);
  const classes = useGalleryStyle();

  const isError = false;
  const secondaryArgs = {
    gcpUrl: AUTH_INFO.gcpUri,
    bucketName: AUTH_INFO.gcpBucketName,
  };

  const contentReturn = (_, type: string[] = []) => {
    if (type.length > 0) {
      //create a set with values of PrelemContentType in lowercase
      const typeSet = new Set(type.map((item) => item.toLowerCase()));
      //filter the local dropdownlist with values of PrelemContentType
      return dropdownList.filter((item) => typeSet.has(item.id.toLowerCase()));
    } else {
      return dropdownList;
    }
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, timeout);
    };
  }

  const handleSelectedItem = (item, index) => {
    if (selectedItem === item) {
      setSelectedItem({});
      setSelectedIndex(-1);
    } else {
      setSelectedItem(item);
      setSelectedIndex(index);
    }
  };
  const [fetchMultiSlotContentList] = useLazyQuery(fetchAllMultislotContentList);

  const handleDone = () => {
    handleSelectedContent(selectedItem);
  };
  const getAllContentTypes = (fil, sear) => {
    setLoading(true);
    fetchMultiSlotContentList({
      variables: {
        filter: fil,
        searchTerm: sear,
        tags: [],
        pagination: { start: 0, rows: 25 },
      },
    })
      .then((res) => {
        if (res?.data?.authoring_getDynamicContentSearch) {
          setLoading(false);

          setItems(res?.data?.authoring_getDynamicContentSearch);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = useCallback(
    debounce((fil, sear) => getAllContentTypes(fil, sear)),
    [],
  );

  useEffect(() => {
    setContentList(contentReturn(fromPageContentType, contentType));
  }, [contentType, fromPageContentType]);

  useEffect(() => {
    handleSearchChange(filter, search);
  }, [filter, search]);

  const getContentType = (item, index) => {
    return (
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        onMouseEnter={() => setOnHover(index)}
        onMouseLeave={() => setOnHover(null)}
        sx={{
          cursor: "pointer",
          position: "relative",
          padding: "10px",
          height: { xs: "auto", md: "255px" },
          ":hover": {
            ".hoverOverlay": {
              // backgroundColor: "rgba(0,0,0,0.5)",
              "&.selected": {
                backgroundColor: "rgba(0,0,0,0.5)",
              },
            },
          },
        }}
        onClick={() => handleSelectedItem(item, index)}
        key={index}
        p={2}>
        <ContentTypeCard
          content={item}
          secondaryArgs={secondaryArgs}
          isLoading={false}
          onHover={onHover}
          index={index}
          selectedIndex={selectedIndex}
          contentTypeList={routes}
        />
        <Box
          className={`hoverOverlay ${index === selectedIndex ? "selected" : ""}`}
          sx={{
            ...(selectedIndex >= 0 && {
              backgroundColor: index === selectedIndex ? "rgba(0,0,0,0.5)" : "none", //"rgba(207,207,207,0.52)",
            }),
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundImage: "linear-gradient(rgba(20, 20, 43, 0), rgba(0, 0, 0, .6))",
          }}
          // p={1}
        >
          <Box>{index === selectedIndex && <img src={CircleActionsSuccess} alt='img' />}</Box>
        </Box>
      </Grid>
    );
  };

  const onSearchChange = (e) => {
    const { value } = e.currentTarget;
    setSearch(value);
  };

  const resetSearch = () => {
    setSearch("");
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilter(value);
  };

  useEffect(() => {
    setFilter(contentType?.[0] || contentList?.[0]?.id);
  }, [contentList]);

  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "#fff",
        width: "100%",
        height: "100%",
        zIndex: "10",
      }}>
      <Grid
        container
        sx={{
          padding: "15px 15px",
          borderBottom: "1px solid #d9dbe9",
          // height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Grid
          item
          xs={12}
          sm={4}
          em={3}
          lg={3}
          sx={{
            margin: "auto 0",
            display: "flex",
            alignItems: "center",
          }}>
          <BackArrow handleReturn={() => onToggleContentGallery(-1)} />
          <Typography variant='h4bold'>{t("prelem_choose_content")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          em={5}
          lg={6}
          sx={{
            padding: {
              xs: "10px 0",
              sm: "0",
              display: "flex",
              justifyContent: "center",
            },
          }}>
          <Grid item xs={12} sm={6} md={4} lg={4} sx={{ height: "50px" }}>
            <FormControl
              fullWidth
              className={classes.menuitemListIcons}
              sx={{
                height: "inherit",
                "& .Platform-x-OutlinedInput-input": {
                  display: "flex",
                  alignItems: "center",
                },
              }}>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={filter}
                style={{
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  height: "inherit",
                  border: "solid 1px #D9DBE9",
                }}
                onChange={handleFilterChange}
                IconComponent={ExpandMoreIcon}>
                {contentList.map((type, key) => {
                  return (
                    <MenuItem value={type?.id} key={key} className={classes.menuitemListIcons}>
                      <img src={type?.icon} alt='' className='contentIcon' />
                      <Typography variant='h6regular' sx={{ marginLeft: "10px" }}>
                        {type?.title}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} sx={{ height: "50px" }}>
            <FormControl fullWidth>
              <TextField
                className='contentTypeCard'
                sx={{
                  ".Platform-x-InputBase-root": {
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    border: "solid 1px #D9DBE9 !important",
                    borderLeft: 0,
                    height: "50px",
                  },
                }}
                variant='filled'
                placeholder={`${t("search")} ${filter}`}
                value={search}
                onChange={onSearchChange}
                inputProps={{ style: { textAlign: "left", borderRadius: 0 } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' style={{ marginLeft: "15px" }}>
                      {/* <SearchIcon /> */}
                      <img
                        src={searchIcon}
                        alt='search'
                        style={{ height: "20.25px", width: "20.25px" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {search !== "" && (
                        <CloseRoundedIcon
                          onClick={resetSearch}
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "18px",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          em={4}
          lg={3}
          container
          spacing={0}
          direction='column'
          alignItems='end'
          justifyContent='center'
          sx={{
            display: "flex",
            marginTop: { xs: "0px", sm: "10px", em: "0" },
          }}>
          <Box justifyContent='end'>
            <Button
              variant='secondaryButton'
              className='sm'
              onClick={() => onToggleContentGallery(-1)}>
              {t("cancel")}
            </Button>

            <Button
              variant='primaryButton'
              className='sm'
              sx={{
                padding: selectedIndex === -1 ? "14px" : "11px",
                marginLeft: "12px",
              }}
              disabled={selectedIndex === -1}
              onClick={handleDone}>
              {t("save")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {isError ? (
        <Box
          sx={{
            marginTop: "200px",
            marginBottom: "100px",
            textAlign: "center",
          }}>
          <img src={NoResults} alt='' />
          <Typography variant='h3semibold'>Failed to fetch results</Typography>
        </Box>
      ) : (
        <Box sx={{ height: "calc(100vh - 90px)", overflowY: "scroll" }} id='scrollableDiv'>
          {loading ? (
            <Box
              sx={{
                marginTop: "100px",
                marginBottom: "100px",
                textAlign: "center",
              }}>
              <Loader />
              {/* style={{
                  width: "80px",
                  height: "80px",
                  color: theme?.palette?.customColor?.PRIMARY, //primary
                }}
              />*/}
            </Box>
          ) : items?.length === 0 ? (
            // <Box
            //   sx={{
            //     marginTop: '100px',
            //     marginBottom: '100px',
            //     textAlign: 'center',
            //   }}
            // >
            //   <Typography variant='overline' display='block' gutterBottom>
            //     {t('no_results')}
            //   </Typography>
            // </Box>
            <Box
              sx={{
                width: "100%",
                height: "75%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <NoContentFound />
            </Box>
          ) : (
            <Grid container sx={{ backgroundColor: "#fff" }}>
              {items?.map((item, index) => {
                if (
                  item &&
                  [
                    "ImageGallery",
                    "VideoGallery",
                    "Accolades",
                    "ServiceCard",
                    "Gallery",
                    "Article",
                    "Vod",
                    "VOD",
                    "Testimonial",
                    "FAQ",
                    "Awards",
                    "Poll",
                    "Quiz",
                    "Event",
                  ].includes(item.ContentType || "")
                ) {
                  return getContentType(item, index);
                }
              })}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

ContentGallery.defaultProps = {
  contentType: ["Select"],
};

export default ContentGallery;
