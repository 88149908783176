import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { findMaxCharLength } from "./helper";

const MaxLengthValidationMessage = ({ validations, currentLength }: any) => {
  const { t } = useTranslation();
  const maxLength = findMaxCharLength(validations);
  const remainingLength = maxLength - currentLength;
  return (
    <Box>
      <Typography variant='p4regular' sx={{ marginTop: "5px" }}>
        {remainingLength === maxLength
          ? `${remainingLength} ${t("characters")} ${t("max")}`
          : `${remainingLength} ${t("characters")} ${t("left")}`}
      </Typography>
    </Box>
  );
};
export default MaxLengthValidationMessage;
