import { Box, FormControl, MenuItem, Select } from "@mui/material";

export const Dropdown = ({ options, error, register, field, isRendering }: any) => {
  const getImgSrc = (val) => {
    if (typeof val === "string") {
      const lowerId = val.toLowerCase();
      return `https://flagcdn.com/w40/${lowerId}.png`;
    } else {
      return "";
    }
  };
  return (
    <Box>
      <FormControl fullWidth error={error}>
        <Select
          labelId='simple-select-label'
          id='simple-select'
          defaultValue={field?.default_value?.[0] || options?.[0]?.value}
          name={field?.name}
          {...register(field?.name)}
          variant='filled'
          {...(!isRendering ? { value: field?.default_value?.[0] } : {})}
          error={error}
          className={isRendering ? "form-outline selectfield" : "authoring selectfield"}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 300,
                padding: "12px",
                // width: 200,
              },
            },
          }}>
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} disabled={item?.disabled} value={item?.label}>
                {item?.code && (
                  <img
                    loading='lazy'
                    width='20'
                    src={getImgSrc(item?.code)}
                    alt='flag'
                    style={{ marginRight: "8px" }}
                  />
                )}
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
