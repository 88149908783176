/* eslint-disable no-console */
import axios from "axios";
import { LOGOUT_URL } from "../constants/AuthConstant";
import { MAX_FILE_TITLE_LEN } from "../constants/CommonConstants";
import { getRestApiCall, getSelectedSite, getSubDomain, postRestApiCall } from "./helperFns";

export const hasOwnProp = (obj: object, key: string): boolean => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

export const getStyleString = (styles: any) =>
  Object.entries(styles)
    .map(([prop, value]) => `${prop}: ${value}`)
    .join("; ");

/**
 * courseId based get course fill details
 * post call
 */
export const getCourseDetailsApiCall = (courseId: string, secondaryArgs: any) => {
  const { prelemBaseEndpoint: { deliveryEndPoint = "", language = "en" } = {}, sitename } =
    secondaryArgs;
  return getRestApiCall(
    `${deliveryEndPoint}api/v1/web/en/delivery/course-model?path=${courseId}`,
    language,
    sitename,
  );
  // return getRestApiCall(
  //   `https://marvericks.delivery.hcl-x.com/platform-x/api/v1/web/en/delivery/course-model?path=108058619401306`
  // );
};

/**
 * courseId based get course fill details
 * post call
 */
export const getLearningListApiCall = (ele: any) => {
  const { secondaryArgs = {}, userId = "" } = ele;
  const {
    prelemBaseEndpoint: {
      // deliveryEndPoint = "https://dev.users.hcl-x.com/platform-x/user-service/",
      usersEndPoint = "",
      language = "en",
    } = {},
    sitename,
  } = secondaryArgs;

  const data = JSON.stringify({
    query: `query{getuserCourses(user_id:${JSON.stringify(userId)})}`,
    variables: {},
  });

  return postRestApiCall(`${usersEndPoint}user-service/`, data, language, sitename);
};

export const fetchOfferName = (VisitorID, publishRpiKey, secondaryArgs) => {
  // eslint-disable-next-line no-console
  console.log("SecondaryArgs22", secondaryArgs);

  // Define the Redpoint API endpoint and the headers
  //Need to take both const from seconry Arg
  const rpiClinetId = secondaryArgs?.rpiClinetId; //"aa07a6db-0e70-4aca-93cc-45056347ed04"
  const rpiApiUrl = secondaryArgs?.rpiApiUrl; //"https://rpi-server-1.hcl-x.com"
  const apiUrl = `${rpiApiUrl}/interactionrealtimeapi/api/SmartAssets/${rpiClinetId}/Results`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };
  const requestData = {
    Identity: {
      VisitorID: VisitorID,
    },
    AssetLookups: [
      {
        PublishID: publishRpiKey,
      },
    ],
  };

  // Send the POST request and return the Axios promise
  return axios
    .post(apiUrl, requestData, { headers })
    .then((response) => {
      localStorage.setItem(
        "OfferName",
        JSON.stringify({ data: response?.data?.Results[0]?.ResultContent }) || "",
      );
      return response.data; // Return the API response
    })
    .catch((error) => {
      console.error("Error fetching offer name:", error);
      return Promise.reject(error); // Return a rejected Promise with the error
    });
};

/**
 * Fetches eCommerce products based on provided query parameters.
 * This function is used to handle common API requests for fetching eCommerce products.
 *
 * @param {string} deliveryEndPointGq - The endpoint URL for making the API call.
 * @param {string} sitename - The name of the site to include in the request headers.
 * @param {object} queryParams - The query parameters to be sent in the API request.
 * @returns {Promise<Array>} - A promise that resolves to an array of fetched eCommerce products.
 */
export const fetchEcomProductsHandler = async (
  deliveryEndPointGq: string,
  sitename: string,
  queryParams: any,
): Promise<any[]> => {
  try {
    const response = await axios.post(
      deliveryEndPointGq,
      {
        query: `query fetchEcomProducts($pagePath: String!) {
          publish_fetchEcomProducts(queryParam: $pagePath)
        }`,
        variables: {
          pagePath: queryParams,
        },
      },
      {
        headers: {
          site_host: sitename,
          "Content-Type": "application/json",
        },
      },
    );

    const { data: { data: { publish_fetchEcomProducts = [] } = {} } = {} } = response;
    return publish_fetchEcomProducts;
  } catch (error) {
    console.error("Error occurred in fetchEcomProductsHandler:", error);
    throw error; // Propagate the error to the caller
  }
};

/**
 * Fetches the dynamic content list using the provided parameters.
 *
 * @param {object} ele - An object containing parameters for the API call.
 * @returns {Promise<Array>} - A promise that resolves to an array of dynamic content.
 */
export const getDynamicContentListApiCall = async (ele: any): Promise<any[]> => {
  try {
    // Destructure and initialize parameters from the input element
    const { secondaryArgs = {}, start, numberOfRows, params, publishRpiKey } = ele;
    const { prelemBaseEndpoint: { deliveryEndPointGq = "" } = {}, sitename } = secondaryArgs;

    // Initialize cdpfilter as an empty array
    let cdpfilter: string[] = [];
    // Retrieve VisitorID from local storage
    const localStorageData = localStorage.getItem("VisitorID");
    const VisitorID = localStorageData || null;

    // If publishRpiKey and VisitorID exist, fetch the offer name and update cdpfilter
    if (publishRpiKey && VisitorID) {
      try {
        const response = await fetchOfferName(VisitorID, publishRpiKey, secondaryArgs);
        const offerName = response?.Results[0]?.ResultContent;
        if (offerName && Array.isArray(offerName)) {
          cdpfilter = offerName;
        }
      } catch (error) {
        console.error("Error fetching offer name:", error);
      }
    }

    // Destructure and initialize additional parameters from params
    const { tags = [], filter = "ALL", searchTerm = "", cdpFilterParent = null } = params;
    if (cdpFilterParent) cdpfilter = [...cdpfilter, ...cdpFilterParent];

    // Construct the query parameter object manually for the API call
    const queryParams = `{pagination:{start:${start},rows:${numberOfRows}},searchTerm:"${searchTerm}",tags:${JSON.stringify(tags)},cdpFilter:${JSON.stringify(cdpfilter)},filter:"${filter}",isSuggestive:false}`;

    // Make the API call to fetch eCommerce products
    return await fetchEcomProductsHandler(deliveryEndPointGq, sitename, queryParams);
  } catch (error) {
    console.error("Error occurred in getDynamicContentListApiCall:", error);
    throw error; // Propagate the error to the caller
  }
};

/**
 * Fetches the My Story content list using the provided parameters.
 *
 * @param {object} ele - An object containing parameters for the API call.
 * @returns {Promise<Array>} - A promise that resolves to an array of My Story content.
 */
export const getMyStoryContentListApiCall = async (ele: any): Promise<any[]> => {
  try {
    // Destructure the input parameters
    const { secondaryArgs = {}, start, numberOfRows } = ele;
    const { prelemBaseEndpoint: { deliveryEndPointGq = "" } = {}, sitename } = secondaryArgs;

    // Fetch user-selected tags and content types from local storage
    const localStorageData = localStorage.getItem("UserSelectedTags");
    const cdpfilter = localStorageData ? JSON.parse(localStorageData) : [];

    const localStorageTagData = localStorage.getItem("SelectedContentType");
    const tags = localStorageTagData ? JSON.parse(localStorageTagData) : [];

    // Ensure that the fetched data is in the expected format (array)
    const sanitizedCdpFilter = Array.isArray(cdpfilter) ? cdpfilter : [];
    const sanitizedTags = Array.isArray(tags) ? tags : [];

    // Set default values for filter and search term
    const filter = "ALL";
    const searchTerm = "";

    // Construct the query parameters for the API call
    const queryParams = `{pagination:{start:${start},rows:${numberOfRows}},searchTerm:"${searchTerm}",tags:${JSON.stringify(sanitizedTags)},cdpFilter:${JSON.stringify(sanitizedCdpFilter)},filter:"${filter}",isSuggestive:false}`;

    // Make the API call using the common handler function
    return await fetchEcomProductsHandler(deliveryEndPointGq, sitename, queryParams);
  } catch (error) {
    // Log any errors that occur and rethrow them for further handling
    console.error("Error occurred in getMyStoryContentListApiCall:", error);
    throw error;
  }
};

/**
 * user details update api call
 */
export const updateUserFormDetailsService = (ele: any) => {
  const { secondaryArgs = {}, userDetails = {} } = ele;
  const { prelemBaseEndpoint: { usersEndPoint = "", language = "en", site_host = "" } = {} } =
    secondaryArgs;
  const data = {
    input: {
      email: userDetails.emailAddress,
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      phone: userDetails.phoneNumber,
      company_name: userDetails.companyName,
      country: userDetails.country,
      message: userDetails.message,
    },
  };
  return postRestApiCall(`${usersEndPoint}contact_us/save`, data, language, site_host);
};

export const getFirstTwoletters = (title: string) => {
  if (!title) return "";
  const words = title.trim().split(" ");
  if (words.length === 1) return words[0].substring(0, 2);
  return words[0].charAt(0) + words[words.length - 1].charAt(0);
};

export const openPageInNewTab = (url: string) => {
  if (window && url) {
    const infoUrl = window?.open(url, "_blank");
    if (infoUrl) {
      infoUrl.focus();
    }
  }
};

export const formatTitle = (title: string) => {
  return title.replace(/[^a-zA-Z0-9-_ ]|[_-](?=[_-])/g, "");
};

export const frameTitle = (title: string) => {
  if (!title) return "";
  let formattedTitle = title.trim();
  formattedTitle = formattedTitle.replace(/^[-_\s]+|[-_\s]+$/g, "");
  return formattedTitle;
};

export const formatPageUrl = (url) => {
  let tmp = url?.toLowerCase();
  tmp = tmp.replace(/[^a-zA-Z0-9_-\s]/g, ""); // Allow only numbers, alphabets, underscore, hyphen, spaces
  tmp = tmp.replace(/\s+/g, "-"); // Replace spaces with hyphens
  tmp = tmp.replace(/[_-]+/g, "-"); // Replace multiple underscores or hyphens with a single hyphen
  tmp = tmp.replace(/^[-_]+|[-_]+$/g, ""); // Remove hyphens or underscores at the beginning or end
  return tmp;
};

export const formatAddPrelem = (item) => {
  return {
    PrelemId: item.PrelemId, // Unique Name
    PrelemName: item.PrelemName,
    SeoEnabled: item.SeoEnabled,
    AnalyticsEnabled: item.AnalyticsEnabled,
    InstanceId: "pr_cont1",
    DocumentPath: item.DocumentPath,
    DocumentCreationPath: item.DocumentCreationPath,
    DocumentType: item.DocumentType,
    IsHidden: false,
    IsModified: false,
    StructuredData: "",
    //RpiKey: item?.RpiKey,
  };
};

export const handleLogout = () => {
  const keycloakURL = LOGOUT_URL;
  localStorage.removeItem("userSession");
  localStorage.removeItem("selectedSite");
  localStorage.removeItem("imageUuid");
  localStorage.removeItem("videoUuid");
  window.location.replace(keycloakURL);
};

export const getRequestFromDelivery = async (url) => {
  try {
    const res = await axios.get(process.env.NX_PUBLIC_DELIVERY_URI + url, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        sitename: getSelectedSite(),
        site_host: getSubDomain(),
      },
      withCredentials: true,
    });
    return res?.data?.result ? res?.data?.result : res?.data;
  } catch (err: any) {
    if (err?.response?.data?.code === 401 && !url.includes("verify")) {
      handleLogout();
    }
    return err;
  }
};
export const navigateToContent = (navigate, navigateUrl, contentType, pageName) => {
  navigate(navigateUrl, {
    state: { contentType, pageName },
  });
};

// function to limit the uploaded file title length
export const trimFileTitle = (str) => {
  return str.slice(0, MAX_FILE_TITLE_LEN);
};

interface MetaFields {
  AltText: string;
  Name: string;
  Title: string;
  Description: string;
  Attribution: boolean;
}

interface OriginalImage {
  original_image_relative_path: string;
  visibility: string;
  ext: string;
  bitStreamId: string;
  auto: boolean;
  MetaFields: MetaFields;
}

interface PublishedImage {
  aspect_ratio: string;
  folder_path: string;
}

export const generateImageObjects = (imageUrl: string) => {
  // Remove leading slash if present
  const cleanUrl = imageUrl.startsWith("/") ? imageUrl.slice(1) : imageUrl;

  // Find the last dot to separate the extension
  const lastDotIndex = cleanUrl.lastIndexOf(".");

  // Extract filename without the extension
  const fileName = cleanUrl.substring(0, lastDotIndex);

  // Extract the extension (without the dot)
  const ext = cleanUrl.substring(lastDotIndex + 1);
  // Create the original_image object
  const original_image: OriginalImage = {
    original_image_relative_path: fileName, // Just use the filename without extension
    visibility: "public",
    ext: ext,
    bitStreamId: "",
    auto: true,
    MetaFields: {
      AltText: fileName,
      Name: fileName,
      Title: fileName,
      Description: `This is for ${fileName}`,
      Attribution: false,
    },
  };

  // Create the published_images array for different aspect ratios
  const aspectRatios = ["landscape", "square", "portrait", "hero", "card1", "card2"];
  const published_images: PublishedImage[] = aspectRatios.map((ratio) => ({
    aspect_ratio: ratio,
    folder_path: `${fileName}-${ratio}`, // No bucket path, just filename and ratio
  }));

  return { original_image, published_images };
};

export const getDisplayContentType = (ct) => {
  switch (ct?.toLowerCase()) {
    case "article":
      return "Article";
    case "quiz":
      return "Quiz";
    case "poll":
      return "Poll";
    case "event":
      return "Event";
    case "formbuilder":
      return "Form";
    case "imagegallery":
      return "Image Gallery";
    case "videogallery":
      return "Video Gallery";
    case "gallery":
      return "Gallery";
    case "vod":
      return "VOD";
    case "profile":
      return "Profile";
    case "faqs":
      return "FAQ";
    case "wheel":
      return "Wheel";
    case "servicecard":
      return "Service Card";
    case "appevent":
      return "App Event";
    case "playeranalysis":
      return "Player Analysis";
    case "speaker":
      return "Speaker";
    default:
      return ct;
  }
};
