import { gql } from "@apollo/client";

export const GET_CONTENT_DATA = gql`
  query publish_contentDetail($pagePath: String!, $contentType: publish_ContentTypes!) {
    publish_contentDetail(pagePath: $pagePath, contentType: $contentType)
  }
`;

export const SAVE_FORM = gql`
  mutation publish_saveFormData($input: publish_formInputRequest) {
    publish_saveFormData(input: $input) {
      message
    }
  }
`;
