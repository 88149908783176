import { Box, Button, Typography } from "@mui/material";
import { SaveAnimationGif } from "@platformx/shared/static-assets";
import { memo, useEffect, useState } from "react";
import { SkeletonLoader } from "../../components/SkeletonLoader/SkeletonLoader";
import ToastService from "../../components/ToastContainer/ToastService";
import graphQlList from "../../graphql/PlayerAnalysis/fetch";
import Tags from "./Tags";

const BLOCKED_TAG_CATEGORY = [
  "Country",
  "Collection",
  "Content Type",
  "Channel",
  "Show",
  "testtag",
  "TestTag",
  "PlatformX QA",
];

const TagListing = ({
  selectedTags,
  onFilterClick,
  applyFilter,
  onSelectTags,
  secondaryArgs,
  applyingFilterLoading,
}) => {
  const [tags, setTags] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const getTags = async () => {
    try {
      const {
        prelemBaseEndpoint: { deliveryEndPointGq = "" } = {},
        sitename = "",
        language = "en",
      } = secondaryArgs;
      const { data, errors } = await graphQlList.getTags(
        {
          start: 0,
          rows: 1000,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (data?.publish_getTagsListV2) {
        return data.publish_getTagsListV2;
      } else {
        console.error("ContentAPIFailed", errors);
        ToastService.failToast("Error fetching Tags");
      }
    } catch (err) {
      ToastService.failToast("Error fetching Tags");
    } finally {
      setLoading(false);
    }
  };

  const prepareFilteredTags = async () => {
    const tagsFromAPI = await getTags();
    const filteredTags =
      tagsFromAPI?.filter((tag) => !BLOCKED_TAG_CATEGORY.includes(tag.category)) || [];
    setTags(filteredTags);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    prepareFilteredTags();
  }, []);

  return (
    <Box sx={{ margin: 0, paddingBottom: 2 }}>
      <Box py={2} display='flex' justifyContent='flex-end'>
        <Button variant='primaryButton2' className='sm' onClick={onFilterClick}>
          Cancel
        </Button>
        {/* <Button
          variant='primaryButton1'
          disabled={selectedTags?.length === 0}
          className='sm'
          onClick={applyFilter}>
          Apply Filter
        </Button> */}
        <Button
          variant='primaryButton1'
          disableElevation
          disabled={selectedTags?.length === 0 || tags?.length === 0}
          className='sm'
          onClick={applyFilter}>
          {applyingFilterLoading && <img src={SaveAnimationGif} alt='' />}
          {applyingFilterLoading ? "Applying" : "Apply Filter"}
        </Button>
      </Box>
      <Box sx={{ minHeight: "90dvh" }}>
        {loading ? (
          <SkeletonLoader />
        ) : (
          tags?.map((tagCategoryItem) => (
            <Box key={tagCategoryItem.category} mb={2}>
              <Typography variant='h4semibold'>{tagCategoryItem.category}</Typography>
              <Tags
                tags={tagCategoryItem?.tags}
                selectedTags={selectedTags}
                onSelectTags={onSelectTags}
              />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default memo(TagListing);
