import { Box } from "@mui/material";
import { FilterIcon } from "@platformx/shared/static-assets";

const PlayerHighlightInfo = ({ onFilterClick, isFilterOpen }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "flex-start",
        padding: "16px 0px",
      }}>
      {/* <Box>
        <Typography variant='h4semibold' sx={{ marginTop: 0 }}>
          {content?.description}
        </Typography>
      </Box> */}

      {!isFilterOpen ? (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
          <Box className='filterIcon' onClick={onFilterClick}>
            <img
              src={FilterIcon}
              alt='filtericon'
              width='17'
              height='24'
              style={{ marginLeft: "10px", cursor: "pointer" }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default PlayerHighlightInfo;
