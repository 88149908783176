const quizObject = {
  contentType: "Quiz",
  input: {
    CommonFields: {
      page: "",
      title: "",
      description: "",
      category: "Quiz",
      site_name: "",
      page_state: "DRAFT",
      is_edit: false,
      seo_enable: true,
      analytics_enable: true,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      others: "",
      structure_data: "",
      creationDate: "",
      modificationDate: "",
      tags: ["Quiz"],
      createdBy: "",
      parent_page_url: "/",
      page_lastmodifiedby: "",
      settings: {
        socialog_url: "",
        socialog_type: "quiz",
        socialog_sitename: "",
        seo_title: "",
        socialog_title: "",
        socialog_twitter_title: "",
        socialog_description: "",
        socialog_twitter_description: "",
        socialog_twitter_url: "",
        keywords: ["Quiz"],
        seo_keywords: ["Quiz"],
        seo_description: "",
        socialog_image: "",
        socialog_twitter_image: "",
        is_schedule_publish: false,
        schedule_publish_datetime: "",
        is_schedule_unpublish: false,
        schedule_unpublish_datetime: "",
      },
      IsConfirm: false,
      is_featured: false,
    },
    ObjectFields: {
      questions: [],
      background_content: {},
      display_scores: "Count",
      result_range_1: "A winner never stops trying",
      result_range_2: "You cannot be a winner without maturity and consistency",
      result_range_3: "In everything we do there's a winner and a loser",
      result_range_4: "That's how I live my life, with belief. I am a winner",
      published_images: [],
      original_image: {},
    },
  },
};
export default quizObject;
