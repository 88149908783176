import { Box, Typography } from "@mui/material";
import Toggle from "../Components/Toggle/Toggle";
import { checkRequired } from "../helper";
import ControllInfo from "./Common/ControllInfo/ControllInfo";

const FormToggle = ({ item, register, errors, isRendering }) => {
  return (
    <Box className='formTextBox toggleElementWrapper' mt={1}>
      <Typography
        variant='p4regular'
        className='form-label'
        mt={1}
        mb={1}
        display='flex'
        alignItems='flex-start'>
        <Box component='span' className='form-label-with-info'>
          {item?.placeholder}
          {checkRequired(item?.validations)}
        </Box>
        {item?.info?.showMessage && <ControllInfo item={item} />}
      </Typography>
      <Toggle field={item} errors={errors} register={register} isRendering={isRendering} />
    </Box>
  );
};

export default FormToggle;
