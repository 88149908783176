import { Box, Button, Container, Typography } from "@mui/material";
import { FormItem } from "@platformx/form-builder-components";
import { GreenTick } from "@platformx/shared/static-assets";
import { Loader } from "@platformx/utilities";
import { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Confirmation from "../../components/Confirmation/Confirmation";
import ContentReplace from "../../components/ContentReplace/ContentReplace";
import ToastService from "../../components/ToastContainer/ToastService";
import prelemTypes from "../../globalStyle";
import { graphQlFormBuilder } from "../../graphql/FormBuilder/fetch";
import { getPrelemType } from "../../utils/Themefunctions";
import { useCustomStyle } from "./FormCreator.style";
import FormSkelton from "./FormSkelton";

const FormCreator = ({ content, authoringHelper, secondaryArgs }) => {
  // eslint-disable-next-line no-console
  console.log("shadowForm", content);
  const prelemType = content.selected_prelem_bg_color;
  const classes = useCustomStyle(getPrelemType(prelemType));
  const globalClasses = prelemTypes();
  const [formData, setFormData] = useState<any>([]);
  const [, setSelectedElement] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const onContentReplace = () => {
    secondaryArgs?.multiSlot?.onToggleContentGallery("FORM", true);
  };
  const form = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  const { register, formState, clearErrors, handleSubmit } = form;
  const { errors } = formState;

  const getElementObject = (elementName) => {
    // elementName === "dropdown_1740743947783_2_Country" &&
    //   console.log("shadowF1", elementName, formData);
    let element: any = null;
    for (const item of formData) {
      // elementName === "dropdown_1740743947783_2_Country" &&
      //   console.log("shadowF2.0", item.name, elementName, item.name === elementName);
      if (item.name === elementName) {
        element = item;
        // elementName === "dropdown_1740743947783_2_Country" && console.log("shadowF2.1", element);
        if (element) break;
      } else if (item.split) {
        element = item.splitFields.filter((splitItem) => {
          return splitItem.name === elementName;
        })?.[0];
        // elementName === "dropdown_1740743947783_2_Country" && console.log("shadowF2.2", element);
        if (element) break;
      }
    }
    // elementName === "dropdown_1740743947783_2_Country" && console.log("shadowF3", element);
    return element;
  };

  const handleSave = async (savedFormData) => {
    // eslint-disable-next-line no-console
    console.log("shadowFormSaveOriginalData", savedFormData);
    const framedFormData = Object.entries(savedFormData)?.map(([key, value]) => {
      const currentElement = getElementObject(key);
      let currentValue = value;
      //condition for toggle to set false when unchecked
      if (currentElement?.name.includes("toggle") && !value) {
        currentValue = false;
      }

      return {
        title: currentElement?.title,
        name: currentElement?.name,
        value: currentValue,
      };
    });
    setPageLoader(true);
    try {
      const input = {
        contentType: "formbuilder",
        form_name: EditorialItemPath,
        form_data: framedFormData,
        form_version: content?.form_version || "v1",
      };
      const {
        prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {},
        sitename = "",
      } = secondaryArgs;
      const { errors: APIErrors } = await graphQlFormBuilder.saveForm(
        { input },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (APIErrors?.length > 0) {
        ToastService.failToast(APIErrors?.[0]?.message);
      } else {
        setSuccessPopUp(true);
      }
    } catch (error) {
      ToastService.failToast("Failed to save form");
    } finally {
      setPageLoader(false);
    }
  };
  const EditorialItemPath =
    content?.Slots?.[0]?.EditorialItemPath === "NULL"
      ? null
      : content?.Slots?.[0]?.EditorialItemPath?.replace(/(^\/)|(\/$)/g, "");

  const transformFormData = (formDataArr) => {
    return formDataArr?.map((item) => item.element_schema);
  };
  const handleClose = () => {
    setSuccessPopUp(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const {
        prelemBaseEndpoint: { deliveryEndPointGq = "", language = "en" } = {},
        sitename = "",
      } = secondaryArgs;
      const contentType = "formbuilder";
      const pagePath = EditorialItemPath;
      const { data, errors: APIErrors } = await graphQlFormBuilder.fetchOldContentData(
        {
          pagePath,
          contentType,
        },
        language,
        sitename,
        deliveryEndPointGq,
      );
      if (APIErrors) {
        ToastService.failToast(APIErrors.msg || "Error fetching form elements");
      } else {
        const { publish_contentDetail = {} } = data;
        const transformedData = await transformFormData(publish_contentDetail?.form_data);
        setFormData(transformedData);
      }
    } catch (error) {
      ToastService.failToast("Error fetching form elements");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (EditorialItemPath) fetchData();
  }, [EditorialItemPath]);

  return (
    <Box
      ref={authoringHelper?.innerRef}
      className={`${classes.formCreatorWrapper} ${globalClasses.prelemType1} prelem prelemType1 formCreator ${authoringHelper?.isEditPage && "noscroll"}`}>
      <Container
        className={
          authoringHelper?.isEditPage
            ? "grid_full_width prelem-py"
            : "grid_container grid_container_nopadding prelem-py"
        }
        sx={{ height: "100%" }}>
        <Box
          className='formCreatorPrelem'
          sx={{
            "&:hover": {
              ".add-content-overlay": {
                display: authoringHelper?.isEditing ? "flex !important" : "none",
              },
            },
          }}>
          {loading ? <FormSkelton /> : null}
          {!loading && formData?.length === 0 ? (
            <>
              <Typography variant='h2medium' id='title' className='title headingText'>
                {content?.title}
              </Typography>
              <Typography variant='h2medium' id='description' className='title headingText'>
                {content?.description}
              </Typography>
            </>
          ) : null}
          {!loading && formData?.length > 0 ? (
            <Box className='formSection editForm'>
              <form onSubmit={handleSubmit(handleSave)}>
                {formData?.length > 0 &&
                  formData?.map((element, index) => (
                    <Box key={index} className={"form-element selected"}>
                      <Suspense fallback='loading...'>
                        <FormItem
                          item={element}
                          register={register}
                          clearErrors={clearErrors}
                          errors={errors}
                          setSelectedElement={setSelectedElement}
                          selectedElement={element}
                          secondaryArgs={secondaryArgs}
                        />
                      </Suspense>
                    </Box>
                  ))}
                <Box className='submitButton form-element' mt={2}>
                  <Button variant='primaryButton1' className='sm' type='submit'>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          ) : null}
          <ContentReplace onReplaceClick={onContentReplace} />
        </Box>
        {successPopUp && (
          <Confirmation
            open={true}
            img={GreenTick}
            handleClose={handleClose}
            text={"Form Data Saved Successfully"}
            title={"Success"}
          />
        )}
        {pageLoader ? <Loader /> : null}
      </Container>
    </Box>
  );
};

export default FormCreator;
