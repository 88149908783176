import { Box } from "@mui/material";
import CheckBoxComponent from "../Components/CheckBox/CheckBoxComponent";

const FormConsent = ({ item, register, errors, isRendering }) => {
  return (
    <Box className='formTextBox checkBoxElementWrapper' mt={1}>
      <CheckBoxComponent
        field={item}
        error={errors?.[item?.name]}
        register={register}
        // handleChange={handleChange}
        isRendering={isRendering}
      />
    </Box>
  );
};

export default FormConsent;
