export const findMaxCharLength = (validations) => {
  let length = 1000;
  if (validations.length > 0) {
    const findData = validations.find((x) => x.type === "maxLength");
    if (findData) {
      length = findData.value;
    }
  }
  return length;
};
