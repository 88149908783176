import { Box, Divider } from "@mui/material";
import { eventAPIS, useWorkflow } from "@platformx/authoring-apis";
import { RootState, previewContent } from "@platformx/authoring-state";
import { ContentType } from "@platformx/content";
import {
  CommonPlateformXDialog,
  Loader,
  MAPPING,
  ShowToastError,
  ShowToastSuccess,
  capitalizeFirstLetter,
  useUserSession,
  workflowKeys,
} from "@platformx/utilities";
import { addMinutes } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v6";
import Analytics from "../../components/Analytics/Analytics";
import ContentPageScroll from "../../components/ContentPageScroll";
import { CreateHeader } from "../../components/CreateHeader/CreateHeader";
import Seo from "../../components/SEO/SEO";
import SocialShare from "../../components/SocialShare/SocialShare";
import { EventInstance, EventWhole } from "./CreateEvent.types";
import icons, {
  // CANCEL,
  CATEGORY_CONTENT,
  DRAFT,
  EVENT,
  // IMAGE_URL,
  PAGE_EXIST_POP_UP,
  PATH,
  PUBLISHED,
  PUBLISH_POP_UP,
  SAVE_AS_DRAFT_POP_UP,
  SCROLL,
  SEO,
  // SOCIAL_SHARE,
  // SOCIAL_SHARE_IMG_URL,
  seo,
} from "./Utils/Constants";
import {
  DefEvent,
  EventData,
  analyticsTitleSubTitles,
  fieldValidation,
  getEventToSend,
  getModifiedField,
  getPreviewContentData,
  getTempObj,
  getUpdateEvent,
  isInViewport,
  seoTitleSubTitles,
  socialShareTitleSubTitles,
  updateEventSettings,
  updateStructureData,
} from "./Utils/helper";
import EventChooseTags from "./components/EventChooseTags/EventChooseTags";
import EventImageAndThumbnail from "./components/EventImageAndThumbnail/EventImageAndThumbnail";
import EventTimeAndLocation from "./components/EventTimeAndLocation/EventTimeAndLocation";
import EventTitleDescription from "./components/EventTitleDescription/EventTitleDescription";

const CreateEvent = () => {
  const dispatch = useDispatch();
  const { getWorkflowDetails, workflowRequest } = useWorkflow();
  const { t, i18n } = useTranslation();
  const { currentContent } = useSelector((state: RootState) => state.content);
  const [workflow, setWorkflow] = useState({});
  const [getSession] = useUserSession();
  const { userInfo, role } = getSession();
  const username = `${userInfo.first_name} ${userInfo.last_name}`;
  const createdUser = useRef(username);
  const quizPageUrl = new URL(window.location.href);
  const currentQuizData = useRef(
    quizPageUrl.searchParams.get("path") ? (quizPageUrl.searchParams.get("path") as string) : "",
  );
  const [scrollToView, setScrollToView] = useState("");
  const [showExitWarning, setShowExitWarning] = useState(false);
  const navigate = useNavigate();
  const [previewButton, setPreviewButton] = useState(true);
  const [previewTooltip, setPreviewTooltip] = useState(true);
  const [isFeatured, setIsFeatured] = useState(false);
  const [parentToolTip, setParentToolTip] = useState("");
  const [eventInstance, setEventInstance] = useState<EventInstance | unknown>({});
  const [isLoading, setIsLoading] = useState(false);
  const unsavedChanges = useRef<boolean>(false);
  const updateTempObj = useRef<unknown>({});
  const eventPageUrl = new URL(window.location.href);
  const [isDraft, setIsDraft] = useState<boolean>(true);
  const [draftPageURL, setDraftPageURL] = useState<string>("");
  const currentEventData = useRef(
    eventPageUrl.searchParams.get(PATH) ? (eventPageUrl.searchParams.get(PATH) as string) : "",
  );
  const [openPageExistModal, setOpenPageExistModal] = useState<boolean>(false);
  const [pageStatus, setPageStatus] = useState(DRAFT);
  const [onSavedModal, setOnSavedModal] = useState(false);
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [eventState, setEventState] = useState(EventData);
  const [enableWorkflowHistory, setEnableWorkflowHistory] = useState<boolean>(false);
  const publishPopup = useRef(PUBLISH_POP_UP);
  const [isError, setIsError] = useState<any>([]);

  const login_user_id = userInfo?.user_id;
  const updateField = (updatedPartialObj) => {
    updateTempObj.current = updatedPartialObj;
    const newTempData = JSON.parse(JSON.stringify(eventInstance));
    const modifiedEvent = getModifiedField(eventState, newTempData, updatedPartialObj);
    setEventInstance(modifiedEvent);
  };

  const eventWholeRef = useRef<EventWhole>(DefEvent);

  const updateCurrentInstance = () => {
    const pageURL = eventWholeRef.current?.title.replace(/[^A-Z0-9]+/gi, "-").toLowerCase();
    const updatedObj = {
      page: pageURL,
      title: eventWholeRef.current?.title,
      short_title: eventWholeRef.current?.short_title,
      description: eventWholeRef.current?.description,
      short_description: eventWholeRef.current?.short_description,
      tags: eventWholeRef.current?.tags,
      current_page_url: `/${pageURL}`,
      settings: {
        ...updateEventSettings(eventWholeRef, eventState, i18n),
      },
    };
    updateField(updatedObj);
  };

  const publishEvent = async (pageURL) => {
    const eventToSend = {
      page: pageURL,
    };
    try {
      // Don't remove the data, API call to publish the event
      await eventAPIS.publishContentType({
        contentType: EVENT,
        input: eventToSend,
      });
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;

      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error?.graphQLErrors[0].message, statusCode);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const [workflowStatus, setWorkflowStatus] = useState(true);
  const [showWorkflowSubmit, setShowWorkflowSubmit] = useState(false);
  const workflowSubmitRequest = async (workflowObj, status) => {
    const { success, workflow_status } = await workflowRequest(workflowObj, status);
    if (success) {
      workflow_status === workflowKeys.publish.toLowerCase() && status === workflowKeys.approve
        ? setShowPublishConfirm(true)
        : setShowWorkflowSubmit(true);
    }
  };

  const createEvent = async (
    pageState,
    IsDuplicate = false,
    isWorkflow = true,
    // props = {},
    // event_step = "",
  ) => {
    setIsLoading(true);
    const newTempData = JSON.parse(JSON.stringify(eventInstance));

    const eventToSend = getEventToSend(
      eventState,
      newTempData,
      updateTempObj,
      pageState,
      IsDuplicate,
      isFeatured,
      createdUser.current,
    );
    try {
      const data: any = await eventAPIS.createContentType({
        contenttype: EVENT,
        input: eventToSend,
      });
      unsavedChanges.current = false;
      if (data?.authoring_createContent?.message === "Successfully created!!!") {
        const pageUrl = data?.authoring_createContent?.path.substring(
          data?.authoring_createContent?.path.lastIndexOf("/") + 1,
        );
        eventWholeRef.current.page = pageUrl;
        setDraftPageURL(pageUrl);
        if (pageState !== PUBLISHED) {
          setIsLoading(false);
          if (data?.authoring_createContent?.isExist === true) {
            setOpenPageExistModal(true);
            setPageStatus(pageState);
            setWorkflowStatus(isWorkflow);
          } else {
            if (!isWorkflow) {
              ShowToastSuccess(`${t("event")} ${t("saved_toast")}`);
            }
            setIsDraft(false);
            const { createdBy, title, description } = eventToSend.CommonFields;
            const workflowObj = {
              createdBy,
              title,
              description,
              path: data?.authoring_createContent?.path,
              workflow_status: workflowKeys.draft,
              tag_name: capitalizeFirstLetter(ContentType.Event),
              last_modifiedBy: createdBy,
            };
            if (isWorkflow) {
              workflowSubmitRequest(workflowObj, workflowKeys.approve);
            }
            setWorkflow({ ...workflow, ...workflowObj });
          }
        } else {
          if (data?.authoring_createContent?.isExist === true) {
            setOpenPageExistModal(true);
            setPageStatus(pageState);
          } else {
            await publishEvent(pageUrl);
            setShowPublishConfirm(true);
          }
        }
      }
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;

      if (error?.graphQLErrors?.length > 0) {
        ShowToastError(error.graphQLErrors[0].message, statusCode);
      } else {
        ShowToastError(t("api_error_toast"), statusCode);
      }
      setIsLoading(false);
    }
  };

  const updateEvent = async (pageState, isWorkflow = true, props = {}, event_step = "") => {
    setIsLoading(true);
    const newTempData = JSON.parse(JSON.stringify(eventInstance));

    const updateEventToSend = getUpdateEvent(
      eventState,
      newTempData,
      updateTempObj,
      pageState,
      username,
      currentEventData,
      draftPageURL,
      isFeatured,
    );
    try {
      // Don't remove the data, API call to update the event
      await eventAPIS.updateContentType({
        contenttype: EVENT,
        input: updateEventToSend,
      });

      unsavedChanges.current = false;
      if (pageState && pageState.toLowerCase() === DRAFT.toLowerCase()) {
        setIsLoading(false);
        if (!isWorkflow) {
          ShowToastSuccess(`${t("event")} ${t("updated_toast")}`);
        } else {
          workflowSubmitRequest(props, event_step);
        }
        setShowExitWarning(false);
      } else {
        await publishEvent(draftPageURL || currentEventData.current);
        setShowPublishConfirm(true);
      }
    } catch (error: any) {
      const statusCode = error?.networkError?.statusCode || 0;

      ShowToastError(t("api_error_toast"), statusCode);
      setIsLoading(false);
    }
  };

  const pageExistCloseHandle = () => {
    unsavedChanges.current = true;
    setOpenPageExistModal(false);
    setIsLoading(false);
  };

  const pageExistYesButtonHandle = () => {
    setOpenPageExistModal(false);
    if (pageStatus.toLowerCase() === DRAFT.toLowerCase()) {
      createEvent(DRAFT, true, workflowStatus);
    } else if (pageStatus.toLowerCase() === PUBLISHED.toLowerCase()) {
      createEvent(PUBLISHED, true);
    }
  };

  const crossButtonHandle = () => {
    setShowExitWarning(false);
    setOnSavedModal(false);
    navigate(`?path=${eventWholeRef?.current?.page}`);
  };

  const saveEvent = (status = true, props = {}, event_step = "") => {
    setShowExitWarning(false);
    updateCurrentInstance();
    // const currentDateTime = addMinutes(new Date(), 15).toISOString();
    // if (fieldValidation(eventState, status, currentDateTime, isError, setIsError)) {
    if (!currentEventData.current && isDraft) {
      createEvent(DRAFT, false, status);
    } else {
      updateEvent(DRAFT, status, props, event_step);
    }
    // }
  };

  const publish = () => {
    updateCurrentInstance();
    const currentDateTime = addMinutes(new Date(), 15).toISOString();
    if (fieldValidation(eventState, true, currentDateTime, isError, setIsError)) {
      if (!currentEventData.current && isDraft) {
        createEvent(PUBLISHED, false, false);
      } else {
        updateEvent(PUBLISHED, false);
      }
    }
  };

  const returnBack = () => {
    if (unsavedChanges.current === true) {
      setShowExitWarning(true);
    } else {
      navigate("/content/event");
    }
  };

  const scrollHandler = () => {
    if (isInViewport(seo, true)) {
      setParentToolTip(SEO);
    } else {
      const active = icons.find((i) => isInViewport(i.id, false));
      if (active && active.tooltip !== parentToolTip) {
        setParentToolTip(active.tooltip);
      }
    }
  };

  useEffect(() => {
    const dataHolder = document.getElementById("scrollableDiv");
    dataHolder?.addEventListener(SCROLL, scrollHandler);
    const fetchWorkflowDetails = async () => {
      setIsLoading(true);
      if (currentQuizData.current === "" && !Object.keys(currentContent).length) {
        await getWorkflowDetails(
          role,
          login_user_id,
          setWorkflow,
          capitalizeFirstLetter(ContentType.Event),
        );
      }
      setIsLoading(false);
    };
    fetchWorkflowDetails();
    return () => {
      window.removeEventListener(SCROLL, scrollHandler);
    };
  }, []);

  const handelPreview = () => {
    const tempObj = getPreviewContentData(eventState, eventWholeRef, createdUser.current, i18n);
    dispatch(
      previewContent({
        ...tempObj,
        workflow: workflow,
        isDraft: isDraft,
        draftPageURL: draftPageURL,
      }),
    );
    navigate("/content/preview", {
      state: { contentType: MAPPING.Event, pageName: currentEventData?.current || draftPageURL },
    });
  };

  const closeButtonHandle = () => {
    unsavedChanges.current = false;
    navigate("/content/event");
  };

  const handleSocialShareChange = (event) => {
    const { name } = event.target;
    eventWholeRef.current = {
      ...eventWholeRef.current,
      [name]: event.target.value,
    };
    setEventState({
      ...eventState,
      [name]: event.target.value,
    });

    unsavedChanges.current = true;
  };

  const handleSocialShareTagChange = (_, value) => {
    const result = value.filter((str) => str.trim()?.length !== 0);
    const updatedtags = result.filter((c, index) => {
      return result.indexOf(c) === index;
    });
    eventWholeRef.current = {
      ...eventWholeRef.current,
      tagsSocialShare: updatedtags,
    };
    setEventState({
      ...eventState,
      tagsSocialShare: updatedtags,
    });
  };

  const handleSocialShareImageChange = (data) => {
    setEventState((prevState) => {
      return {
        ...prevState,
        socialShareImgURL: data.relativeUrl,
      };
    });
    eventWholeRef.current = {
      ...eventWholeRef.current,
      socialShareImgURL: data.relativeUrl,
    };
    unsavedChanges.current = true;
  };

  const handleAnalyticsSeoChange = (event, keyName) => {
    eventWholeRef.current = { ...eventWholeRef.current, [keyName]: event?.target?.checked };
    setEventState((prevState) => {
      return {
        ...prevState,
        [keyName]: event?.target?.checked,
      };
    });
  };

  const handleStrucutreDataChange = (structureData) => {
    setEventState((prevState) => {
      return {
        ...prevState,
        structure_data: JSON.stringify(structureData),
      };
    });
  };

  const getContentByPath = async () => {
    try {
      const data: any = await eventAPIS.fetchContent({
        contentType: EVENT,
        path: currentEventData.current,
      });
      setIsLoading(false);
      const contentObj = data?.authoring_getCmsContentByPath;
      const {
        title,
        short_title: shortTitle,
        short_description: shortDesc,
        description,
        tags,
        settingsProperties,
        banner_image: imageUrl,
        path,
        workflow_status,
        stages,
        tag_name,
        last_modifiedBy,
        createdBy,
        task_status,
        user_id,
        user_name,
        is_featured,
      } = contentObj;
      setIsFeatured(is_featured);
      createdUser.current = createdBy;
      const tempObj = getTempObj(data, contentObj, eventState);
      setWorkflow({
        path,
        workflow_status,
        stages,
        tag_name,
        last_modifiedBy,
        createdBy,
        role,
        title,
        enable: stages?.length > 0 ? true : false,
        login_user_id,
        task_status,
        task_user_id: user_id,
        task_user_name: user_name,
        description,
      });
      setEventState(tempObj);
      setEventInstance({
        ...tempObj,
        page_state: data?.authoring_getCmsContentByPath?.page_state,
      });
      eventWholeRef.current = {
        title: title,
        short_title: shortTitle,
        short_description: shortDesc,
        description: description,
        imageUrl: imageUrl,
        tags: tags,
        socialShareImgURL: settingsProperties?.socialog_image,
        titleSocialShare: settingsProperties?.socialog_title,
        descriptionSocialShare: settingsProperties?.socialog_description,
        tagsSocialShare: settingsProperties?.keywords,
      };
    } catch (err: any) {
      const statusCode = err?.networkError?.statusCode || 0;
      ShowToastError("Some thing went wrong", statusCode);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(currentContent).length > 0) {
      eventWholeRef.current = currentContent?.eventWholeRef;
      setEventState(currentContent?.eventState);
      setWorkflow(currentContent?.workflow);
      setDraftPageURL(currentContent?.draftPageURL);
      setIsDraft(currentContent?.isDraft);
    } else if (currentEventData.current && unsavedChanges.current !== true) {
      setIsLoading(true);
      getContentByPath();
    }
  }, [currentContent]);

  useEffect(() => {
    const { title, description, address, postalCode, original_image, published_images } =
      eventState;
    if (
      title === "" ||
      description === "" ||
      address === "" ||
      postalCode === "" ||
      Object.keys(original_image).length === 0 ||
      published_images.length === 0
    ) {
      setPreviewButton(true);
    } else {
      if (!currentEventData?.current && isDraft) {
        setPreviewTooltip(false);
      } else {
        setPreviewButton(false);
      }
    }
  }, [eventState, isDraft]);

  useEffect(() => {
    const structureData = updateStructureData(eventState);
    setEventState((prevState) => {
      return {
        ...prevState,
        structure_data: JSON.stringify(structureData),
      };
    });
  }, [eventState]);

  const styles = `.sticky-header { position: sticky; top: 0; background-color: #fff; z-index:1} 
  .sticky-header.keyboard-open { position: relative; }`;
  return (
    <>
      <style> {styles} </style>
      {isLoading && <Loader />}
      <Box
        sx={{
          backgroundColor: "#FFF",
        }}></Box>
      <Box>
        <Box>
          <Box>
            <CreateHeader
              // className={isKeyboardOpen ? "sticky-header keyboard-open" : "sticky-header"}
              hasPreviewButton={previewButton}
              showPreview={true}
              handelPreview={handelPreview}
              createText={
                currentQuizData.current
                  ? `${t("edit")} ${t("event")}`
                  : `${t("create")} ${t("event")}`
              }
              publishText={t("publish")}
              saveText={t("save_as_draft")}
              previewText={t("preview")}
              toolTipText={previewTooltip ? t("preview_tooltip") : t("preview_tooltip1")}
              saveVariant='secondaryButton'
              // publish={publish}
              category={CATEGORY_CONTENT}
              subCategory={ContentType.Event}
              workflow={{ enable: false }}
              setEnableWorkflowHistory={setEnableWorkflowHistory}
              setIsFeatured={setIsFeatured}
              isFeatured={isFeatured}
              handleReturn={returnBack}
              handleSaveOrPublish={saveEvent}
              handlePublish={publish}
              publishDisabled={isLoading}
            />
            <Divider></Divider>
          </Box>

          <Box
            sx={{
              position: "relative",
              height: {
                sm: "calc(100vh - 125px)",
                xs: "calc(100vh - 45px)",
              },
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            id='scrollableDiv'>
            {!enableWorkflowHistory && (
              <Box
                sx={{
                  position: "fixed",
                  top: "25%",
                  right: { sm: "5px", xs: 0 },
                  zIndex: 1000,
                }}>
                <ContentPageScroll
                  icons={icons}
                  scrollToView={scrollToView}
                  parentToolTip={parentToolTip}
                />
              </Box>
            )}
            {enableWorkflowHistory ? (
              // <WorkflowHistory
              //   workflow={workflow}
              //   setEnableWorkflowHistory={setEnableWorkflowHistory}
              // />
              <>workflow history</>
            ) : (
              <>
                <EventTitleDescription
                  setSaveButton={() => {}}
                  //setPreviewButton={setPreviewButton}
                  eventWholeRef={eventWholeRef}
                  state={eventState}
                  setState={setEventState}
                  unsavedChanges={unsavedChanges}
                  setPublishButton={() => {}}
                  isError={isError}
                  setIsError={setIsError}
                />
                <EventImageAndThumbnail
                  state={eventState}
                  setState={setEventState}
                  eventWholeRef={eventWholeRef}
                  unsavedChanges={unsavedChanges}
                  isError={isError}
                  setIsError={setIsError}
                />
                <EventTimeAndLocation
                  state={eventState}
                  setState={setEventState}
                  unsavedChanges={unsavedChanges}
                  isError={isError}
                  setIsError={setIsError}
                />

                {/* <EventScheduleDate
                  state={eventState}
                  setState={setEventState}
                  unsavedChanges={unsavedChanges}
                /> */}

                <EventChooseTags
                  state={eventState}
                  setState={setEventState}
                  eventWholeRef={eventWholeRef}
                  content={currentContent}
                  unsavedChanges={unsavedChanges}
                  isEdit={currentEventData.current ? true : false}
                  setScrollToView={setScrollToView}
                  socialShareExpanded={false}
                />
                <SocialShare
                  titleSubTitles={socialShareTitleSubTitles()}
                  state={eventState}
                  handleOnBlur={handleSocialShareChange}
                  handleChange={() => {}}
                  handleTagChange={handleSocialShareTagChange}
                  handleImageChange={handleSocialShareImageChange}
                />
                <Analytics
                  titleSubTitles={analyticsTitleSubTitles()}
                  state={eventState}
                  handleChange={handleAnalyticsSeoChange}
                />
                <Seo
                  titleSubTitles={seoTitleSubTitles()}
                  state={eventState}
                  handleChange={handleAnalyticsSeoChange}
                  handleStrucutreDataChange={handleStrucutreDataChange}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>

      {showExitWarning && (
        <CommonPlateformXDialog
          isDialogOpen={showExitWarning}
          title={t("save_warn_title")}
          subTitle={t("save_warn_subtitle")}
          closeButtonText={t("stay_here")}
          confirmButtonText={t("take_me_out")}
          closeButtonHandle={() => {
            setShowExitWarning(false);
          }}
          confirmButtonHandle={closeButtonHandle}
          modalType='unsavedChanges'
        />
      )}
      <CommonPlateformXDialog
        isDialogOpen={onSavedModal}
        title={t(SAVE_AS_DRAFT_POP_UP.saveAsDraftTitle)}
        subTitle={t(SAVE_AS_DRAFT_POP_UP.saveAsDraftDescription)}
        closeButtonText={t(SAVE_AS_DRAFT_POP_UP.saveAsDraftCloseText)}
        confirmButtonText={t(SAVE_AS_DRAFT_POP_UP.saveAsDraftConfirmText)}
        confirmButtonHandle={() => navigate("/content/event")}
        closeButtonHandle={crossButtonHandle}
        modalType='draft'
      />

      <CommonPlateformXDialog
        isDialogOpen={showPublishConfirm || showWorkflowSubmit}
        title={t(publishPopup.current.publishTitle)}
        subTitle={
          showPublishConfirm
            ? `${t("your")} ${t("event")} ${t(publishPopup.current.publishDescription)}`
            : t("requested_action")
        }
        confirmButtonText={t(publishPopup.current.publishConfirmText)}
        confirmButtonHandle={() => navigate("/content/event")}
        modalType='publish'
      />
      {openPageExistModal && (
        <CommonPlateformXDialog
          isDialogOpen={openPageExistModal}
          title={t(PAGE_EXIST_POP_UP.saveAsDraftTitle)}
          subTitle={t(PAGE_EXIST_POP_UP.saveAsDraftDescription)}
          closeButtonText={t(PAGE_EXIST_POP_UP.saveAsDraftCloseText)}
          confirmButtonText={t(PAGE_EXIST_POP_UP.saveAsDraftConfirmText)}
          confirmButtonHandle={pageExistYesButtonHandle}
          closeButtonHandle={pageExistCloseHandle}
          crossButtonHandle={pageExistCloseHandle}
          modalType=''
        />
      )}
    </>
  );
};
export default React.memo(CreateEvent);
