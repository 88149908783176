const articleObj = {
  contentType: "Article",
  input: {
    CommonFields: {
      page: "",
      title: "",
      description: "",
      category: "Article",
      site_name: "",
      parent_page_url: "/",
      // current_page_url: "",
      developedby: "",
      page_state: "DRAFT",
      is_edit: false,
      seo_enable: true,
      analytics_enable: true,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      others: "",
      structure_data: "",
      createdBy: "",
      creationDate: "",
      modificationDate: "",
      tags: ["Articles"],
      page_createdby: "",
      page_lastmodifiedby: "",
      settings: {
        socialog_url: "",
        socialog_twitter_url: "",
        socialog_type: "article",
        socialog_sitename: "",
        seo_title: "",
        socialog_title: "",
        socialog_twitter_title: "",
        socialog_description: "",
        socialog_twitter_description: "",
        socialog_image: "",
        socialog_twitter_image: "",
        keywords: ["Articles"],
        seo_keywords: ["Articles"],
        seo_description: "",
        seo_blockIndexing: true,
      },
      IsConfirm: false,
      is_featured: false,
    },
    ObjectFields: {
      page_name: "",
      banner: "",
      sub_title: "",
      content_type: "Article",
      page_tags: [],
      link_tags: [],
      article_content: {},
      tag: [],
      links: [],
      published_images: [],
      original_image: {},
    },
  },
};

export default articleObj;
