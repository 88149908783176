import { useMutation } from "@apollo/client";
import { Box, Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import { contentTypeAPIs, contentTypeSchemaApi } from "@platformx/authoring-apis";
import { PopupImage, SaveAnimationGif } from "@platformx/shared/static-assets";
import {
  findMaxCharLength,
  formatTitle,
  frameTitle,
  getDisplayContentType,
  hasOwnProp,
  makeCreateContentPath,
  MaxLengthValidationMessage,
  ShowToastError,
  useUserSession,
} from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useCustomStyle } from "./CreateNewContent.styles";
import { DESCRIPTION_VALIDATION, getObjectBasedOnContent, TITLE_VALIDATION } from "./helper";

const CreateNewContent = ({ isDialogOpen, closeButtonHandle, contentType }) => {
  const { t } = useTranslation();
  const classes = useCustomStyle();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ title: false, description: false });
  const navigate = useNavigate();
  const [getSession] = useUserSession();
  const [createOldContent] = useMutation(contentTypeAPIs.createContentType);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setError({ ...error, title: false });
  };

  const handleClearAndClose = () => {
    setFormData({ title: "", description: "" });
    setError({ ...error, title: false });
    closeButtonHandle();
  };

  const formTitleAndUpdateState = () => {
    const updatedTitle = frameTitle(formData.title);
    setFormData((prevData) => ({ ...prevData, title: updatedTitle }));
  };

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (formData.title.trim().length > 0) {
        const { userInfo } = getSession();
        const createObj: any = await getObjectBasedOnContent(
          contentType,
          userInfo,
          formData.title,
          formData.description,
        );
        let path = "";
        if (hasOwnProp(createObj, "schema_document_type")) {
          try {
            const saveResponse: any = await contentTypeSchemaApi.saveContent(createObj);
            const { message, path: newPath } = saveResponse?.authoring_saveSchemaContent;
            if (message === "Successfully created!!!") {
              //ask statuscodes instead of this
              // ShowToastSuccess(`${contentType} ${t("created_toast")}`);
            }
            path = newPath;
          } catch (err: any) {
            ShowToastError(
              err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : t("api_error_toast"),
            );
          }
        } else {
          try {
            const { data } = await createOldContent({
              variables: {
                contenttype: createObj.contentType,
                input: createObj.input,
              },
            });
            path = data?.authoring_createContent?.path;
          } catch (err) {
            ShowToastError(t("api_error_toast"));
          }
          closeButtonHandle();
          setFormData({ title: "", description: "" });
        }
        if (path !== "") {
          const navigateTo = makeCreateContentPath(contentType);
          const page = path.split("/").pop();
          if (hasOwnProp(createObj, "schema_document_type")) {
            navigate(`${navigateTo}/${page}`, {
              state: contentType?.toLowerCase(),
            });
          } else {
            navigate(`${navigateTo}?path=${page}`, {
              state: contentType?.toLowerCase(),
            });
          }
        } else {
          ShowToastError(t("api_error_toast"));
        }
      } else {
        setError({ ...error, title: true });
      }
    } catch (err) {
      ShowToastError(t("api_error_toast"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setError({ title: false, description: false });
    setFormData({
      title: "",
      description: "",
    });
  }, [isDialogOpen]);

  return (
    <Dialog
      fullWidth
      open={isDialogOpen}
      onClose={handleClearAndClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={`${classes.formbuilderBgWrapper} formbuilderBg`}
      sx={{
        ".Platform-x-Dialog-paper": {
          padding: 0,
          width: { xs: "100%" },
          borderRadius: "5px",
          maxWidth: { sx: "100%", md: "700px", em: "800px" },
          margin: "15px",
        },
      }}>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box className='createPagePopupLeft'>
              <Box>
                <Box className='createPagePopuprowBox'>
                  <Typography id='alert-dialog-title' component='h2' variant='h3semibold'>
                    {`Create a New ${getDisplayContentType(contentType)}`}
                  </Typography>
                </Box>
                <Box className='createPagePopuprowBox'>
                  <Typography variant='h5regular'>{t("enter_name_of_the_item")}</Typography>
                  <TextField
                    autoFocus
                    placeholder={t("title_placeholder")}
                    margin='dense'
                    id='Title'
                    type='text'
                    fullWidth
                    variant='filled'
                    autoComplete='off'
                    name='title'
                    value={formData.title}
                    error={error.title}
                    onChange={(e) => {
                      const value = formatTitle(e.target.value);
                      handleChange({ target: { name: e.target.name, value } });
                    }}
                    onBlur={formTitleAndUpdateState}
                    inputProps={{
                      readOnly: false,
                      maxLength: findMaxCharLength(TITLE_VALIDATION),
                    }}
                    sx={{
                      ".Platform-x-Input-root:after": {
                        borderBottom: `1px solid primary`,
                      },
                    }}
                  />
                  <MaxLengthValidationMessage
                    validations={TITLE_VALIDATION}
                    currentLength={formData?.title?.length || 0}
                  />
                </Box>
                <Box className='createPagePopuprowBox'>
                  <Typography variant='h5regular'>{t("describe_the_item_optional")}</Typography>
                  <TextField
                    margin='dense'
                    id='formDescription'
                    type='text'
                    placeholder={t("description")}
                    fullWidth
                    multiline
                    rows={3}
                    variant='filled'
                    autoComplete='off'
                    name='description'
                    value={formData.description}
                    onChange={handleChange}
                    sx={{
                      ".Platform-x-Input-root:after": {
                        borderBottom: `1px solid primary`,
                      },
                    }}
                    inputProps={{
                      readOnly: false,
                      maxLength: findMaxCharLength(DESCRIPTION_VALIDATION),
                    }}
                  />
                  <MaxLengthValidationMessage
                    validations={DESCRIPTION_VALIDATION}
                    currentLength={formData?.description?.length || 0}
                  />
                </Box>
              </Box>
              <Box className='createPagePopupButtonWp'>
                <Button
                  disableElevation
                  variant='secondaryButton'
                  onClick={handleClearAndClose}
                  data-testid='form-close-button'>
                  {t("back")}
                </Button>
                <Button variant='primaryButton' disableElevation type='submit'>
                  {loading && <img src={SaveAnimationGif} alt='' />}
                  {loading ? t("creating") : t("create")}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className='createPagePopupRight'>
            <Box className='popupRightImage'>
              <img src={PopupImage} alt='Create Page Popup' />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CreateNewContent;
