import { Box, Divider, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BasicSwitch } from "@platformx/utilities";
import { LENGTH } from "../constants";
import InfoSettings from "./DropdownSettings/InfoSettings";

const FormTextBoxSetting = ({ selectedElement, updateElement, renderValidation, index }) => {
  const theme = useTheme();
  /**
   * Update the textBox placeholder/Title of the selected element.
   * updateElement is a function that updates the selected element with the new value.
   */
  const handleChange = (field, value) => {
    updateElement(selectedElement.id, { [field]: value });
  };
  return (
    <Box className='formTextBox elementWrapper'>
      <Box className='settingSection'>
        {/* checks optional key: Render switch if only the object have optional key to hide or show textbox if not needed.*/}
        {selectedElement && "optional" in selectedElement && (
          <Box mt={2} mb={2} display='flex' justifyContent='space-between'>
            <Typography variant='p4semibold'>Active</Typography>
            <BasicSwitch
              onChange={(e) => {
                const updatedValidations = selectedElement?.validations?.map((validation) => {
                  return validation.type === "required"
                    ? { ...validation, value: false }
                    : validation;
                });
                updateElement(selectedElement?.id, {
                  validations: updatedValidations,
                  optional: e.target.checked,
                });
              }}
              checked={selectedElement?.optional}
              color={theme.palette.customColor.PRIMARY}
            />
          </Box>
        )}
        <Box mt={1}>
          <Typography variant='p4regular'>Title Text</Typography>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedElement?.title}
            inputProps={{ maxLength: LENGTH.TITLE }}
            onChange={(e) => handleChange("title", e?.target?.value)}
          />
          <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
            {`${LENGTH.TITLE} characters max`}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant='p4regular'>Placeholder Text</Typography>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedElement?.placeholder}
            inputProps={{ maxLength: LENGTH.PLACEHOLDER }}
            onChange={(e) => handleChange("placeholder", e?.target?.value)}
          />
          <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
            {`${LENGTH.PLACEHOLDER} characters max`}
          </Typography>
        </Box>
      </Box>
      <InfoSettings updateElement={updateElement} selectedElement={selectedElement} />
      {selectedElement?.validations?.length > 0 && (
        <Box className='validationSection' mt={2} mb={2}>
          <Typography variant='h6semibold'>Validation</Typography>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          {selectedElement?.validations?.map((validationType) => (
            <Box key={index}>{renderValidation(validationType)}</Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FormTextBoxSetting;
