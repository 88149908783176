import { Box } from "@mui/material";
import { useState } from "react";
import CityList from "./CityList";
import CountryList from "./CountryList";
import StateList from "./StateList";

const CountryStateCityDropdown = ({ field, register, secondaryArgs, parentElement }) => {
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);

  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [stateLoading, setStateLoading] = useState<boolean>(false);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setStateLoading(true);
  };

  const handleSelectedState = (value) => {
    setSelectedState(value);
  };

  return (
    <>
      {(() => {
        switch (field?.type) {
          case "country":
            return (
              <CountryList
                setSelectedCountry={handleCountryChange}
                field={field}
                register={register}
                secondaryArgs={secondaryArgs}
                countryLoading={countryLoading}
                setCountryLoading={setCountryLoading}
                setStateLoading={setStateLoading}
              />
            );
          case "country-state": {
            const countryField = parentElement?.splitFields?.find(
              (item) => item?.type === "country",
            );
            return (
              <Box
                className='country-state'
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: field?.alignRow ? "row" : "column",
                  gap: "12px",
                }}>
                <CountryList
                  setSelectedCountry={handleCountryChange}
                  field={countryField}
                  register={register}
                  secondaryArgs={secondaryArgs}
                  countryLoading={countryLoading}
                  setCountryLoading={setCountryLoading}
                  setStateLoading={setStateLoading}
                />
                <StateList
                  selectedCountry={selectedCountry}
                  setSelectedState={handleSelectedState}
                  field={field}
                  register={register}
                  secondaryArgs={secondaryArgs}
                  stateLoading={stateLoading}
                  countryLoading={countryLoading}
                  setStateLoading={setStateLoading}
                />
              </Box>
            );
          }
          case "country-state-city": {
            const countryField = parentElement?.splitFields?.find(
              (item) => item?.type === "country",
            );
            const stateField = parentElement?.splitFields?.find(
              (item) => item?.type === "country-state",
            );
            return (
              <Box
                className='country-state-city'
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: field?.alignRow ? "row" : "column",
                  // display: field?.alignRow ? "flex" : "block",
                  gap: "12px",
                }}>
                <CountryList
                  setSelectedCountry={handleCountryChange}
                  field={countryField}
                  register={register}
                  secondaryArgs={secondaryArgs}
                  countryLoading={countryLoading}
                  setCountryLoading={setCountryLoading}
                  setStateLoading={setStateLoading}
                />
                <StateList
                  selectedCountry={selectedCountry}
                  setSelectedState={handleSelectedState}
                  field={stateField}
                  register={register}
                  secondaryArgs={secondaryArgs}
                  stateLoading={stateLoading}
                  countryLoading={countryLoading}
                  setStateLoading={setStateLoading}
                />
                <CityList
                  selectedCountry={selectedCountry}
                  selectedState={selectedState}
                  field={field}
                  register={register}
                  secondaryArgs={secondaryArgs}
                  stateLoading={stateLoading}
                  countryLoading={countryLoading}
                />
              </Box>
            );
          }
          default:
            return null;
        }
      })()}
    </>
  );
};

export default CountryStateCityDropdown;
