import getGraphQlInstance from "../graphqlConfig";
import { GET_CONTENT_DATA, SAVE_FORM } from "./queries";

export const graphQlFormBuilder = {
  fetchOldContentData: async <T>(input: T, lang, host, endPoint): Promise<any> => {
    const { loading, errors, data } = await getGraphQlInstance(lang, host, endPoint).query({
      query: GET_CONTENT_DATA,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { loading, errors, data };
  },
  saveForm: async <T>(input: T, lang: string, host: string, endPoint: string): Promise<any> => {
    const { errors, data } = await getGraphQlInstance(lang, host, endPoint).mutate({
      mutation: SAVE_FORM,
      variables: input,
      fetchPolicy: "network-only",
    });
    return { errors, data };
  },
};
