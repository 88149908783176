import { Chip, useTheme } from "@mui/material";
import { memo } from "react";

const Tags = ({ tags, selectedTags, onSelectTags }) => {
  const theme = useTheme();
  return (
    <>
      {tags?.map((tagItem) => (
        <Chip
          key={tagItem}
          label={tagItem}
          className={selectedTags.includes(tagItem) ? "tag" : "tag-selected"}
          // variant='outlined'
          onClick={() => onSelectTags(tagItem)}
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            marginRight: "10px",
            marginTop: "5px",
            borderRadius: theme.borderRadius.value,
            // backgroundColor: "#fff",
            // color: "#14142B",
            // border: "1px solid #14142B",
          }}
        />
      ))}
    </>
  );
};

export default memo(Tags);
