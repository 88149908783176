import { Box, Divider, TextField, Typography, useTheme } from "@mui/material";
import { BasicSwitch } from "@platformx/utilities";

const FormNameSetting = ({
  selectedElement,
  updateElement,
  updateSplitelement,
  renderValidation,
  splitRenderValidation,
}) => {
  const theme = useTheme();
  const handleChange = (field, value) => {
    updateElement(selectedElement.id, { [field]: value });
  };
  const handleChangeValue = (field, value, index) => {
    updateSplitelement(selectedElement?.id, selectedElement?.splitFields?.[index]?.id, {
      [field]: value,
    });
  };
  // console.log("selectedElement", selectedElement);
  return (
    <Box className='formTextBox elementWrapper'>
      <Box className='settingSection'>
        {/* <Typography variant='h5semibold'>Full Name Setting</Typography>
        <Box mt={1.5} mb={1.5}>
          <Divider />
        </Box> */}
        {selectedElement?.split ? (
          selectedElement?.splitFields?.map((splitFieldItem, i) => (
            <Box key={i} mt={1}>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='p4regular'>{splitFieldItem?.label} title</Typography>
                {selectedElement?.splitFields?.[i] &&
                  "optional" in selectedElement.splitFields[i] && (
                    <BasicSwitch
                      onChange={(e) => {
                        const updatedValidations = selectedElement?.splitFields[
                          i
                        ]?.validations?.map((validation) => {
                          return validation.type === "required"
                            ? { ...validation, value: false }
                            : validation;
                        });
                        updateSplitelement(
                          selectedElement?.id,
                          selectedElement?.splitFields?.[i]?.id,
                          {
                            validations: updatedValidations,
                            optional: e.target.checked,
                          },
                        );
                      }}
                      // handleChangeValue("optional", e.target.checked, i)}
                      checked={selectedElement?.splitFields[i]?.optional}
                      color={theme.palette.customColor.PRIMARY}
                    />
                  )}
              </Box>
              <TextField
                fullWidth
                margin='dense'
                variant='outlined'
                value={splitFieldItem?.title}
                onChange={(e) => {
                  handleChangeValue("title", e.target.value, i);
                }}
              />
            </Box>
          ))
        ) : (
          <Box mt={1}>
            <Typography variant='p4regular'>Full name title</Typography>
            <TextField
              fullWidth
              margin='dense'
              variant='outlined'
              value={selectedElement?.title}
              onChange={(e) => handleChange("title", e?.target?.value)}
            />
          </Box>
        )}
        {/* split Validation render */}
        {selectedElement?.split && (
          <Box className='validationSection' mt={2}>
            <Typography variant='h6semibold'>Validation</Typography>
            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>
            {selectedElement.splitFields?.map((splitField, index) => (
              <Box key={splitField?.id} mb={2}>
                <Typography variant='subtitle1'>{splitField.label}</Typography>
                {splitField.validations && splitField.validations?.length > 0
                  ? splitField.validations.map((validationType, validationIndex) => (
                      <Box key={`${splitField?.id}-${validationIndex}`}>
                        {splitRenderValidation(validationType, index)}
                      </Box>
                    ))
                  : null}
              </Box>
            ))}
          </Box>
        )}
        {/* Parent Validation render */}
        {!selectedElement?.split && selectedElement?.validations?.length > 0 && (
          <Box className='validationSection' mt={2}>
            <Typography variant='h6semibold'>Validation</Typography>
            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>
            {selectedElement?.validations?.map((validationType, index) => (
              <Box key={index}>{renderValidation(validationType)}</Box>
            ))}
          </Box>
        )}

        {/* {selectedElement?.split && selectedElement?.splitFields
          ? selectedElement.splitFields.map((splitField) => (
              <Box key={splitField?.id}>
                <Typography variant='subtitle1'>{splitField.label}</Typography>
                {splitField.validations && splitField.validations.length > 0
                  ? splitField.validations.map((validationType, validationIndex) => (
                      <Box key={`${splitField?.id}-${validationIndex}`}>
                        {renderValidation(validationType)}
                      </Box>
                    ))
                  : null}
              </Box>
            ))
          : selectedElement?.validations
            ? selectedElement?.validations?.map((validationType, index) => (
                <Box key={index}>{renderValidation(validationType)}</Box>
              ))
            : null} */}

        {/* <Box mt={1}>
          <Typography variant='p4regular'>Title Text</Typography>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedElement?.title}
            onChange={(e) => handleChange("title", e?.target?.value)}
          />
        </Box> */}
        {/* <Box mt={1}>
          <Typography variant='p4regular'>Placeholder Text</Typography>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedElement?.placeholder}
            onChange={(e) => handleChange("placeholder", e?.target?.value)}
          />
        </Box> */}
      </Box>
      {/* <Box className='informationSection' mt={2}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h5semibold'>Info Setting</Typography>
          <BasicSwitch
            onChange={() =>
              updateElement(selectedElement.id, {
                info: {
                  ...selectedElement?.info,
                  showMessage: !selectedElement?.info?.showMessage,
                },
              })
            }
            checked={selectedElement?.info?.showMessage || false}
            color={theme?.palette?.customColor?.PRIMARY}
          />
        </Box>
        {selectedElement?.info?.showMessage && (
          <>
            <Box mt={1.5} mb={1.5}>
              <Divider />
            </Box>
            <Box mt={1}>
              <Typography variant='p4regular'>Tooltip Message</Typography>
              <TextField
                fullWidth
                margin='dense'
                variant='outlined'
                value={selectedElement?.info?.message}
                onChange={(e) =>
                  updateElement(selectedElement.id, {
                    info: { ...selectedElement?.info, message: e?.target?.value },
                  })
                }
              />
            </Box>
          </>
        )}
      </Box>

      {selectedElement?.validations?.length > 0 && (
        <Box className='validationSection' mt={2}>
          <Typography variant='h6semibold'>Validation</Typography>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          {selectedElement?.validations?.map((validationType, index) => (
            <Box key={index}>{renderValidation(validationType)}</Box>
          ))}
        </Box>
      )} */}
    </Box>
  );
};

export default FormNameSetting;
