import { Box, Typography } from "@mui/material";
import CheckboxGroup from "../Components/CheckboxGroup/CheckboxGroup";
import { checkRequired } from "../helper";
import ControllInfo from "./Common/ControllInfo/ControllInfo";

const FormRadioGroup = ({
  item,
  register,
  clearErrors,
  errors,
  selectedElement,
  setSelectedElement,
  isRendering,
}) => {
  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    clearErrors(name);
    setSelectedElement((prev) => {
      const prevValues = Array.isArray(prev?.default_value) ? prev?.default_value : [];
      const updatedValues = checked
        ? [...prevValues, value] // Add new selection
        : prevValues.filter((v) => v !== value); // Remove unchecked option
      return {
        ...prev,
        [name]: updatedValues,
        default_value: updatedValues,
      };
    });
  };

  return (
    <Box className='formRadio elementWrapper' mt={1}>
      <Typography
        variant='p4regular'
        className='form-label'
        mt={1}
        mb={1}
        display='flex'
        alignItems='center'>
        <Box component='span' className='form-label-with-info'>
          {item?.title}
          {checkRequired(item?.validations)}
        </Box>
        {item?.info?.showMessage && <ControllInfo item={item} />}
      </Typography>

      <CheckboxGroup
        options={item?.options}
        selectedElement={selectedElement}
        field={item}
        row={item.alignRow}
        handleChange={handleChange}
        error={errors[item.name]}
        register={register}
        clearErrors={clearErrors}
        isRendering={isRendering}
      />
    </Box>
  );
};

export default FormRadioGroup;
