import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    brightcovePlayerWrapper: {
      "&.brightcovePlayercontainer": {
        "& .video-js .vjs-control": {
          [theme.breakpoints.down("sm")]: {
            width: "2.8em",
          },
        },
      },
    },
  };
});

export default useCustomStyle;
