const eventObject = {
  contentType: "Event",
  input: {
    CommonFields: {
      page: "",
      title: "",
      tag_name: "Event",
      category: "Event",
      site_name: "",
      page_state: "DRAFT",
      description: "",
      short_description: "",
      is_edit: false,
      seo_enable: true,
      analytics_enable: true,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      short_title: "",
      others: "",
      structure_data: "",
      creationDate: "",
      modificationDate: "",
      tags: ["Events"],
      createdBy: "",
      parent_page_url: "/",
      page_lastmodifiedby: "",
      settings: {
        socialog_url: "",
        socialog_type: "event",
        socialog_sitename: "",
        seo_title: "",
        socialog_title: "",
        socialog_twitter_title: "",
        socialog_description: "",
        socialog_twitter_description: "",
        socialog_twitter_url: "",
        keywords: ["Events"],
        seo_keywords: ["Events"],
        seo_description: "",
        socialog_image: "",
        socialog_twitter_image: "",
        is_schedule_publish: false,
        schedule_publish_datetime: "",
        is_schedule_unpublish: false,
        schedule_unpublish_datetime: "",
      },
      IsConfirm: false,
      is_featured: false,
    },
    ObjectFields: {
      background_content: {},
      thumbnail_image: "",
      banner_image: "",
      actual_address: "",
      event_end_date: "",
      event_start_date: "",
      virtual_address: "",
      locality: "",
      postal_code: "",
      region_state: "",
      country: "",
      google_api_address: "",
      original_image: {},
      published_images: [],
    },
  },
};

export default eventObject;
