import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { dynamicAPICall } from "../../../helper";

interface State {
  id: string;
  label: string;
}

const StateList = ({
  selectedCountry,
  setSelectedState,
  field,
  register,
  secondaryArgs,
  stateLoading,
  setStateLoading,
  countryLoading,
}) => {
  const [stateList, setStateList] = useState<State[]>([]);
  const [stateName, setStateName] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedCountry) return;
    const fetchStates = async () => {
      const splitSelectedCountry = selectedCountry.split("-")?.[1];

      const obj = {
        query: `query {publish_getDynamicDropDownItems(api_variant:"States", filter:{CountryCode:${splitSelectedCountry}})}`,
        responseVariable: "publish_getDynamicDropDownItems",
      };
      try {
        setStateLoading(true);
        const result = await dynamicAPICall(obj.query, obj.responseVariable, secondaryArgs);
        if (result && result.length > 0) {
          let mappedResults = result.map((item) => ({
            label: item.label,
            id: `${item.label}-${item.id}`,
          }));
          mappedResults = [{ label: "Select", id: "No Selection" }, ...mappedResults];
          setStateList(mappedResults);
          setStateName(mappedResults[0]?.id);
          setSelectedState(mappedResults[0]?.id);
        } else {
          setStateList([]); // Reset state list if no states are returned
          setSelectedState(null);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
        setStateList([]); // Reset state list on error
        setSelectedState(null);
      } finally {
        setStateLoading(false);
      }
    };

    fetchStates();
  }, [selectedCountry]);

  const handleStateChange = (event) => {
    setStateName(event.target.value);
    setSelectedState(event.target.value);
  };

  // console.log("shadowState", stateLoading, countryLoading, selectedCountry, stateName);

  return (
    <FormControl fullWidth sx={{ mb: 1, flex: 1 }}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {field?.title}
      </Typography>
      {countryLoading || stateLoading || stateName === null ? (
        <Select
          label='State'
          variant='filled'
          sx={{ minWidth: "130px" }}
          defaultValue='Loading...'
          className='dynamicdropdown'>
          <MenuItem value='Loading...' disabled>
            Loading...
          </MenuItem>
        </Select>
      ) : (
        <>
          {/* {console.log("shadowStateXO", stateName)} */}
          <Select
            key={stateName}
            defaultValue={stateName}
            label='State'
            variant='filled'
            sx={{ minWidth: "130px" }}
            className='dynamicdropdown'
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  minWidth: 200,
                },
              },
            }}
            name={field?.name}
            {...register(`${field?.name}`, {
              onChange: (event) => {
                handleStateChange(event);
              },
            })}>
            {stateLoading && countryLoading ? (
              <MenuItem value='' disabled>
                Loading...
              </MenuItem>
            ) : stateList?.length === 0 ? (
              <MenuItem value='' disabled>
                No Data Found
              </MenuItem>
            ) : (
              stateList?.map((item, i) => (
                <MenuItem key={`${item?.id}-${i}`} value={item?.id}>
                  <Box display='flex' sx={{ maxHeight: "20px", overflow: "hidden" }}>
                    {item.label}
                  </Box>
                </MenuItem>
              ))
            )}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default StateList;
