/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { GreenTick } from "@platformx/shared/static-assets";
import { Loader } from "@platformx/utilities";
import axios from "axios";
import { useEffect, useState } from "react";
import Confirmation from "../../../ContentTypes/Ecommerce/Common/Confirmation/Confirmation";
import {
  ProductAddOnReplacementToCart,
  addPaymentMethod,
  getCartId,
  placeOrder,
  proceedToShippingAddress,
} from "../../../ContentTypes/Ecommerce/helperEcommerce";
import ToastService from "../../../components/ToastContainer/ToastService";
import { HOST, PROJECT_KEY } from "../Constants";
import {
  AmountWithCurrency,
  getCustomerEmail,
  getRandomNumebrs,
  returnOrderedLineItem,
  setEmailInCT,
} from "../HelperFunction";

const ProductCart = ({
  selectedProduct,
  onBackClick,
  stateManage,
  token,
  secondaryArgs,
  addonCartItem,
}) => {
  const [isConfirmOrder, setIsconfirmOrder] = useState(false);
  const [loader, setLoader] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const userId = localStorage.getItem("userId");
  const confirmationText = `We have received your order for replacement. Your order Id is ${orderId}.`;
  const totalAfterDiscount = addonCartItem.reduce((acc, curr) => +curr.price + acc, 0);
  const totalBeforeDiscount = +selectedProduct.price + totalAfterDiscount;

  const customerAddress = {
    city: selectedProduct?.shippingAddress?.city,
    email: getCustomerEmail(),
    address: selectedProduct?.shippingAddress?.region,
    landmark: selectedProduct?.shippingAddress?.postalCode,
    lastName: selectedProduct?.shippingAddress?.lastName,
    country: selectedProduct?.shippingAddress?.country,
    firstName: selectedProduct?.shippingAddress?.firstName,
    alterNumber: selectedProduct?.shippingAddress?.mobile,
    contactNumber: selectedProduct?.shippingAddress?.mobile,
    state: selectedProduct?.shippingAddress?.state,
  };
  const Address = {
    title: "My Address",
    salutation: "Mr.",
    firstName: selectedProduct?.shippingAddress?.firstName,
    lastName: selectedProduct?.shippingAddress?.lastName,
    streetName: "Examplary Street",
    streetNumber: "4711",
    additionalStreetInfo: "Backhouse",
    postalCode: selectedProduct?.shippingAddress?.postalCode,
    city: selectedProduct?.shippingAddress?.city,
    region: selectedProduct?.shippingAddress?.region,
    state: selectedProduct?.shippingAddress?.state,
    country: selectedProduct?.shippingAddress?.country,
    company: "My Company Name",
    department: "Sales",
    building: "Hightower 1",
    apartment: "247",
    pOBox: selectedProduct?.shippingAddress?.postalCode,
    phone: selectedProduct?.shippingAddress?.mobile,
    mobile: selectedProduct?.shippingAddress?.mobile,
    email: getCustomerEmail(),
    fax: selectedProduct?.shippingAddress?.mobile,
    additionalAddressInfo: "no additional Info",
    externalId: "Information not needed",
  };

  const confirmationPopUpClose = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (orderId) {
      setIsconfirmOrder(true);
      setLoader(false);
    }
  }, [orderId]);

  //New Order creation
  /* eslint-disable no-await-in-loop */
  const addOnItemsToCart = async (addonCartSelectedItems, cartId) => {
    const results: any[] = [];

    for (const addonCartSelectedItem of addonCartSelectedItems) {
      try {
        const response = await ProductAddOnReplacementToCart({
          id: addonCartSelectedItem?.id,
          cartId: cartId,
          quantity: addonCartSelectedItem?.quantity || 1,
          secondaryArgs: secondaryArgs,
          ecomx_variant_id: addonCartSelectedItem?.variantId || 1, // Default to 1 if variantId is not provided
        });
        //console.log("response", response);

        results.push(response);
      } catch (error) {
        console.error("Error adding product to cart:", error);
        // Optionally, you can break the loop if an error occurs
        // break;
      }
    }

    return results;
  };

  /**
   * Places the final order by performing a series of API calls:
   * 1. Create a cart ID.
   * 2. Adds the selected product to the cart.
   * 3. Adds any addon items to the cart.
   * 4. Proceeds to shipping address.
   * 5. Adds a payment method.
   * 6. Places the order.
   * 7. Returns the ordered line item.
   *
   * @param {Object} secondaryArgs - Additional arguments required for API calls.
   */
  const placeFinalOrder = async () => {
    try {
      // Step 1: Retrieve a new cart ID
      const responseCartId = await getCartId(secondaryArgs);
      const {
        data: {
          data: {
            publish_addProductToCart: { cartId = "", statusCode: cartStatusCode = 0 } = {},
          } = {},
        } = {},
      } = responseCartId;

      // Check if cart ID retrieval was successful
      if (cartStatusCode === 200 && cartId) {
        // Step 2: Add the selected product to the cart
        const responseAddProduct = await ProductAddOnReplacementToCart({
          id: selectedProduct?.productId,
          cartId: cartId,
          quantity: selectedProduct?.quantity || 1,
          secondaryArgs: secondaryArgs,
          ecomx_variant_id: selectedProduct?.variantId || 1,
        });

        const { data: { data: { publish_addProductToCart: addProductResponse = {} } = {} } = {} } =
          responseAddProduct;
        const {
          statusCode: addProductStatusCode = 0,
          msg: addProductMsg = "",
          line_item_id: replacedLineItemId = "",
        } = addProductResponse;

        // Check if adding the selected product was successful
        if (addProductStatusCode === 200) {
          // Step 3: Add addon items to the cart
          await addOnItemsToCart(addonCartItem, cartId);

          // Step 4: Proceed to shipping address
          await proceedToShippingAddress({
            secondaryArgs,
            newObj: {
              ...customerAddress,
              cartId: cartId,
            },
          });
          // Step 5: Add payment method
          const paymentResponse = await addPaymentMethod({ secondaryArgs, cartId });

          const {
            data: {
              data: {
                publish_addProductToCart: {
                  msg: paymentMsg = "",
                  statusCode: paymentStatusCode = 0,
                } = {},
              } = {},
            } = {},
          } = paymentResponse;

          // Check if payment method addition was successful
          if (paymentStatusCode === 200) {
            // Step 6: Place the order
            const orderResponse = await placeOrder({
              secondaryArgs,
              cartId,
              userId,
              total_price: "" + Math.round(totalAfterDiscount), // Ensuring total_price is a string
            });

            const {
              data: {
                data: {
                  publish_addProductToCart: {
                    msg: orderMsg = "",
                    statusCode: orderStatusCode = 0,
                    order_number = "",
                    order_id = "",
                  } = {},
                } = {},
              } = {},
            } = orderResponse;

            // Check if order placement was successful
            if (orderStatusCode === 200) {
              setOrderId(order_id); // Updating state with the new order number

              // Step 7: Return the ordered line item
              await returnOrderedLineItem(
                secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
                secondaryArgs?.sitename,
                selectedProduct?.orderid,
                selectedProduct?.productSequenceNumber,
                stateManage.replacementReason,
                order_id,
                replacedLineItemId,
              );

              console.log("Ordered line item returned successfully.");
            } else {
              console.error("Failed to place the order:", orderMsg);
            }
          } else {
            console.error("Failed to add payment method:", paymentMsg);
          }
        } else {
          console.error("Failed to add selected product to cart:", addProductMsg);
        }
      } else {
        console.error("Failed to retrieve cart ID.");
      }
    } catch (error) {
      console.error("An error occurred during the order placement process:", error);
    }
  };

  //5th Step to create final order
  const createOrder = async (cart_id: any, cart_version: any) => {
    const data = JSON.stringify({
      id: cart_id,
      version: cart_version,
      orderNumber: getRandomNumebrs().toString(),
    });

    const config = {
      method: "post",
      url: `${HOST}/${PROJECT_KEY}/orders`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios(config)
      .then(async function (response) {
        await setEmailInCT(
          response?.data?.id,
          { email: getCustomerEmail() },
          secondaryArgs?.sitename,
          secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
        );
        setOrderId(response?.data?.orderNumber);
        await returnOrderedLineItem(
          secondaryArgs?.prelemBaseEndpoint?.deliveryEndPointGq,
          secondaryArgs?.sitename,
          selectedProduct?.orderid,
          selectedProduct?.productSequenceNumber,
          stateManage.replacementReason,
          response?.data?.orderNumber,
          "",
        );
        await emptyCustomfield();
      })
      .catch(function (error) {
        ToastService.failToast(error);
      });
  };

  //4th step to create LineItem
  const setLineItemPrice = (version_id, line_item_id, cart_id) => {
    const data = {
      version: version_id,
      actions: [
        {
          action: "setLineItemPrice",
          lineItemId: line_item_id,
          externalPrice: {
            currencyCode: "USD",
            centAmount: 0,
          },
        },
      ],
    };
    const config = {
      method: "post",
      url: `${HOST}/${PROJECT_KEY}/carts/${cart_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    };

    axios(config)
      .then(async function (response) {
        await createOrder(cart_id, response?.data?.version);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  //3rd step to create cart Data
  const setCartData = async (cart_id: any, version: any, payment_id: any) => {
    const Action = [
      {
        action: "addLineItem",
        productId: selectedProduct?.productId,
        variantId: selectedProduct?.variantId || 1,
        quantity: selectedProduct?.quantity,
      },
      {
        action: "setShippingAddress",
        address: {
          id: "exampleAddress",
          key: "exampleKey",
          ...Address,
        },
      },
      {
        action: "setBillingAddress",
        address: {
          id: "exampleAddress1",
          key: "exampleKey",
          ...Address,
        },
      },
      {
        action: "addPayment",
        payment: {
          id: payment_id,
          typeId: "payment",
        },
      },
    ];
    if (addonCartItem.length > 0) {
      const objArray = addonCartItem.map((val) => ({
        action: "addLineItem",
        productId: val?.id,
        variantId: 1,
        quantity: 1,
      }));
      Action.splice(1, 0, ...objArray);
    }

    const data = JSON.stringify({
      version: version,
      actions: Action,
    });

    const config = {
      method: "post",
      url: `${HOST}/${PROJECT_KEY}/carts/${cart_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios(config)
      .then(async function (response) {
        await setLineItemPrice(response.data?.version, response?.data?.lineItems[0]?.id, cart_id);
      })
      .catch(function (error) {
        ToastService.failToast(error);
      });
  };

  //2nd step to create cart
  const createCart = async (payment_id: any) => {
    try {
      const data = JSON.stringify({
        currency: "USD",
      });

      const config = {
        method: "post",
        url: `${HOST}/${PROJECT_KEY}/carts`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      await axios(config)
        .then(async function (response) {
          await setCartData(response?.data?.id, response?.data?.version, payment_id);
        })
        .catch(function (error) {
          ToastService.failToast(error);
        });
    } catch (e) {
      ToastService.failToast(e);
    }
  };

  //1st step to create payment

  const createPayment = async () => {
    const data = JSON.stringify({
      key: getRandomNumebrs().toString(),
      interfaceId: getRandomNumebrs().toString(),
      amountPlanned: {
        currencyCode: "USD",
        centAmount: 0,
      },
      paymentMethodInfo: {
        paymentInterface: "COD",
        method: "cash_on_delivery",
        name: {
          en: "Cash on delivery",
        },
      },
      transactions: [
        {
          timestamp: new Date().toISOString(),
          type: "Authorization",
          amount: {
            currencyCode: "USD",
            centAmount: 0,
          },
          state: "Pending",
        },
      ],
    });

    const config = {
      method: "post",
      url: `${HOST}/${PROJECT_KEY}/payments`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        createCart(response?.data?.id);
      })
      .catch(function (error) {
        ToastService.failToast(error);
      });
  };

  const emptyCustomfield = async () => {
    let data = JSON.stringify({
      version: 1,
      actions: [
        {
          action: "setLineItemCustomField",
          lineItemId: `${selectedProduct?.productSequenceNumber}`,
          name: "warrantyRegisteredFrom",
          value: "",
        },
        {
          action: "setLineItemCustomField",
          lineItemId: `${selectedProduct?.productSequenceNumber}`,
          name: "IsWarrantyRegistered",
          value: false,
        },
      ],
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST}/${PROJECT_KEY}/orders/${selectedProduct?.orderid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(response.data));
      })
      .catch(async (error) => {
        const newVersion = error?.response?.data?.errors[0]?.currentVersion;
        if (newVersion) {
          const newData = JSON.parse(data);
          newData.version = newVersion;
          data = JSON.stringify(newData);
          const configNew = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${HOST}/${PROJECT_KEY}/orders/${selectedProduct?.orderid}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: data,
          };
          await axios
            .request(configNew)
            .then((response) => {
              // eslint-disable-next-line no-console
              console.log(JSON.stringify(response.data));
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log("SETEMAILERROR", err);
            });
        }
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const setReturnInfo = () => {
    const data = JSON.stringify({
      version: selectedProduct?.version,
      actions: [
        {
          action: "setReturnInfo",
          items: [
            {
              items: [
                {
                  quantity: selectedProduct?.quantity,
                  lineItemId: selectedProduct?.lineItemId,
                  comment: stateManage.replacementReason,
                  shipmentState: "Returned",
                },
              ],
              returnTrackingId: getRandomNumebrs().toString(),
              returnDate: new Date().toISOString(),
            },
          ],
        },
      ],
    });
    const config = {
      method: "post",
      url: `${HOST}/${PROJECT_KEY}/orders/${selectedProduct?.orderid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // eslint-disable-next-line no-console
        console.info(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const confirmOrder = async () => {
    setLoader(true);
    await placeFinalOrder();
    //await createPayment();
    setLoader(false);
  };

  const ProductBox = ({ value, wr }) => {
    return (
      <Box className='productListWrapper'>
        {/* Image Wrapper */}
        <Box
          sx={{
            width: 92,
            height: 92,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Box sx={{ padding: "5px" }}>
            <img
              src={value.lineItemImageUrl}
              alt='Product'
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ flex: 1, marginLeft: 2 }}>
          <Grid item xs={6}>
            <Typography variant='h4regular' sx={{ marginBottom: 0 }}>
              {value?.attributes?.category}
            </Typography>
            <Typography variant='labelregular' sx={{ margin: 0, textTransform: "none" }}>
              {value?.name}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant='h4regular' sx={{ marginBottom: 0 }}>
              {wr ? "Free" : " "}
            </Typography>
            <Typography
              variant='labelregular'
              sx={{ textDecoration: wr ? "line-through" : "none" }}>
              {value?.price ? `${AmountWithCurrency(value?.price)}` : ""}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Box className='productRegistration registeredProduct productCart'>
          <Grid container>
            <Grid container alignItems='center' spacing={1} sx={{ mb: 2 }}>
              <Grid item>
                <IconButton>
                  <ArrowBackIcon
                    onClick={() => {
                      onBackClick();
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant='h3semibold'>Cart</Typography>
              </Grid>
              <Grid item xs />
            </Grid>
            <Grid item xs={12} md={7} lg={8} className='formcontainerLeft'>
              <ProductBox value={selectedProduct} wr={true} />
              {addonCartItem.map((obj) => (
                <ProductBox key={obj.id} value={obj} wr={false} />
              ))}
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Box className='formContainerRight'>
                <Box className='formContainerRight'>
                  <Box className='rightPanel'>
                    <Typography variant='h3semibold' className='marginTopZero'>
                      Selected Product
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                      }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Typography variant='p3regular'>Price:</Typography>
                        <Typography variant='p3regular'>
                          {selectedProduct?.price
                            ? `${AmountWithCurrency(totalBeforeDiscount)}`
                            : ""}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 2,
                        }}>
                        <Typography variant='p3regular'>Discount:</Typography>
                        <Typography variant='p3regular'>
                          {" "}
                          {selectedProduct?.price
                            ? `- ${AmountWithCurrency(selectedProduct?.price)}`
                            : ""}
                        </Typography>
                      </Box>
                      <Divider className='hr' sx={{ marginBottom: 2 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 1,
                        }}>
                        <Typography variant='p4semibold'>Total</Typography>
                        <Typography variant='p4semibold'>
                          {AmountWithCurrency(totalAfterDiscount)}
                        </Typography>
                      </Box>
                      <Button
                        variant='primaryButton1'
                        color='primary'
                        onClick={() => confirmOrder()}>
                        Confirm order
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {isConfirmOrder && (
            <Confirmation
              open={true}
              img={GreenTick}
              handleClose={confirmationPopUpClose}
              text={confirmationText}
              title={"Order Placed Successfully!"}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default ProductCart;
