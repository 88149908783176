import EastIcon from "@mui/icons-material/East";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import {
  getDisplayContentType,
  getImage,
  getLandingPageURLwithoutSlash,
} from "@platformx/utilities";
import { useState } from "react";
import { useClickImpression } from "../../components/ImpressionHooks/ClickImpressionHook";
import { getIcon } from "../../components/Utils/helperFns";
import ImageVideoGalleryModalSliderNew from "../ImageVideoGalleryModalSlider/ImageVideoGalleryModalSliderNew";

const MultiSlotCard2 = ({ content, secondaryArgs, index, analytics }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { triggerClickAnalytics } = useClickImpression();

  const toggleModalStatus = () => {
    if (!secondaryArgs?.editState) setModalStatus(!modalStatus);
  };
  const onClickCard = (id: string) => (e: any) => {
    if (secondaryArgs.editState) {
      e.preventDefault();
    } else {
      if (typeof window !== "undefined") {
        const url = getLandingPageURLwithoutSlash(
          secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint,
          secondaryArgs?.prelemBaseEndpoint?.language,
          content?.ContentType,
          id,
        );
        triggerClickAnalytics(
          url,
          index,
          analytics,
          secondaryArgs,
          content?.Title,
          content?.ContentType,
        );
        window.open(url, "_self");
      }
    }
  };

  const handleClose = () => {
    setModalStatus(false);
  };

  const formedUrl = getImage(content, secondaryArgs);
  const { color, imageUrl } = formedUrl;
  return (
    <>
      {["Article", "Quiz", "Poll", "Event", "Vod"].includes(content?.ContentType) ? (
        <Box
          sx={{
            "&:hover": {
              ".button-name": {
                display: secondaryArgs?.editState ? "none" : "block",
              },
            },
          }}
          className='multiSlotCard2 overlay-wrapper'>
          <Card className='cardItem' onClick={onClickCard(content?.EditorialItemPath)}>
            <Box className='cardItemInner'>
              <CardMedia
                sx={{
                  backgroundColor: color ? color : "",
                  width: "100%",
                  height: "100%",
                }}
                className='bgimage'
                image={imageUrl ? imageUrl : ""}
              />
              <Box className='gradientWrapper' />
              <Box className='expertise-show-case'>
                <Box>
                  <Box className='textUnderline'>
                    <Box display='flex' alignItems='center'>
                      <img
                        src={getIcon(content?.ContentType)}
                        alt={content?.ContentType}
                        width={16}
                        height={16}
                      />
                      <Typography
                        variant='p4regular'
                        className='contentTypeName'
                        color='textColor'
                        sx={{ textTransform: "capitalize" }}>
                        {getDisplayContentType(content?.ContentType)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant='p2semibold' color='textColor' className='doublebr title'>
                  {content?.Title}
                </Typography>
                <Box display='flex' overflow='hidden' mt='8px' alignItems='center'>
                  <Box className='view-more' position='relative'>
                    <Typography variant='p4regular' color='textColor' className='viewMoreText'>
                      View More
                    </Typography>
                    <EastIcon className='eastIcon' />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      ) : ["gallery", "videogallery", "imagegallery"].includes(
          content?.ContentType.toLowerCase(),
        ) ? (
        <Box
          sx={{
            "&:hover": {
              ".button-name": {
                display: secondaryArgs?.editState ? "none" : "block",
              },
            },
          }}
          className='multiSlotCard2 overlay-wrapper'
          onClick={toggleModalStatus}>
          <Box className='cardItemInner'>
            <CardMedia
              image={imageUrl ? imageUrl : ""}
              className='bgimage'
              sx={{ backgroundColor: color ? color : "" }}
            />
            <Box className='gradientWrapper' />
            <Box className='expertise-show-case'>
              <Box>
                <Box className='textUnderline'>
                  <Box display='flex' alignItems='center'>
                    <img
                      src={getIcon(content?.ContentType)}
                      alt={content?.ContentType}
                      width={16}
                      height={16}
                    />
                    <Typography
                      variant='p4regular'
                      className='contentTypeName'
                      color='textColor'
                      sx={{ textTransform: "capitalize" }}>
                      {getDisplayContentType(content?.ContentType)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography variant='p2semibold' className='doublebr title' color='textColor'>
                {content?.Title}
              </Typography>
              <Box display='flex' overflow='hidden' mt='8px' alignItems='center'>
                <Box className='view-more' position='relative'>
                  <Typography variant='p4regular' color='textColor' className='viewMoreText'>
                    View More
                  </Typography>
                  <EastIcon className='eastIcon' />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            "&:hover": {
              ".button-name": {
                display: secondaryArgs?.editState ? "none" : "block",
              },
            },
          }}
          className='multiSlotCard2 overlay-wrapper'>
          <Card className='cardItem' onClick={onClickCard(content?.EditorialItemPath)}>
            <Box className='cardItemInner'>
              <CardMedia
                sx={{
                  backgroundColor: color ? color : "",
                  width: "100%",
                  height: "100%",
                }}
                className='bgimage'
                image={imageUrl ? imageUrl : ""}
              />
              <Box className='gradientWrapper' />
              <Box className='expertise-show-case'>
                <Box>
                  <Box className='textUnderline'>
                    <Box display='flex' alignItems='center'>
                      <img
                        src={getIcon(content?.ContentType)}
                        alt={content?.ContentType}
                        width={16}
                        height={16}
                      />
                      <Typography
                        variant='p4regular'
                        className='contentTypeName'
                        color='textColor'
                        sx={{ textTransform: "capitalize" }}>
                        {getDisplayContentType(content?.ContentType)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant='p2semibold' color='textColor' className='doublebr title'>
                  {content?.Title}
                </Typography>
                <Box display='flex' overflow='hidden' mt='8px' alignItems='center'>
                  <Box className='view-more' position='relative'>
                    <Typography variant='p4regular' color='textColor' className='viewMoreText'>
                      View More
                    </Typography>
                    <EastIcon className='eastIcon' />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      )}
      {modalStatus && !secondaryArgs?.editState && (
        <ImageVideoGalleryModalSliderNew
          openModal={modalStatus}
          indexPos={0}
          handleClose={handleClose}
          content={content}
          secondaryArgs={secondaryArgs}
        />
      )}
    </>
  );
};

export default MultiSlotCard2;
