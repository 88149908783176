import { Box } from "@mui/material";
import DynamicDropdowns from "../Components/DynamicDropdown/DynamicDropdown";
import FormItem from "../FormItem";
import FormTextArea from "./FormTextArea";

const FormAddress = ({
  item,
  register,
  clearErrors,
  errors,
  isRendering,
  setSelectedElement,
  selectedElement,
  secondaryArgs,
}) => {
  return (
    <Box
      className='formTextBox elementWrapper'
      mt={1}
      flexWrap='wrap'
      sx={{
        display: { xs: "block", sm: "flex" },
        flexDirection: item?.split ? "row" : "column",
        gap: item?.split ? "12px" : 0,
      }}>
      {item?.split ? (
        item?.splitFields?.map(
          (splitFieldItem) =>
            splitFieldItem?.optional !== true && (
              <Box key={splitFieldItem?.id} flex='1 1 48%' className='address-split-field'>
                {splitFieldItem.type !== "country-state-city" ? (
                  <FormItem
                    item={splitFieldItem}
                    register={register}
                    clearErrors={clearErrors}
                    errors={errors}
                    setSelectedElement={setSelectedElement}
                    selectedElement={selectedElement}
                    isRendering={isRendering}
                    secondaryArgs={secondaryArgs}
                  />
                ) : (
                  <DynamicDropdowns
                    item={splitFieldItem}
                    register={register}
                    clearErrors={clearErrors}
                    errors={errors}
                    isRendering={isRendering}
                    secondaryArgs={secondaryArgs}
                    parentElement={item}
                  />
                )}
              </Box>
            ),
        )
      ) : (
        <Box flex='1 1 100%'>
          <FormTextArea
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}
          />
        </Box>
      )}
    </Box>
  );
};

export default FormAddress;
