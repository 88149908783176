import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { dynamicAPICall } from "../../../helper";

interface Country {
  id: string;
  label: string;
}

const CountryList = ({
  setSelectedCountry,
  field,
  register,
  secondaryArgs,
  countryLoading,
  setCountryLoading,
  setStateLoading,
}) => {
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [country, setCountry] = useState<string | null>(null);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    setSelectedCountry(event.target.value);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const obj = {
        query: 'query {publish_getDynamicDropDownItems(api_variant:"Countries")}',
        responseVariable: "publish_getDynamicDropDownItems",
      };
      try {
        setCountryLoading(true);
        setStateLoading(true);
        const result = await dynamicAPICall(obj.query, obj.responseVariable, secondaryArgs);
        if (result && result.length > 0) {
          const mappedResults = result.map((item) => ({
            label: item.label,
            id: `${item.label}-${item.id}`,
          }));
          setCountryList(mappedResults);
          setCountry(mappedResults[0]?.id);
          setSelectedCountry(mappedResults[0]?.id);
        }
      } catch (error) {
        setCountryList([]);
        setSelectedCountry(null);
        console.error("Error fetching countries:", error);
      } finally {
        setCountryLoading(false);
      }
    };
    fetchCountries();
  }, []);

  const getImgSrc = (id) => {
    if (id) {
      const splitSelectedCountry = id.split("-")?.[1];
      const lowerId = splitSelectedCountry.toLowerCase();
      return `https://flagcdn.com/w40/${lowerId}.png`;
    } else {
      return "";
    }
  };

  return (
    <FormControl fullWidth sx={{ mb: 1, flex: 1 }}>
      <Typography variant='p4regular' className='form-label' mt={1} mb={1}>
        {field?.title}
      </Typography>
      {countryLoading || country === null ? (
        <Select
          label='Country'
          variant='filled'
          sx={{ minWidth: "130px" }}
          defaultValue='Loading...'
          className='dynamicdropdown'>
          <MenuItem value='Loading...' disabled>
            Loading...
          </MenuItem>
        </Select>
      ) : (
        <>
          {/* {console.log("shadowCountry", country)} */}
          <Select
            defaultValue={country}
            label='Country'
            variant='filled'
            sx={{ minWidth: "130px" }}
            className='dynamicdropdown'
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 300,
                  minWidth: 100,
                },
              },
            }}
            name={field?.name}
            {...register(`${field?.name}`, {
              onChange: (event) => {
                handleCountryChange(event);
              },
            })}>
            {countryLoading ? (
              <MenuItem value='' disabled>
                Loading...
              </MenuItem>
            ) : countryList?.length === 0 ? (
              <MenuItem value='' disabled>
                No Data Found
              </MenuItem>
            ) : (
              countryList?.map((item, i) => (
                <MenuItem key={`${item?.id}-${i}`} value={item?.id}>
                  <Box display='flex' sx={{ maxHeight: "20px", overflow: "hidden" }}>
                    <img
                      loading='lazy'
                      width='20'
                      src={getImgSrc(item?.id)}
                      alt='flag'
                      style={{ marginRight: "8px" }}
                    />
                    {item?.label}
                  </Box>
                </MenuItem>
              ))
            )}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default CountryList;
