import { Box, Divider, Skeleton, Typography, useTheme } from "@mui/material";
import WatchVideo from "./WatchVideo";

const RecentVideos = ({ watchMore, vodData, videoLoader, loadVODDetails, secondaryArgs }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      {/* Heading */}
      {videoLoader ? (
        <Skeleton
          variant='text'
          sx={{ fontSize: theme.fontSize?.fontSize_24, my: "12px" }}
          width='60%'
        />
      ) : (
        <Typography variant='h3regular'>{vodData?.title}</Typography>
      )}
      {/* Paragraph */}
      {videoLoader ? (
        <>
          <Skeleton
            variant='text'
            sx={{ fontSize: theme.fontSize?.fontSize_24, mb: "1px" }}
            width='100%'
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: theme.fontSize?.fontSize_24, mb: "12px" }}
            width='80%'
          />
        </>
      ) : (
        <Typography variant='p3regular' mt={0}>
          {vodData?.description}
        </Typography>
      )}
      {/* Separator */}
      {watchMore?.length > 0 ? (
        <>
          <Divider sx={{ marginBottom: 2 }} />
          <Typography variant='h5semibold' sx={{ marginBottom: 2 }}>
            Watch More
          </Typography>
          {/* Video Thumbnails with Horizontal Scroll */}
          <Box className='watchVideoWrapper'>
            {watchMore?.map((item) => (
              <WatchVideo
                key={item.Thumbnail.Name}
                item={item}
                loadVODDetails={loadVODDetails}
                secondaryArgs={secondaryArgs}
              />
            ))}
          </Box>{" "}
        </>
      ) : null}
    </Box>
  );
};

export default RecentVideos;
