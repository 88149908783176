import { contentTypeSchemaApi } from "@platformx/authoring-apis";
import { formatPageUrl, getCurrentLang } from "@platformx/utilities";
import { addDays, addMinutes } from "date-fns";
import { updateSettingsOnCreate } from "../../pages/article/Utils/helperFunction";
import {
  articleObj,
  eventObject,
  formObject,
  pollObject,
  quizObject,
  vodObject,
} from "./DefaultObjects";

const getDefaultValues = (item) => {
  switch (item.type) {
    case "text":
    case "textarea":
      return "";
    case "number":
      return 0;
    case "boolean":
      return false;
    case "date":
    case "datetime":
    case "time":
      return new Date().toISOString();
    case "select":
      return item?.options?.length > 0 ? item?.options[0]?.value : "";
    case "multiselect":
      return item?.options?.length > 0 ? [item?.options[0]?.value] : [];
    case "radio":
      return item?.options?.length > 0 ? item?.options[0]?.value : "";
    case "checkbox":
      return item?.options?.length > 0 ? [item?.options[0]?.value] : [];
    case "file":
      return "";
    case "image":
      return { original_image: {}, published_images: [] };
    case "video":
      return {};
    case "tags":
      return item.title;
    default:
      return "";
  }
};

const getFormData = (formattedPage, formattedDescription) => {
  const title = {
    element_description: formattedPage,
    element_name: `${formattedPage}_${new Date().getTime()}_${1}`,
    element_schema: {
      id: `${formattedPage}_${new Date().getTime()}_${1}`,
      type: "heading",
      name: `${formattedPage}_${new Date().getTime()}_${1}`,
      title: formattedPage,
      multiple: false,
      index: "01",
      description: "",
      placeholder: "",
      validations: [],
      variant: "string",
      settings: [
        {
          components: ["FormHeadingSetting"],
          label: "Heading",
        },
      ],
    },
  };
  const description = {
    element_description: "formattedDescription",
    element_name: `paragraph_${new Date().getTime()}_${2}`,
    element_schema: {
      id: `paragraph_${new Date().getTime()}_${2}`,
      type: "paragraph",
      name: `paragraph_${new Date().getTime()}_${2}`,
      title: "formattedDescription",
      multiple: false,
      index: "02",
      description: "Write your description here",
      placeholder: "Enter your description here",
      validations: [],
      variant: "string",
      settings: [
        {
          components: ["FormParagraphSetting"],
          label: "Paragraph",
        },
      ],
    },
  };
  return formattedDescription ? [title, description] : [title];
};

const getSchemaBasedObject = async (contentType, updatedPage, updatedTitle, updatedDescription) => {
  const detailsRes: any = await contentTypeSchemaApi.getSchemaDynamic({
    filter: contentType,
  });
  const schema = detailsRes?.authoring_getSchema;
  const schemaFieldsObject = {};
  schema?.fields?.forEach((item) => {
    schemaFieldsObject[item.name] = getDefaultValues(item);
  });

  const objectForAPI = {
    contentType: schema.name,
    input: {
      ...schemaFieldsObject,
      title: updatedTitle,
      tags: [schema.title],
      name: updatedPage,
      description: updatedDescription,
    },
    schema_document_type: schema.schema_document_type,
  };
  return objectForAPI;
};

const getObjectBasedOnContent = async (
  contentType,
  userInfo,
  updatedTitle,
  updatedDescription = "",
) => {
  const formattedPage = formatPageUrl(updatedTitle);
  const username = `${userInfo.first_name} ${userInfo.last_name}`;
  const currentLanguage = getCurrentLang();
  const date = new Date().toISOString();
  switch (contentType?.toLowerCase()) {
    case "article":
      return {
        ...articleObj,
        input: {
          CommonFields: {
            ...articleObj.input.CommonFields,
            page: formattedPage,
            title: updatedTitle,
            // current_page_url: `/${formattedPage}`,
            createdBy: username,
            creationDate: date,
            modificationDate: date,
            page_createdby: username,
            page_lastmodifiedby: username,
            description: updatedDescription,
            settings: updateSettingsOnCreate(
              { updatedTitle, updatedDescription },
              formattedPage,
              "article",
              currentLanguage,
            ),
          },
          ObjectFields: articleObj.input.ObjectFields,
        },
      };
    case "vod":
      return {
        ...vodObject,
        input: {
          CommonFields: {
            ...vodObject.input.CommonFields,
            page: formattedPage,
            title: updatedTitle,
            // current_page_url: `/${formattedPage}`,
            createdBy: username,
            creationDate: date,
            modificationDate: date,
            page_createdby: username,
            page_lastmodifiedby: username,
            description: updatedDescription,
            settings: updateSettingsOnCreate(
              { updatedTitle, updatedDescription },
              formattedPage,
              "video",
              currentLanguage,
            ),
          },
          ObjectFields: vodObject.input.ObjectFields,
        },
      };
    case "quiz":
      return {
        ...quizObject,
        input: {
          CommonFields: {
            ...quizObject.input.CommonFields,
            page: formattedPage,
            title: updatedTitle,
            // current_page_url: `/${formattedPage}`,
            createdBy: username,
            creationDate: date,
            modificationDate: date,
            page_createdby: username,
            page_lastmodifiedby: username,
            description: updatedDescription,
            settings: updateSettingsOnCreate(
              { updatedTitle, updatedDescription },
              formattedPage,
              "quiz",
              currentLanguage,
            ),
          },
          ObjectFields: quizObject.input.ObjectFields,
        },
      };
    case "poll":
      return {
        ...pollObject,
        input: {
          CommonFields: {
            ...pollObject.input.CommonFields,
            page: formattedPage,
            title: updatedTitle,
            // current_page_url: `/${formattedPage}`,
            createdBy: username,
            creationDate: date,
            modificationDate: date,
            page_createdby: username,
            page_lastmodifiedby: username,
            description: updatedDescription,
            settings: updateSettingsOnCreate(
              { updatedTitle, updatedDescription },
              formattedPage,
              "poll",
              currentLanguage,
            ),
          },
          ObjectFields: pollObject.input.ObjectFields,
        },
      };
    case "event":
      return {
        ...eventObject,
        input: {
          CommonFields: {
            ...eventObject.input.CommonFields,
            page: formattedPage,
            title: updatedTitle,
            // current_page_url: `/${formattedPage}`,
            createdBy: username,
            creationDate: date,
            modificationDate: date,
            page_createdby: username,
            page_lastmodifiedby: username,
            description: updatedDescription,
            settings: updateSettingsOnCreate(
              { updatedTitle, updatedDescription },
              formattedPage,
              "event",
              currentLanguage,
            ),
          },
          ObjectFields: {
            ...eventObject.input.ObjectFields,
            event_start_date: addMinutes(new Date(), 15).toISOString(),
            event_end_date: addDays(new Date(), 1).toISOString(),
          },
        },
      };
    case "formbuilder":
      return {
        ...formObject,
        input: {
          CommonFields: {
            ...formObject.input.CommonFields,
            page: formattedPage,
            title: updatedTitle,
            // current_page_url: `/${formattedPage}`,
            createdBy: username,
            creationDate: date,
            modificationDate: date,
            page_createdby: username,
            page_lastmodifiedby: username,
            settings: updateSettingsOnCreate(
              { updatedTitle, updatedDescription },
              formattedPage,
              "formbuilder",
              currentLanguage,
            ),
          },
          ObjectFields: {
            ...formObject.input.ObjectFields,
            page_name: formattedPage,
            form_version: "",
            formData: getFormData(formattedPage, updatedDescription),
          },
        },
      };
    //Default Runs for all schema based items.
    default:
      return await getSchemaBasedObject(
        contentType,
        formattedPage,
        updatedTitle,
        updatedDescription,
      );
  }
};

const TITLE_VALIDATION = [
  {
    type: "maxLength",
    value: 25,
    message: "25 characters max",
  },
];

const DESCRIPTION_VALIDATION = [
  {
    type: "maxLength",
    value: 100,
    message: "100 characters max",
  },
];

const URL_VALIDATION = [
  {
    type: "maxLength",
    value: 30,
    message: "30 characters max",
  },
];

const DUPLICATE_TITLE_VALIDATION = [
  {
    type: "maxLength",
    value: 35,
    message: "35 characters max",
  },
];

const DUPLICATE_URL_VALIDATION = [
  {
    type: "maxLength",
    value: 35,
    message: "35 characters max",
  },
];

export {
  DESCRIPTION_VALIDATION,
  DUPLICATE_TITLE_VALIDATION,
  DUPLICATE_URL_VALIDATION,
  getObjectBasedOnContent,
  TITLE_VALIDATION,
  URL_VALIDATION,
};
