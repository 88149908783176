import FormAddress from "./FormElements/FormAddress";
import FormCheckboxGroup from "./FormElements/FormCheckboxGroup";
import FormConsent from "./FormElements/FormConsent";
import FormDate from "./FormElements/FormDate";
import FormDateAndTimePicker from "./FormElements/FormDateAndTimePicker";
import FormDynamicDropdown from "./FormElements/FormDynamicDropdown";
import FormHeading from "./FormElements/FormHeading";
import FormName from "./FormElements/FormName";
import FormParagraph from "./FormElements/FormParagraph";
import FormPhone from "./FormElements/FormPhone";
import FormRadioGroup from "./FormElements/FormRadioGroup";
import FormTextArea from "./FormElements/FormTextArea";
import FormTextBox from "./FormElements/FormTextBox";
import FormToggle from "./FormElements/FormToggle";

const FormItem = ({
  item,
  register,
  clearErrors,
  errors,
  selectedElement,
  setSelectedElement,
  isRendering = true,
  secondaryArgs,
}) => {
  const type = item?.type;
  switch (type) {
    case "text":
    case "number":
    case "email":
      if (item?.variant === "multiline") {
        return (
          <FormTextArea
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}
          />
        );
      } else {
        return (
          <FormTextBox
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}
          />
        );
      }
    case "phone":
      return (
        <FormPhone
          item={item}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          isRendering={isRendering}
        />
      );
    case "datetime":
      return (
        <FormDateAndTimePicker
          item={item}
          register={register}
          clearErrors={clearErrors}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          errors={errors}
        />
      );
    case "date":
      return (
        <FormDate
          item={item}
          register={register}
          clearErrors={clearErrors}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          errors={errors}
        />
      );
    case "heading":
      return <FormHeading item={item} />;
    case "paragraph":
      return <FormParagraph item={item} />;
    case "boolean":
      switch (item.variant) {
        case "toggle":
          return (
            <FormToggle item={item} register={register} errors={errors} isRendering={isRendering} />
          );
        case "consent":
          return (
            <FormConsent
              item={item}
              register={register}
              errors={errors}
              isRendering={isRendering}
            />
          );
        default:
          return null;
      }
    case "splitName":
      return (
        <FormName
          item={item}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          isRendering={isRendering}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          secondaryArgs={secondaryArgs}
        />
      );
    case "address":
      return (
        <FormAddress
          item={item}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          isRendering={isRendering}
          secondaryArgs={secondaryArgs}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      );
    case "radio":
      return (
        <FormRadioGroup
          item={item}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          isRendering={isRendering}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      );
    case "checkbox":
      return (
        <FormCheckboxGroup
          item={item}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          isRendering={isRendering}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      );
    case "dropdown":
      return (
        <FormDynamicDropdown
          item={item}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
          isRendering={isRendering}
          secondaryArgs={secondaryArgs}
        />
      );
    default:
      return null;
  }
};

export default FormItem;
