import { Box, Typography } from "@mui/material";
import NumericTextBox from "../Components/TextBox/NumericTextBox";
import TextBox from "../Components/TextBox/TextBox";
import { checkRequired } from "../helper";
import ControllInfo from "./Common/ControllInfo/ControllInfo";

const FormTextBox = ({ item, register, clearErrors, errors, isRendering }) => {
  return (
    item?.optional !== false && (
      <Box className='formTextBox elementWrapper' mt={1}>
        <Typography
          variant='p4regular'
          className='form-label'
          mt={1}
          mb={1}
          display='flex'
          alignItems='flex-start'>
          <Box component='span' className='form-label-with-info'>
            {item?.title}
            {checkRequired(item?.validations)}
          </Box>
          {item?.info?.showMessage && <ControllInfo item={item} />}
        </Typography>
        {item.type === "number" ? (
          <NumericTextBox
            field={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}
            isDisabled={!isRendering}
          />
        ) : (
          <TextBox
            field={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}
            isDisabled={!isRendering}
          />
        )}
      </Box>
    )
  );
};

export default FormTextBox;
