import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTitle, frameTitle, getDisplayContentType } from "../../utils/helper";
import LanguageDropDownCheckBox from "../LanguageDropDownCheckBox/LanguageDropDownCheckBox";
import { findMaxCharLength } from "../MaxLengthValidationMessage/helper";
import MaxLengthValidationMessage from "../MaxLengthValidationMessage/MaxLengthValidationMessage";
import "./DuplicateContentPopup.css";

interface DialogList {
  titledata: string;
  isDialogOpen: boolean;
  closeButtonHandle: () => void;
  // eslint-disable-next-line no-unused-vars
  doneButtonHandle: (pageExist: boolean, currTitle: string) => void;
  contentType?: string;
  language?: any;
  setLanguage?: any;
}

export default function DuplicateContentPopup({
  titledata = "",
  isDialogOpen,
  closeButtonHandle,
  doneButtonHandle,
  contentType,
  language = [],
  setLanguage,
}: DialogList) {
  const { t } = useTranslation();
  const [title, setTitle] = useState(formatTitle(titledata?.substring(0, 35)));

  const formTitleAndUpdateState = () => {
    const updatedTitle = frameTitle(title);
    setTitle(updatedTitle);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={closeButtonHandle}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className='duplicateContentPopupModal'
        sx={{
          ".Platform-x-Dialog-paper": {
            margin: { xs: "0", md: "20px", xl: "30px" },
            alignSelf: { xs: "flex-end", md: "center" },
            padding: { xs: "20px 15px", md: "25px" },
            width: { xs: "100%" },
            borderBottomLeftRadius: { xs: 0, md: "4px" },
            borderBottomRightRadius: { xs: 0, md: "4px" },
          },
        }}>
        <Box className='mainBox'>
          <DialogTitle id='alert-dialog-title' component='h2' variant='h2medium'>
            {t("duplicate")} {getDisplayContentType(contentType)}
          </DialogTitle>
          <Box className='inputesBox'>
            <TextField
              autoFocus
              value={title}
              onChange={(e) => {
                const updatedValue = formatTitle(e.target.value);
                setTitle(updatedValue);
              }}
              onBlur={formTitleAndUpdateState}
              inputProps={{
                readOnly: false,
                maxLength: findMaxCharLength([
                  {
                    type: "maxLength",
                    value: 35,
                    message: "35 characters max",
                  },
                ]),
              }}
              placeholder='Enter Title'
              margin='dense'
              id='name'
              type='text'
              variant='filled'
              sx={{ width: "100%" }}
              autoComplete='off'
            />
            <MaxLengthValidationMessage
              validations={[
                {
                  type: "maxLength",
                  value: 35,
                  message: "35 characters max",
                },
              ]}
              currentLength={title?.length || 0}
            />
          </Box>
          <Box className='inputesBox' sx={{ display: "none" }}>
            <Typography variant='h5regular'>
              {t("content_language_label")}
              {getDisplayContentType(contentType)}
            </Typography>
            <LanguageDropDownCheckBox language={language} setLanguage={setLanguage} />
          </Box>
          <DialogActions className='actionsButtons'>
            <Button
              variant='secondaryButton'
              className='buttonsBottom'
              sx={{
                marginRight: "8px",
              }}
              onClick={closeButtonHandle}>
              {t("back")}
            </Button>
            <Button
              disabled={title?.length > 0 && language.length > 0 ? false : true}
              variant='primaryButton'
              className='buttonsBottom'
              onClick={() => doneButtonHandle(false, title)}
              autoFocus>
              {t("create")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
