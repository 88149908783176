import { Box, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { getImage, PlatFormXDateTimeFormat } from "@platformx/utilities";
import { getIcon } from "../../components/Utils/helperFns";

const WatchVideo = ({ item, loadVODDetails, secondaryArgs }) => {
  const formedUrl = getImage(item, secondaryArgs);
  const { imageUrl } = formedUrl;
  return (
    <Card
      data-testid='enable-vod-play'
      key={item.Thumbnail.Name}
      className='watchVideo'
      onClick={() => loadVODDetails(item?.EditorialItemPath)}>
      <Box className='imageSectionWrapper'>
        <CardMedia
          component={imageUrl ? "img" : "div"}
          image={imageUrl || ""}
          loading={imageUrl ? "lazy" : "eager"}
          title={imageUrl ? item?.Thumbnail?.AltText : "image"}
          className='cardMedia'
          alt='banner'
        />
        <Box className='overlay' />
        <Box className='iconWrapper'>
          <img
            src={getIcon(
              item?.ContentType,
              item?.Content_icon,
              secondaryArgs?.gcpUrl,
              secondaryArgs?.bucketName,
            )}
            alt='icon'
            width={25}
            height={25}
          />
        </Box>
      </Box>

      <CardContent className='cardContent'>
        <Typography gutterBottom variant='h5medium' className='noMarginBoth title' component='div'>
          {item?.Title}
        </Typography>
      </CardContent>
      <CardActions className='actionBar'>
        <Box style={{ display: "grid" }}>
          {/* <Typography variant='h7medium' className='noMarginBoth title'>
            {item?.Author}
          </Typography> */}
          <Typography variant='h7medium' className='noMarginBoth title'>
            {PlatFormXDateTimeFormat(item?.PublishedDate || item?.published_date)}
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default WatchVideo;
