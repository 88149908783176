const formObject = {
  contentType: "formbuilder",
  input: {
    CommonFields: {
      page: "",
      title: "",
      description: "",
      category: "formbuilder",
      site_name: "",
      parent_page_url: "/",
      developedby: "",
      page_state: "DRAFT",
      is_edit: false,
      seo_enable: false,
      analytics_enable: false,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      others: "",
      structure_data: "",
      createdBy: "",
      creationDate: "",
      modificationDate: "",
      tags: ["formbuilder"],
      page_createdby: "",
      page_lastmodifiedby: "",
      settings: {
        socialog_url: "",
        socialog_twitter_url: "",
        socialog_type: "Form",
        socialog_sitename: "",
        seo_title: "",
        socialog_title: "",
        socialog_twitter_title: "",
        socialog_description: "",
        socialog_twitter_description: "",
        socialog_image: "",
        socialog_twitter_image: "",
        keywords: [],
        seo_keywords: ["Form"],
        seo_description: "",
        seo_blockIndexing: true,
      },
      IsConfirm: false,
      is_featured: false,
    },
    ObjectFields: {
      page_name: "",
      formData: [
        {
          element_description: "",
          element_name: "",
          element_schema: {
            id: "",
            type: "heading",
            name: "",
            title: "",
            multiple: false,
            index: "01",
            description: "Write your description here",
            placeholder: "Enter your description here",
            validations: [],
            variant: "string",
          },
        },
        {
          element_description: "",
          element_name: "",
          element_schema: {
            id: "",
            type: "paragraph",
            name: "",
            title: "",
            multiple: false,
            index: "01",
            description: "Write your description here",
            placeholder: "Enter your description here",
            validations: [],
            variant: "string",
          },
        },
      ],
    },
  },
};

export default formObject;
