import { Box, TextField, Typography } from "@mui/material";

const SimpleSetting = ({ updateElement, selectedElement }) => {
  const handleChangeValue = (splitFieldItem, field, value) => {
    updateElement(splitFieldItem?.id, {
      [field]: value,
    });
  };

  return (
    <Box mt={1}>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='p4regular'>{selectedElement?.label} Title</Typography>
      </Box>
      <TextField
        fullWidth
        margin='dense'
        variant='outlined'
        value={selectedElement?.title}
        onChange={(e) => {
          handleChangeValue(selectedElement, "title", e.target.value);
        }}
      />
    </Box>
  );
};

export default SimpleSetting;
