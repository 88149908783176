import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    playerAnalysisWrapper: {
      "&.playerAnalysis": {
        background: theme.palette.prelemType1.BACKGROUND,
        "& .gradient": {
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "linear-gradient(180deg, rgba(0,0,0,0.0001) 0%, #000000 100%)",
          mixBlendMode: "normal",
          opacity: "0.8",
          zIndex: 1,
        },
        "& .bannerText": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "20px",
          textAlign: "center",
          zIndex: 2,
        },

        "& .watchVideoWrapper": {
          display: "flex",
          overflowX: "auto",
          gap: theme.spacing(2),
          paddingBottom: theme.spacing(4),
          "-ms-overflow-style": "none",
          scrollbarWidth: "none",
          "& .watchVideo": {
            cursor: "pointer",
            background: "transparent",
            width: "100%",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex!important",
            flexDirection: "column",
            color: theme.palette.prelemType1.CARDS.VARIANT1.TITLE,
            position: "relative",
            height: "360px",
            minWidth: "270px",
            maxWidth: "270px",
            [theme.breakpoints.up("md")]: {
              height: "320px",
            },
            [theme.breakpoints.up("lg")]: {
              height: "270px",
            },
            "& .imageSectionWrapper": {
              position: "relative",
              height: "230px",
              [theme.breakpoints.up("md")]: {
                height: "190px",
              },
              [theme.breakpoints.up("lg")]: {
                height: "150px",
              },
            },
            "& .cardMedia": {
              overflow: "hidden",
              objectFit: "cover",
              height: "100%",
              width: "100%",
            },

            "& .overlay": {
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "linear-gradient(180deg, rgba(0,0,0,0.0001) 0%, #000000 100%)",
              mixBlendMode: "normal",
              opacity: "0.5",
              top: 0,
            },

            "& .iconWrapper": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              "& img": {
                left: "10px",
                bottom: "10px",
                position: "absolute",
              },
            },
            "& .cardContent": {
              padding: 0,
              paddingTop: theme.spacing(2),
              // flexGrow: 1,
            },

            "& .title": {
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "1",
              overflow: "hidden",
            },

            "& .actionBar": {
              display: "grid",
              padding: 0,
              paddingTop: theme.spacing(1),
            },

            "& .author, & .publishedDate": {
              fontSize: "14px",
            },
            "& .noMarginBoth": {
              marginBottom: 0,
              marginTop: 0,
            },
          },
        },
        "& .filterIcon": {
          background: "white",
          borderRadius: "4px",
          padding: "2px 8px 0px 0",
          cursor: "pointer",
        },
        "& .tag-selected": {
          backgroundColor: "#fff",
          color: "#14142B",
          border: "1px solid #14142B",
        },
        "& .tag": {
          backgroundColor: "#14142B",
          color: "#fff",
          border: "1px solid #14142B",
        },
      },
    },
  };
});
