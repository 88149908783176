const pollObject = {
  contentType: "Poll",
  input: {
    CommonFields: {
      page: "",
      title: "",
      description: "",
      category: "Poll",
      site_name: "",
      page_state: "DRAFT",
      is_edit: false,
      seo_enable: true,
      analytics_enable: true,
      robot_txt: false,
      sitemap: false,
      analytics: "",
      others: "",
      structure_data: "",
      creationDate: "",
      modificationDate: "",
      tags: ["Polls"],
      createdBy: "",
      parent_page_url: "/",
      page_lastmodifiedby: "",
      settings: {
        socialog_url: "",
        socialog_type: "poll",
        socialog_sitename: "",
        seo_title: "",
        socialog_title: "",
        socialog_twitter_title: "",
        socialog_description: "",
        socialog_twitter_description: "",
        socialog_twitter_url: "",
        keywords: ["Polls"],
        seo_keywords: ["Polls"],
        seo_description: "",
        socialog_image: "",
        socialog_twitter_image: "",
        is_schedule_publish: false,
        schedule_publish_datetime: "",
        is_schedule_unpublish: false,
        schedule_unpublish_datetime: "",
      },
      IsConfirm: false,
      is_featured: false,
    },
    ObjectFields: {
      background_content: {},
      question_background_content: {},
      display_scores: "Count",
      poll_description: "",
      poll_title: "",
      poll_question: "",
      options_compound_fields: [],
      original_image: {},
      published_images: [],
      is_image_option: false,
    },
  },
};
export default pollObject;
