import FormDropdown from "../../FormElements/FormDropdown";
import CountryStateCityDropdown from "./CountryStateCity/CountryStateCityDropdown";
import SimpleDropdown from "./SimpleDropdown";

const DynamicDropdowns = ({
  item,
  register,
  errors,
  clearErrors,
  isRendering,
  secondaryArgs,
  parentElement,
}) => {
  const renderItem = item?.type;
  const renderDynamicDropdown = (dropdownType) => {
    const type = dropdownType;
    switch (type) {
      case "custom":
        return (
          <FormDropdown
            item={item}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            isRendering={isRendering}
          />
        );
      case "country":
      case "country-state":
      case "country-state-city":
        return (
          <CountryStateCityDropdown
            field={item}
            register={register}
            secondaryArgs={secondaryArgs}
            parentElement={parentElement}
          />
        );
      default:
        return (
          <SimpleDropdown
            field={item}
            register={register}
            errors={errors}
            clearErrors={clearErrors}
            isRendering={isRendering}
          />
        );
    }
  };
  return item?.enabled ? renderDynamicDropdown(renderItem) : null;
};

export default DynamicDropdowns;
