import { Box, Divider, TextField, Typography } from "@mui/material";
import { LENGTH } from "../constants";

const FormHeadingSetting = ({ selectedElement, updateElement, renderValidation }) => {
  const handleChange = (field, value) => {
    updateElement(selectedElement.id, { [field]: value });
  };
  return (
    <Box className='formTextBox elementWrapper'>
      {/* <Typography variant='h5semibold'>Heading Setting</Typography>
      <Box mt={1.5} mb={1.5}>
        <Divider />
      </Box> */}
      <Box mt={1}>
        <Typography variant='p4regular'>Title Text</Typography>
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          value={selectedElement.title}
          onChange={(e) => handleChange("title", e.target.value)}
          inputProps={{ maxLength: LENGTH.HEADING }}
        />
        <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
          {`${LENGTH.HEADING} characters max`}
        </Typography>
      </Box>
      {selectedElement?.validations?.length > 0 && (
        <Box className='validationSection' mt={2}>
          <Typography variant='h6semibold'>Validation</Typography>
          <Box mt={1.5} mb={1.5}>
            <Divider />
          </Box>
          {selectedElement?.validations?.map((validationType, index) => (
            <Box key={index}>{renderValidation(validationType)}</Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FormHeadingSetting;
